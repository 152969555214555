/**
 * Classe responsavel por concentrar tudo que for util (Boilterplate code)
 */
import { SelectItem } from "../../core/components/dropdown/select-item";

export class Utils {
	static readonly REGEX_EMAIL = /^(([^<>+()\[\]\\.,;:\s@"-#$%&=]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
	static readonly MASK_CNPJ = [/\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/];
	static readonly MASK_CPF = [/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/];
	static readonly MASK_TITULO_ELEITOR = [/\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/];
	static readonly MASK_DATA = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
	static readonly MASK_TELEFONE_CELULAR = ["(", /\d/, /\d/, ")", /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
	static readonly MASK_TELEFONE_FIXO = ["(", /\d/, /\d/, ")", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
	static readonly MASK_CEP = [/\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/];

	static capitalizar(txt: string) {
		if (txt == null) {
			return null;
		}

		if (0 === txt.length) {
			return "";
		}

		const prep: string[] = ["por", "a", "para", "de", "em", "pelo", "ao", "pro", "do", "no", "pela", "à", "pra", "da", "na", "pelos", "aos", "pros", "dos", "nos", "pelas", "às", "pras", "das", "nas"];

		const partes = txt.split(" ");

		const res = [];

		for (const parte of partes) {
			const lowered = parte.toLocaleLowerCase();
			if (prep.includes(lowered)) {
				res.push(lowered);
			} else {
				res.push(lowered.charAt(0).toLocaleUpperCase() + lowered.slice(1));
			}
		}

		return res.join(" ");
	}

	static cnpj(value: string): string {
		if (value == null) {
			return null;
		}

		if (0 === value.length) {
			return "";
		}
		return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
	}

	static trim(value: string): string {
		if (value == null) {
			return null;
		}

		if (0 === value.length) {
			return "";
		}
		return value.trim();
	}

	/**
	 * Retorna as uf
	 */
	static ufs(): SelectItem[] {
		return [
			{ value: "AC", label: "Acre" },
			{ value: "AL", label: "Alagoas" },
			{ value: "AM", label: "Amazonas" },
			{ value: "AP", label: "Amapá" },
			{ value: "BA", label: "Bahia" },
			{ value: "CE", label: "Ceará" },
			{ value: "DF", label: "Distrito Federal" },
			{ value: "ES", label: "Espírito Santo" },
			{ value: "GO", label: "Goaís" },
			{ value: "MA", label: "Maranhão" },
			{ value: "MG", label: "Minas Gerais" },
			{ value: "MS", label: "Mato Grosso do Sul" },
			{ value: "MT", label: "Mato Grosso" },
			{ value: "PA", label: "Pará" },
			{ value: "PB", label: "Paraíba" },
			{ value: "PE", label: "Pernambuco" },
			{ value: "PI", label: "Piauí" },
			{ value: "PR", label: "Paraná" },
			{ value: "RJ", label: "Rio de Janeiro" },
			{ value: "RN", label: "Rio Grande do Norte" },
			{ value: "RO", label: "Rondônia" },
			{ value: "RR", label: "Roraíma" },
			{ value: "RS", label: "Rio Grande Do Sul" },
			{ value: "SC", label: "Santa Catarina" },
			{ value: "SE", label: "Sergipe" },
			{ value: "SP", label: "São Paulo" },
			{ value: "TO", label: "Tocantins" },
		];
	}

	static formatarCNPJ(cnpj: string): string {
		if (cnpj) {
			return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
		} else {
			return "";
		}
	}

	static formataCNPJSemMascara(cnpj: string): string {
		if (cnpj) {
			cnpj = cnpj.replace(".", "");
			cnpj = cnpj.replace(".", "");
			cnpj = cnpj.replace("/", "");
			cnpj = cnpj.replace("-", "");
			return cnpj;
		} else return "";
	}

	static formataCPFSemMascara(cpf: string): string {
		if (cpf) {
			cpf = cpf.replace(".", "");
			cpf = cpf.replace(".", "");
			cpf = cpf.replace("-", "");
			return cpf;
		} else return "";
	}

	static formataCPF(cpf) {
		if (cpf != null && typeof cpf !== "undefined") {
			cpf = cpf.replace(/[^\d]/g, "");
			return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
		}
		return null;
	}

	static formatarCep(cep: string): string {
		if (cep != null && typeof cep !== "undefined") {
			cep = cep.replace(/[^\d]/g, "");
			return cep.replace(/(\d{2})(\d{3})(\d{3})/, "$1.$2-$3");
		}
		return null;
	}

	static formatarProtocolo(protocolo: string): string {
		if (protocolo != null && typeof protocolo !== "undefined") {
			protocolo = protocolo.replace(".", "");
			protocolo = protocolo.replace("/", "");
		}
		return protocolo;
	}

	static validarCamposAlfaNumericos(valor: string) {
		if (valor != null) {
			let pattern = new RegExp("^s*([0-9a-zA-Z.-]+)s*$");
			return pattern.test(valor);
		} else {
			return false;
		}
	}

	static validarCamposNumericos(valor: string) {
		if (valor != null) {
			let pattern = new RegExp("^[0-9]*$");
			return pattern.test(valor);
		} else {
			return false;
		}
	}

	static validarCampoTituloEleitor(valor: string): boolean {
		valor = valor.replace(/ /g, "");
		if (valor != null) return new RegExp("[0-9]*$").test(valor);
		else return false;
	}

	static validarCamposCaracteres(valor: string) {
		if (valor != null) {
			let pattern = new RegExp("^[A-Za-z]*$");
			return pattern.test(valor);
		} else {
			return false;
		}
	}

	static formatarData(data: Date): string {
		if (data) {
			const dataSemFormatar = data.toString();
			const dataFormatada = dataSemFormatar.split("-").reverse().join("/");
			return dataFormatada;
		} else {
			return "";
		}
	}

	/*--------------- VALIDADORES ---------------*/
	static validaCpf(cpf: string): boolean {
		let soma: number;
		let resto: number;
		soma = 0;

		const cpfsInvalidos = ["00000000000", "11111111111", "22222222222", "33333333333", "44444444444", "55555555555", "66666666666", "77777777777", "88888888888", "99999999999"];

		cpf = cpf.replace(/[\s.-]*/gim, "");
		const cpfInvalido = cpfsInvalidos.indexOf(cpf);

		if (!cpf || cpf.length !== 11 || cpfInvalido !== -1) return false;

		for (let i = 1; i <= 9; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
		resto = (soma * 10) % 11;

		if (resto == 10 || resto == 11) resto = 0;
		if (resto != parseInt(cpf.substring(9, 10))) return false;

		soma = 0;
		for (let i = 1; i <= 10; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
		resto = (soma * 10) % 11;

		if (resto == 10 || resto == 11) resto = 0;

		return resto !== parseInt(cpf.substring(10, 11)) ? false : true;
	}

	static validaCNPJ(cnpj: string): boolean {
		cnpj = cnpj.replace(/[^\d]+/g, "");

		if (cnpj == "") return false;
		if (cnpj.length != 14) return false;

		//- Elimina CNPJs invalidos conhecidos
		const cnpjsInvalidos = [
			"00000000000000",
			"11111111111111",
			"22222222222222",
			"33333333333333",
			"44444444444444",
			"55555555555555",
			"66666666666666",
			"77777777777777",
			"88888888888888",
			"99999999999999",
		];
		if (cnpjsInvalidos.indexOf(cnpj) === -1) return false;

		//- Valida DVs
		let tamanho: any = cnpj.length - 2;
		let numeros = cnpj.substring(0, tamanho);
		let digitos = cnpj.substring(tamanho);
		let soma: any = 0;
		let pos = tamanho - 7;
		for (let i = tamanho; i >= 1; i--) {
			soma += numeros.charCodeAt(tamanho - i) * pos--;
			if (pos < 2) pos = 9;
		}
		let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
		if (resultado != digitos.charCodeAt(0)) return false;

		tamanho = tamanho + 1;
		numeros = cnpj.substring(0, tamanho);
		soma = 0;
		pos = tamanho - 7;
		for (let i = tamanho; i >= 1; i--) {
			soma += numeros.charCodeAt(tamanho - i) * pos--;
			if (pos < 2) pos = 9;
		}

		resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
		if (resultado != digitos.charCodeAt(1)) return false;

		return true;
	}

	static validarRg(valor: string) {
		if (valor != null) {
			let pattern = new RegExp("^s*([0-9a-zA-Z.-]+)s*$");
			return pattern.test(valor);
		} else {
			return false;
		}
	}

	static validarRgNumerico(valor: string) {
		if (valor != null) {
			let pattern = new RegExp("^[0-9]*$")
			return pattern.test(valor);
		} else {
			return false;
		}
	}

	static validaTituloEleitor(tituloEleitor: string): boolean {
		const tit = tituloEleitor.replace(/ /g, "");
		let digito1 = 0;
		let digito2 = 0;

		//- Separa dados do titulo
		const tamanho = tit.length;
		const digitos = tit.substr(tamanho - 2, 2);
		const estado = tit.substr(tamanho - 4, 2);
		let titulo = tit.substr(0, tamanho - 2);
		titulo = "000000000000" + titulo;
		titulo = titulo.substr(titulo.length - 11, titulo.length - 1);

		//- Verifica codigo do estado
		const codigosEstado = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28"];
		const exce: boolean = codigosEstado.indexOf(estado) === -1 ? false : true;

		digito1 =
			(titulo.charCodeAt(0) - 48) * 2 +
			(titulo.charCodeAt(1) - 48) * 9 +
			(titulo.charCodeAt(2) - 48) * 8 +
			(titulo.charCodeAt(3) - 48) * 7 +
			(titulo.charCodeAt(4) - 48) * 6 +
			(titulo.charCodeAt(5) - 48) * 5 +
			(titulo.charCodeAt(6) - 48) * 4 +
			(titulo.charCodeAt(7) - 48) * 3 +
			(titulo.charCodeAt(8) - 48) * 2;

		let resto = digito1 % 11;
		if (resto == 0) {
			if (exce) digito1 = 1;
			else digito1 = 0;
		} else {
			if (resto == 1) digito1 = 0;
			else digito1 = 11 - resto;
		}

		digito2 = (titulo.charCodeAt(9) - 48) * 4 + (titulo.charCodeAt(10) - 48) * 3 + digito1 * 2;

		resto = digito2 % 11;
		if (resto == 0) {
			if (exce) digito2 = 1;
			else digito2 = 0;
		} else {
			if (resto == 1) digito2 = 0;
			else digito2 = 11 - resto;
		}

		return digitos.charCodeAt(0) - 48 == digito1 && digitos.charCodeAt(1) - 48 == digito2 ? true : false;
	}
}
