import {HttpHeaders} from '@angular/common/http';

/**
 * Helper para download do blob's advindos do back-end
 */
export class DownloadHelper {

    /**
     * Metodo de download
     *
     * @param downloadUrl
     * @param filename
     */
    static download(downloadUrl, filename) {
        const a: any = document.createElement('a');
        a.style = 'display: none';
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
    }

    /**
     * Header especifico para o download
     */
    static header() {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }),
            responseType: 'blob' as 'json'
        };
    }


}
