import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {TipoDocumento} from '../model/tipo-documento';
import {Observable} from 'rxjs';
import {Funcionalidade} from '../enums/funcionalidade';
import {filter, map, shareReplay} from 'rxjs/operators';
import { TipoDocumentoDTO } from '../model/tipo-documento-dto';
import { Page, QueryBuilder } from '../util/pagination';

const CACHE_SIZE = 1;

@Injectable({
    providedIn: 'root'
})
export class TipoDocumentoService {

    cacheDocumentoCredenciamento$: Observable<TipoDocumento[]>

    constructor(private readonly http: HttpClient) {
    }

    buscarTodosTiposDeDocumento(): Observable<TipoDocumento[]> {
        return this.http.get<TipoDocumento[]>(environment.cadastroApiUrl + `/tipo-documento/buscar-todos`)
    }

    salvarParametrizacao(tiposDocumento: TipoDocumento[]): Observable<Object> {
        return this.http.post(environment.cadastroApiUrl + `/tipo-documento/salvar-parametrizacao`, tiposDocumento);
    }

    buscarPorFuncionalidade(funcionalidadeId: number): Observable<TipoDocumento[]> {
        return this.http.get<TipoDocumento[]>(environment.cadastroApiUrl + `/tipo-documento/${funcionalidadeId}`)
    }

    buscarPorFuncionalidadeDTO(funcionalidadeId: number): Observable<TipoDocumentoDTO[]> {
        return this.http.get<TipoDocumentoDTO[]>(environment.cadastroApiUrl + `/tipo-documento/dto/${funcionalidadeId}`)
    }

    buscarDocumentacaoCredenciamento(): Observable<TipoDocumento[]> {
        this.cacheDocumentoCredenciamento$ = this.buscarPorFuncionalidade(Funcionalidade.CREDENCIAMENTO_AR_DOCUMENTACAO)
            .pipe(
                shareReplay(CACHE_SIZE)
            )
        return this.cacheDocumentoCredenciamento$;
    }

    buscarDocumentosUnicos(): Observable<TipoDocumento[]> {
        return this.buscarDocumentacaoCredenciamento()
            .pipe(
               map(value => value.filter(value1 => value1.unico))
            );
    }

    buscarDocumentosMultiplos() {
        return this.buscarDocumentacaoCredenciamento()
            .pipe(
                map(value => value.filter(value1 => !value1.unico))
            );
    }

    buscarTipoDocumento(queryBuilder: QueryBuilder): Observable<Page<TipoDocumentoDTO>> {
        return this.http
        .get<TipoDocumentoDTO[]>(`${environment.cadastroApiUrl}/tipo-documento?${queryBuilder.buildQueryString()}`, { observe:"response" })
        .pipe(map(response => <Page<TipoDocumentoDTO>>Page.fromResposne(response)));
    }

    buscarPorGrupoDocumento(grupoDocumentoId: number): Observable<TipoDocumento[]> {
        return this.http.get<TipoDocumento[]>(environment.cadastroApiUrl + `/tipo-documento/documentos/${grupoDocumentoId}`)
    }

    buscarPorIdDocumento(documentoId: number): Observable<TipoDocumento[]> {
        return this.http.get<TipoDocumento[]>(environment.cadastroApiUrl + `/tipo-documento/id-documento/${documentoId}`)
    }

}
