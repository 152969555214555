
<div [formGroup]="form">
	<label>{{label}}</label>
	<mat-form-field class="form-field px-0 mt-0" appearance="outline">
		<input matInput [matDatepicker]="dp" [formControlName]="controlName" [disabled]="disabled" [max]="dataMaxima" maxlength="10" [readonly]="readonly">
		<mat-datepicker-toggle matSuffix [for]="dp" [disabled]="readonly"></mat-datepicker-toggle>
		<mat-datepicker #dp></mat-datepicker>

		<mat-error *ngIf="form.get(controlName).touched && form.get(controlName).invalid">
			{{validacao()}}
		</mat-error>
	</mat-form-field>
</div>
