import { DocumentoUploadDTO } from './../../../shared/model/documento-upload-dto';
import { AfterViewInit, Component, EventEmitter, OnInit, QueryList, ViewChildren } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { SelectItem } from "app/core/components/dropdown/select-item";
import { DsInputUploadFormComponent } from "app/core/components/ds-input-upload-form/ds-input-upload-form.component";
import { DsInputUploadFormService } from "app/core/components/ds-input-upload-form/ds-input-upload-form.service";
import { DsInputUploadComponent } from "app/core/components/ds-input-upload/ds-input-upload.component";
import { DsInputUploadService } from "app/core/components/ds-input-upload/ds-input-upload.service";
import { TextareaDialogComponent } from "app/core/components/textarea-dialog/textarea-dialog.component";
import { CriarTipoDocumentoService } from "app/modulos/tipo-documento/tipo-documento.service";
import { EventBusData } from "app/shared/class/event-bus-data.class";
import { AcoesTabela } from "app/shared/enums/acoesTabela";
import { Funcionalidade } from "app/shared/enums/funcionalidade";
import { GrupoDocumentoEnum } from "app/shared/enums/grupoDocumentoEnum";
import { Mensagem } from "app/shared/enums/mensagem";
import { Perfis } from "app/shared/enums/Perfis";
import { StatusCredenciamento } from "app/shared/enums/statusCredenciamento";
import { StatusCredenciamentoDescricao } from "app/shared/enums/statusCredenciamentoDescricao";
import { AssinaturaPdfHelper } from "app/shared/helper/assinatura-pdf-helper";
import { DocumentoUploadHelper } from "app/shared/helper/documento-upload-helper";
import { Acesso } from "app/shared/model/acesso/acesso";
import { CampoAcesso } from "app/shared/model/acesso/campoAcesso";
import { AnaliseTecnica } from "app/shared/model/analise-tecnica";
import { ComplementacaoDocumentos } from "app/shared/model/complementacao-documentos";
import { CredDescredPssDTO } from "app/shared/model/cred-descred-pss-dto";
import { DespachoCgafi } from "app/shared/model/despacho-cgafi";
import { DespachoDafn } from "app/shared/model/despacho-dafn";
import { DespachoDiretorPresidente } from "app/shared/model/despacho-diretor-presidente";
import { Documento } from "app/shared/model/Documento";
import { DocumentoSolicitacaoEntidadeDTO } from "app/shared/model/documento-solicitacao-entidade-dto";
import { EmpresaCredenciadaDTO } from 'app/shared/model/empresa-credenciada-dto';
import { ParecerTecnico } from "app/shared/model/parecer-tecnico";
import { PessoaJuridicaDTO } from "app/shared/model/pessoa-juridica-dto";
import { PublicacaoDou } from "app/shared/model/publicacao-dou";
import { SolicitacaoCredDescredPssDTO } from "app/shared/model/solicitacao-cred-descred-pss-dto";
import { SolicitacaoCredenciamentoDTO } from "app/shared/model/solicitacao-credenciamento-dto";
import { PayloadTabela, TabelaCustomButton, TabelaHeader } from "app/shared/model/tabela-model";
import { TipoDocumento } from "app/shared/model/tipo-documento";
import { AnaliseTecnicaResultadoService } from "app/shared/service/analise-tecnica-resultado.service";
import { ConsultarSolicitacoesService } from "app/shared/service/consultar-solicitacoes.service";
import { CredenciamentoDescredenciamentoPSSService } from "app/shared/service/credenciamento-descredenciamento-pss.service";
import { DocumentoUploadService } from "app/shared/service/documento-upload.service";
import { EmpresaCredenciadaService } from 'app/shared/service/empresa-credenciada.service';
import { EventBusService } from "app/shared/service/event-bus.service";
import { NotificationService } from "app/shared/service/notification.service";
import { PdfService } from "app/shared/service/pdf.service";
import { SolicitacaoService } from "app/shared/service/solicitacao-service";
import { TipoDocumentoService } from "app/shared/service/tipo-documento.service";
import { UsuarioService } from "app/shared/service/usuario.service";
import { Utils } from "app/shared/util/utils";
import * as moment from "moment";
import { Observable } from "rxjs";

@Component({
	selector: "app-detalhe-credenciamento-descredenciamento-pss-base",
	templateUrl: "./detalhe-credenciamento-descredenciamento-pss-base.component.html",
	styleUrls: ["./detalhe-credenciamento-descredenciamento-pss-base.component.scss"],
})
export class DetalheCredenciamentoDescredenciamentoPssBaseComponent implements OnInit, AfterViewInit {
	@ViewChildren(DsInputUploadComponent) inputAnexo: QueryList<DsInputUploadComponent>;
	private readonly mensagem = Mensagem;

	receberPublicacaoSolicitacaoArEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

	form: UntypedFormGroup;
	formDocumentosUpload: UntypedFormGroup;
	formDocumentosDescredenciamentoUpload: UntypedFormGroup;
	_tiposDocumentos: Array<TipoDocumento[]> = [];
	tiposDocumentos: SelectItem[];
	tiposDocumentosDescredenciamento: SelectItem[];
	funcionalidadeCredenciamento: Funcionalidade = Funcionalidade.MANUTENCAO_DE_AC1_CREDENCIAMENTO_SIMPLIFICADO_DE_PSS;
	grupoCredenciamento = GrupoDocumentoEnum.CREDENCIAMENTO_PSS;
	funcionalidadeDescredenciamento: Funcionalidade = Funcionalidade.MANUTENCAO_DE_AC1_DESCREDENCIAMENTO_SIMPLIFICADO_DE_PSS;
	grupoDescredenciamento = GrupoDocumentoEnum.DESCREDENCIAMENTO_PSS;
	documentoUploadComplementado: DocumentoSolicitacaoEntidadeDTO[] = []
	protocolo: string;
	activedRoute: ActivatedRoute;
	acesso_campos: any = {};
	router: Router;
	perfil: string;
	nomeDocumento: string;
	assinaturaPdfHelper: AssinaturaPdfHelper = null;
	credenciamentoPss: CredDescredPssDTO[] = [];
	descredenciamentoPss: CredDescredPssDTO[] = [];
	perfilITI: boolean = false;
	perfilGOVBR: boolean = false;
	aguardandoComplementacao: boolean = false;
	idDocumentoSelecionado: number;
	nomeEmpresaPss: string;
	nomeDocumentoSelecionado: string;
	documentosCredenciamentoDetalhe: DocumentoSolicitacaoEntidadeDTO[] = [];
	documentosDescredenciamentoDetalhe: DocumentoSolicitacaoEntidadeDTO[] = [];
	pessoaJuridicaCredenciamento: PessoaJuridicaDTO;
	idAreaAtuacao: number;

	arquivosInseridosComplementacao: any[] = [];

	acoesTabelaComplementacao: Array<AcoesTabela> = [];

	//Resultado Análise Técnica
	codigo: number = 0;
	formResultadoAnaliseTecnica: UntypedFormGroup;
	emitirParecerTecnicoShow: boolean = false;
	formValidAnaliseTecnica: boolean = false;
	resultado_analise_tecnica: boolean = false;

	solicitado: Boolean = false;

	//- Tabelas credenciamento
	pssCredenciamentoHeader: any[] = [

		{ title: "Nome (Razão Social)", field: "nome" },
		{ title: "CNPJ", field: "cnpj" },
		{ title: "Tipo de Atividade", field: "tipoAtividade", render: this.renderTabelaTipoAtividade },
		{ title: "Tipo de Ambiente", field: "tipoAmbiente", render: this.renderTabelaTipoAmbiente },
	];
	pssCredenciamentoRows: Array<any> = [];

	documentosPSSHeader: any[] = [];
	documentosPSSRows: Array<any> = [];

	//- Tabelas descredenciamento
	pssDescredenciamentoHeader: any[] = [
		{ title: "Nome (Razão Social)", field: "nome" },
		{ title: "CNPJ", field: "cnpj" },
		{ title: "Motivo do Descredenciamento", field: "motivo" },
		{ title: "Data de Encerramento das Atividades do PSS", field: "dataEncerramento", render: (index: number, row: CredDescredPssDTO, field: string) => { return moment(row.dataDescredenciamento).format('DD/MM/YYYY') } },
	];
	pssDescredenciamentoRows: Array<any> = [];

	documentosDescredenciamentoHeader: any[] = [];
	documentosDescredenciamentoRows: Array<any> = [];

	//Complementação de documentos
	complementacaoDataHeader: any[] = [
		{ title: "Descrição da complementação", field: "descricao" },
		{
			title: "Anexo",
			field: "descricao2",
			render: (i, entity, row) => {
				return entity.tipoDocumentoObj.nome;
			},
		},
		{ title: "Ações", field: "acoes", actions: ["edit", "preview", "delete"] },
	];
	complementacaoDataRows: any[] = [];

	complementacaoDosDocumentos: any[] = [];
	//Devolver desapacho para o Analista

	infoSolicitacao: SolicitacaoCredenciamentoDTO
	statusCredenciamentoDescricao: string
	empresaCredenciada: EmpresaCredenciadaDTO;

	constructor(
		private readonly formBuilder: UntypedFormBuilder,
		private readonly credenciamentoDescredenciamentoPSSService: CredenciamentoDescredenciamentoPSSService,
		private readonly consultaSolicitacoesService: ConsultarSolicitacoesService,
		private readonly notificationService: NotificationService,
		private readonly analiseTecnicaResultadoService: AnaliseTecnicaResultadoService,
		private readonly usuarioService: UsuarioService,
		private readonly documentoUploadService: DocumentoUploadService,
		private eventBusService: EventBusService,
		private readonly tipoDocumentoService: TipoDocumentoService,
		private readonly documentoUploadHelper: DocumentoUploadHelper,
		private readonly criarTipoDocumentoService: CriarTipoDocumentoService,
		private solicitacaoService: SolicitacaoService,
		private readonly dsInputUploadFormService: DsInputUploadFormService,
		private readonly empresaCredenciadaService: EmpresaCredenciadaService,
		private dialog: MatDialog,
		activedRoute: ActivatedRoute,
		router: Router,
		private pdfService: PdfService,
		private pdfDialog: MatDialog,
	) {
		this.router = router;
		this.activedRoute = activedRoute;
		this.assinaturaPdfHelper = new AssinaturaPdfHelper(pdfDialog, pdfService);
	}

	ngOnInit() {
		this.criarFormulario();
		this.receberPublicacaoSolicitacaoArEvent.emit(false);
	}

	ngAfterViewInit(): void {
		// this.buscarDocumentos();
		this.activedRoute.queryParams.subscribe({
			next: (param: Params) => {
				this.protocolo = param.protocolo;
			},
		});
		this.detalharSolicitacao();
	}

	private criarFormulario() {
		this.form = this.formBuilder.group({
			protocolo: [null, null],
			dataEnvio: [null, null],
			situacaoSolicitacao: [null, null],
			cnpjEmpresaRepresentante: [null, null],
			nomeEmpresaRepresentante: [null, null],
			tipoDocumento: [null, null],
			descricaoAnexo: [null, null],
			tipoDocumentoDescredenciamento: [null, null],
			descricaoAnexoDescredenciamento: [null, null]

		});

		this.formResultadoAnaliseTecnica = this.formBuilder.group({
			completo: [null, null],
			analise: [null, Validators.required],
		});
		this.formDocumentosUpload = this.formBuilder.group({});
		this.formDocumentosDescredenciamentoUpload = this.formBuilder.group({});
	}

	private detalharSolicitacao() {
		this.resetaVariaveisDeArray();
		this.credenciamentoDescredenciamentoPSSService.detalharCredenciamentoDescredenciamentoPSS(this.protocolo).subscribe({
			next: (detalhe: SolicitacaoCredDescredPssDTO) => {
				this.credenciamentoPss = detalhe.credenciamentoPss;
				this.descredenciamentoPss = detalhe.descredenciamentoPss;
				this.documentosCredenciamentoDetalhe = detalhe.docsCredenciamentoPss;
				this.documentosDescredenciamentoDetalhe = detalhe.docsDescredenciamentoPss;
				this.idAreaAtuacao = detalhe.solicitacao.areaAtuacaoUltimo.id;

				this.infoSolicitacao = detalhe.solicitacao
				this.buscarEmpresaCredenciada(detalhe.solicitacao.pessoaJuridicaSolicitante.cnpj)

				this.usuarioService.getPerfil().subscribe({
					next: (perfil: string) => {
						this.perfil = perfil;
						if (perfil === Perfis.ITI || perfil === Perfis.ITI_DEFAULT) {
							this.eventBusService.emit(new EventBusData('toggle-sidebar', true));
							this.perfilITI = true;
							this.getAcessosDoUsuario(detalhe.solicitacao.statusInterno);
						} else {
							this.getAcessosDoUsuario(detalhe.solicitacao.statusCredenciamento);
							this.perfilGOVBR = true;
							const statusSolicitante = Object.keys(StatusCredenciamento).find(sc => StatusCredenciamento[sc] == StatusCredenciamento.AGUARDANDO_COMPLEMENTACAO) as StatusCredenciamento
							if (detalhe.solicitacao.statusCredenciamento == statusSolicitante) {
								this.aguardandoComplementacao = true;
							}
						}

						const form = this.form.controls;
						this.statusCredenciamentoDescricao = StatusCredenciamentoDescricao[detalhe.solicitacao.statusCredenciamento];
						this.criaHeaderTabelas(StatusCredenciamentoDescricao[detalhe.solicitacao.statusInterno], StatusCredenciamentoDescricao[detalhe.solicitacao.statusCredenciamento]);
						//- Preenche o Credenciamento de PSS	
						detalhe.credenciamentoPss.forEach(credenciamentoPss => {
							this.pessoaJuridicaCredenciamento = credenciamentoPss.entidadePss;
							this.nomeEmpresaPss = credenciamentoPss.entidadePss.nome;
							this.pssCredenciamentoRows.push({
								nome: this.nomeEmpresaPss,
								cnpj: Utils.formatarCNPJ(credenciamentoPss.entidadePss.cnpj),
								tipoAtividade: credenciamentoPss.atividades,
								tipoAmbiente: credenciamentoPss.ambientes
							});

						});
						if (typeof detalhe.docsCredenciamentoPss != "undefined" && detalhe.docsCredenciamentoPss != null) {
							this.documentosCredenciamentoDetalhe = this.transformarDocumentosParaTabela(detalhe.docsCredenciamentoPss);
						}

						this.pssCredenciamentoRows = JSON.parse(JSON.stringify(this.pssCredenciamentoRows));
						this.documentosPSSRows = JSON.parse(JSON.stringify(this.documentosCredenciamentoDetalhe));

						this.buscaComplementacoesDeDocumentosDaSolicitacao();

						//- Preenche o Descredenciamento de PSS
						detalhe.descredenciamentoPss.forEach(descredenciamento => {
							this.pessoaJuridicaCredenciamento = descredenciamento.entidadePss;
							this.pssDescredenciamentoRows.push({
								nome: descredenciamento.entidadePss.nome,
								cnpj: Utils.formatarCNPJ(descredenciamento.entidadePss.cnpj),
								motivo: descredenciamento.motivoDescredenciamento,
								dataEncerramento: descredenciamento.dataDescredenciamento
							});

						});
						if (typeof detalhe.docsDescredenciamentoPss != "undefined" && detalhe.docsDescredenciamentoPss != null) {
							this.documentosDescredenciamentoDetalhe = this.transformarDocumentosParaTabela(detalhe.docsDescredenciamentoPss);
						}

						this.pssDescredenciamentoRows = JSON.parse(JSON.stringify(this.pssDescredenciamentoRows));
						this.documentosDescredenciamentoRows = JSON.parse(JSON.stringify(this.documentosDescredenciamentoDetalhe));

					}
				});
			}
		});
	}

	transformarDocumentosParaTabela(documentos: DocumentoSolicitacaoEntidadeDTO[]): any[] {
		let docs: any[] = [];
		documentos.forEach(documento => {
			docs.push({
				nome: documento.pessoaJuridica.nome,
				tipoDocumento: documento.documentoUpload.tipoDocumento.nome,
				descricaoAnexo: documento.documentoUpload.descricao,
				arquivo: documento.documentoUpload.nome,
				documento: documento,
				excluido: documento.documentoUpload.excluido,
			});
		});
		return docs;
	}

	renderTabelaTipoAtividade(index: number, row: any, field: string) {
		return row.tipoAtividade.map(ta => " " + ta.ordem + " - " + ta.atividade).join(", ")
	}


	renderTabelaTipoAmbiente(index: number, row: any, field: string) {
		return row.tipoAmbiente.map(ta => " " + ta.ordem + " - " + ta.ambiente).join(", ")
	}

	downloadArquivo(event: any): void {
		this.documentoUploadService.download(event.entity.documento.documentoUpload).subscribe(download => {
			this.documentoUploadService.handleDownload(download, `${event.entity.documento.documentoUpload.name || event.entity.documento.documentoUpload.nome}`);
		});
	}

	async voltar(mensagem?: string): Promise<void> {
		if (mensagem) this.notificationService.sucesso(mensagem);
		this.router.navigate(["/credenciamento/consultar-solicitacao"]);
	}

	//Iniciar Analise
	iniciarAnalise(): void {
		this.consultaSolicitacoesService.iniciarAnalise(this.protocolo).subscribe(resultado => {
			if (resultado.status === 200) {
				this.notificationService.sucesso(this.mensagem.SUCESSO.ANALISE_INICIADA);
				this.detalharSolicitacao();
			}
		});
	}

	//Analise Técnica
	salvarResultadoAnalise(): void {
		const { completo, analise } = this.formResultadoAnaliseTecnica.controls;
		const protocolo = Utils.formatarProtocolo(this.protocolo);
		const analiseEnvio: AnaliseTecnica = {
			protocolo: protocolo,
			completo: "COMPLETO",
			descricao: analise.value,
		};

		this.emitirParecerTecnicoShow = true;

		this.analiseTecnicaResultadoService.salvarAnaliseTecnica(analiseEnvio).subscribe(
			({ id }) => {
				this.codigo = id;
				this.notificationService.sucesso(this.mensagem.SUCESSO.ANALISE_REALIZADA);
				this.formValidAnaliseTecnica = false;
				this.resultado_analise_tecnica = true;
				if (!completo.value) {
					this.acesso_campos.campo_acesso_analise_tecnica = false;
				}
			},
			async () => {
				this.notificationService.erro(this.mensagem.ERRO.ATRIBUIR_SOLICITACAO_ANALISTA);
				this.voltar();
			}
		);
	}

	isFormValidAnaliseTecnica(event) {
		this.formValidAnaliseTecnica = event;
	}

	//Complementação de dados
	salvarSolicitacaoComplementacao(): void {
		const { completo, analise } = this.formResultadoAnaliseTecnica.controls;

		const protocolo = Utils.formatarProtocolo(this.protocolo);
		const analiseEnvio: AnaliseTecnica = {
			protocolo: protocolo,
			completo: "PENDENTE",
			descricao: analise.value,
		};

		if (this.complementacaoDataRows.length > 0) {
			this.analiseTecnicaResultadoService.salvarAnaliseTecnica(analiseEnvio).subscribe(
				({ id }) => {
					this.codigo = id;

					this.analiseTecnicaResultadoService.salvarComplementacaoDocumento(this.complementacaoDataRows).subscribe(result => {
						this.notificationService.sucesso(this.mensagem.SUCESSO.COMPLEMENTACAO_SOLICITADA);
						this.voltar();
						this.resultado_analise_tecnica = true;
						if (!completo.value) {
							this.acesso_campos.campo_acesso_analise_tecnica = false;
						}
					});
				},
				async erro => {
					this.notificationService.erro(this.mensagem.ERRO.ATRIBUIR_SOLICITACAO_ANALISTA);
					await this.voltar();
				}
			);
		}
	}

	onChangeCheckboxDialogComplementacao(documento?: DocumentoSolicitacaoEntidadeDTO): void {
		this.dialog
			.open(TextareaDialogComponent, {
				width: "400px",
				data: {
					title: documento.documentoUpload.tipoDocumento.nome,
					content: "",
					okButton: {
						show: true,
						label: "Ok",
					},
					cancelButton: {
						show: true,
						label: "Cancelar",
					},
				},
			})
			.afterClosed()
			.subscribe(descricao => {
				if (descricao !== false) {

					const solDoc: ComplementacaoDocumentos = {} as ComplementacaoDocumentos;

					solDoc.descricao = descricao;
					solDoc.tipoDocumentoObj = documento.documentoUpload.tipoDocumento;
					solDoc.protocolo = this.protocolo;
					solDoc.tipoDocumento = documento.documentoUpload.tipoDocumento.id;

					this.complementacaoDataRows.push(solDoc);
					this.complementacaoDataRows = JSON.parse(JSON.stringify(this.complementacaoDataRows));
					this.notificationService.sucesso(this.mensagem.SUCESSO.COMPLEMENTACAO_ADICIONADA);
				}

			});
	}

	resultadoCheckboxDialogComplementacaoDelete(payload) {
		let dataIndex: number;
		this.complementacaoDataRows.filter((element, index) => {
			if (element.hasOwnProperty('descricao') && payload.entity.hasOwnProperty('descricao') && element.descricao === payload.entity.descricao) {
				dataIndex = index;
			}
		});
		if (dataIndex != null) {
			this.complementacaoDataRows.splice(dataIndex, 1);
			this.complementacaoDataRows = JSON.parse(JSON.stringify(this.complementacaoDataRows));

			this.notificationService.sucesso("Solicitação de complementação excluída");
		}
	}

	//Enviar resposta da complementação do solicitante
	enviarComplementacaoSolicitante() {
		const complementos = [];
		this.complementacaoDataRows.forEach(complementacao => {
			this.arquivosInseridosComplementacao.forEach(arquivo => {
				if (complementacao.complementacao.tipoDocumento == arquivo.documentoUpload.tipoDocumentoId) {
					complementos.push({ ...complementacao, codigoDocumento: arquivo.documentoUpload.id, documento:arquivo.documentoUpload });
				}
			});
		});
		this.analiseTecnicaResultadoService.salvarComplementacaoDocumentoSolicitante(complementos).subscribe(() => {
			this.voltar(this.mensagem.SUCESSO.COMPLEMENTACAO_ENVIADA);
		});

	}

	private getAcessosDoUsuario(status: StatusCredenciamento): void {
		this.usuarioService.getAcessosDetalharCredenciamento(status, this.protocolo).subscribe(({ conteudo, observacoes }: Acesso) => {
			const { campos } = conteudo;
			if (campos.length >= 1) {
				campos.forEach(({ nome, mostrar }: CampoAcesso) => { this.acesso_campos[nome] = mostrar });
			}

			if (observacoes != null && observacoes.length > 0) {
				const analiseTecnicaIniciada: AnaliseTecnica = observacoes[0]?.objeto as AnaliseTecnica;
				if (analiseTecnicaIniciada) {
					this.resultado_analise_tecnica = true;
					this.formResultadoAnaliseTecnica.controls.completo.setValue(analiseTecnicaIniciada.completo);
					this.formResultadoAnaliseTecnica.controls.completo.disable({ onlySelf: true });
					this.formResultadoAnaliseTecnica.controls.analise.setValue(analiseTecnicaIniciada.descricao);
				}
			}
		});
	}


	/**
	 * @access private
	 * @description
	 * Busca complementações de documentos a serem feitas para a solicitação
	 *
	 * @returns ```void```
	 */
	private buscaComplementacoesDeDocumentosDaSolicitacao(): void {
		this.tiposDocumentos = [];
		this.analiseTecnicaResultadoService.buscarComplementacaoDocumento(this.protocolo).subscribe(complementacoes => {
			const idsTipoDocumento: number[] = [];
			if (complementacoes.length > 0) {
				this.complementacaoDosDocumentos = complementacoes
				complementacoes.forEach(complementacao => {
					idsTipoDocumento.push(complementacao.tipoDocumento);
					let td: TipoDocumento = {} as TipoDocumento;
					td.nome = complementacao.nomeDocumento;
					td.id = complementacao.tipoDocumento;
					const cd = {
						id: complementacao.id,
						descricao: complementacao.descricao,
						protocolo: this.protocolo,
						tipoDocumentoObj: td,
						complementacao,
					};


					this.complementacaoDataRows.push(cd);

					this.tiposDocumentos.push(
						{ label: complementacao.nomeDocumento, value: complementacao.tipoDocumento }
					);

				});

				if (this.perfil !== Perfis.ITI && this.perfil !== Perfis.ITI_DEFAULT) {
					idsTipoDocumento.forEach(idTipoDocumento => {
						let campoAlteracao = this.inputAnexo.filter((campo: DsInputUploadComponent) => parseInt(campo.id) === idTipoDocumento)[0];
						if (campoAlteracao) {
							campoAlteracao.disabled = false;
						}
					});
				}

				this.complementacaoDataRows = JSON.parse(JSON.stringify(this.complementacaoDataRows));
			}
		});
	}

	criaHeaderTabelas(statusInterno: StatusCredenciamentoDescricao, statusCredenciamento: StatusCredenciamentoDescricao) {
		if (statusCredenciamento == StatusCredenciamentoDescricao.AGUARDANDO_COMPLEMENTACAO) {
			this.documentosPSSHeader = [
				{
					title: "",
					field: "complementacao",
					actions: [{
						type: "",
						icon: "",
						label: "",
						title: ""
					} as TabelaCustomButton], exibir: (index: number, row: any, field: string) => {
						return typeof row.tipoDocumentoObj != "undefined" && row.tipoDocumentoObj != null && this.complementacaoDosDocumentos.some(comp => comp.tipoDocumento == row.tipoDocumentoObj.id)
					}
				},
				{ title: "Nome (Razão Social)", field: "nome" },
				{ title: "Tipo do documento", field: "tipoDocumento" },
				{ title: "Descrição do anexo", field: "descricaoAnexo" },
				{ title: "Arquivo", field: "arquivo" },
				{ title: "Ações", field: "acoes", actions: ["download"] },
			];

			this.documentosDescredenciamentoHeader = [
				{
					title: "",
					field: "complementacao",
					icon: {
						icon: "home",
						exibir: (index: number, row: any, field: string) => {
							return typeof row.tipoDocumentoObj != "undefined" && row.tipoDocumentoObj != null && this.complementacaoDosDocumentos.some(comp => comp.tipoDocumento == row.tipoDocumentoObj.id)
						}
					}
				},
				{ title: "Nome (Razão Social)", field: "nome" },
				{ title: "Tipo do documento", field: "tipoDocumento" },
				{ title: "Descrição do anexo", field: "descricaoAnexo" },
				{ title: "Arquivo", field: "arquivo" },
				{ title: "Ações", field: "acoes", actions: ["download"] }
			];
		} else if (statusInterno == StatusCredenciamentoDescricao.EM_ANALISE_TECNICA && this.perfilITI ) {
			this.documentosPSSHeader = [
				{ title: "Nome (Razão Social)", field: "nome" },
				{ title: "Tipo do documento", field: "tipoDocumento" },
				{ title: "Descrição do anexo", field: "descricaoAnexo" },
				{
					title: "Ações", field: "acoes", actions: ["download", {
						type: "",
						icon: "add_box",
						label: "",
						title: "Solicitar complementação do documento"
					} as TabelaCustomButton]
				},
			];

			this.documentosDescredenciamentoHeader = [
				{ title: "Nome (Razão Social)", field: "nome" },
				{ title: "Tipo do documento", field: "tipoDocumento" },
				{ title: "Descrição do anexo", field: "descricaoAnexo" },
				{
					title: "Ações", field: "acoes", actions: ["download", {
						type: "",
						icon: "add_box",
						label: "",
						title: "Solicitar complementação do documento"
					} as TabelaCustomButton]
				},
			];

		} else {
			this.documentosPSSHeader = [
				{ title: "Nome (Razão Social)", field: "nome" },
				{ title: "Tipo do documento", field: "tipoDocumento" },
				{ title: "Descrição do anexo", field: "descricaoAnexo" },
				{ title: "Ações", field: "acoes", actions: ["download"] },
			];
			this.documentosDescredenciamentoHeader = [
				{ title: "Nome (Razão Social)", field: "nome" },
				{ title: "Tipo do documento", field: "tipoDocumento" },
				{ title: "Descrição do anexo", field: "descricaoAnexo" },
				{ title: "Ações", field: "acoes", actions: ["download"] },
			];
		}
	}
	private resetaVariaveisDeArray(): void {
		this.acesso_campos = {};
		this.pssCredenciamentoRows = [];
		this.documentosPSSRows = [];
		this.pssDescredenciamentoRows = [];
		this.documentosDescredenciamentoRows = [];
	}

	tipoDocumentoSelecionado(payload: any) {
		this.idDocumentoSelecionado = payload;
		if (payload !== undefined) {
			this.tipoDocumentoService.buscarPorIdDocumento(payload).subscribe({
				next: (documentos: Array<TipoDocumento>) => {
					this._tiposDocumentos[this.funcionalidadeCredenciamento] = documentos;
					this.formDocumentosUpload = this.documentoUploadHelper.criarFormDocumentosUpload(this.formDocumentosUpload, documentos);
					documentos.forEach(documento => {
						this.nomeDocumentoSelecionado = documento.nome;
					})
				}
			});
		} else {
			this.formDocumentosUpload.reset();
			this.formDocumentosUpload = this.formBuilder.group({});
			this._tiposDocumentos = [];
		}
	}

	adicionarDocumentos() {
		let documentos = this.documentoUploadHelper.converterFormGroupToArray(this.formDocumentosUpload);
		let documentosEntidade: DocumentoSolicitacaoEntidadeDTO[] = []

		this.documentosPSSRows.forEach(documentoDetalhe => {
			if (documentoDetalhe.documento.documentoUpload.tipoDocumento.id === this.form.controls.tipoDocumento.value) {
				this.dsInputUploadFormService.delete(documentoDetalhe.documento.documentoUpload.id).subscribe();
			}
		});

		documentos.forEach(documento => {
			documentosEntidade.push({
				documentoUpload: {
					id: documento.id,
					identificador: documento.identificador,
					nome: documento.nome,
					protocolo: documento.protocolo,
					tipoDocumentoId: documento.tipoDocumentoId,
					descricao: this.form.controls.descricaoAnexo.value,
					solicitacaoCredenciamento: this.infoSolicitacao
				} as DocumentoUploadDTO,
				pessoaJuridica: this.pessoaJuridicaCredenciamento
			});

			this.arquivosInseridosComplementacao = documentosEntidade;

			this.solicitacaoService.salvarDocumentosUploadSolicitacaoEntidade(documentosEntidade).subscribe(res => {
				this.credenciamentoDescredenciamentoPSSService.detalharCredenciamentoDescredenciamentoPSS(this.protocolo).subscribe((detalhe: SolicitacaoCredDescredPssDTO) => {
					this.documentosCredenciamentoDetalhe = this.transformarDocumentosParaTabela(detalhe.docsCredenciamentoPss);
					this.documentosPSSRows = JSON.parse(JSON.stringify(this.documentosCredenciamentoDetalhe));
				});
			});
		});
	}

	tipoDocumentoSelecionadoDescredenciamento(payload: any) {
		this.idDocumentoSelecionado = payload;
		if (payload !== undefined) {
			this.tipoDocumentoService.buscarPorIdDocumento(payload).subscribe({
				next: (documentos: Array<TipoDocumento>) => {
					this._tiposDocumentos[this.funcionalidadeDescredenciamento] = documentos;
					this.formDocumentosDescredenciamentoUpload = this.documentoUploadHelper.criarFormDocumentosUpload(this.formDocumentosDescredenciamentoUpload, documentos);
					documentos.forEach(documento => {
						this.nomeDocumentoSelecionado = documento.nome;
					})
				}
			});
		} else {
			this.formDocumentosDescredenciamentoUpload.reset();
			this.formDocumentosDescredenciamentoUpload = this.formBuilder.group({});
			this._tiposDocumentos = [];
		}
	}

	adicionarDocumentosDescredenciamento() {
		let documentos = this.documentoUploadHelper.converterFormGroupToArray(this.formDocumentosDescredenciamentoUpload);
		let documentosEntidade: DocumentoSolicitacaoEntidadeDTO[] = []

		this.documentosDescredenciamentoRows.forEach(documentoDetalhe => {
			if (documentoDetalhe.documento.documentoUpload.tipoDocumento.id === this.form.controls.tipoDocumentoDescredenciamento.value) {
				this.dsInputUploadFormService.delete(documentoDetalhe.documento.documentoUpload.id).subscribe();
			}
		});

		documentos.forEach(documento => {
			documentosEntidade.push({
				documentoUpload: {
					id: documento.id,
					identificador: documento.identificador,
					nome: documento.nome,
					protocolo: documento.protocolo,
					tipoDocumentoId: documento.tipoDocumentoId,
					descricao: this.form.controls.descricaoAnexoDescredenciamento.value,
					solicitacaoCredenciamento: this.infoSolicitacao
				} as DocumentoUploadDTO,
				pessoaJuridica: this.pessoaJuridicaCredenciamento
			});

			this.solicitacaoService.salvarDocumentosUploadSolicitacaoEntidade(documentosEntidade).subscribe(res => {
				this.credenciamentoDescredenciamentoPSSService.detalharCredenciamentoDescredenciamentoPSS(this.protocolo).subscribe((detalhe: SolicitacaoCredDescredPssDTO) => {
					this.documentosDescredenciamentoDetalhe = this.transformarDocumentosParaTabela(detalhe.docsDescredenciamentoPss);
					this.documentosDescredenciamentoRows = JSON.parse(JSON.stringify(this.documentosDescredenciamentoDetalhe));
				});
			});
		});
	}

	buscarEmpresaCredenciada(cnpj: string) {
		this.empresaCredenciadaService.buscaEmpresaCredenciadaPeloCnpj(cnpj).subscribe(empresaCredenciadaEncontrada => {
			empresaCredenciadaEncontrada.forEach(empresa => {
				this.empresaCredenciada = empresa
			})
		})

	}
}