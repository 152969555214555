import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {TipoDocumento} from '../model/tipo-documento';
import {Observable} from 'rxjs';
import {Funcionalidade} from '../enums/funcionalidade';
import {filter, map, shareReplay} from 'rxjs/operators';

const CACHE_SIZE = 1;

@Injectable({
    providedIn: 'root'
})
export class PdfService {

    constructor(private readonly http: HttpClient) {
    }

    /**
     * É enviado o conteúdo puro em HTML no body da requisição
     * 
     * @param conteudo 
     * @returns 
     */
    gerarPdfDeTexto(conteudo: String): Observable<any> {
        return this.http.post(environment.pdfApiUrl + `/gerar-pdf/do-texto`, conteudo);
    }

    verificarLoginChallenge(challenge: string, certificado: string): Observable<any> {
        return this.http.post(environment.pdfApiUrl + `/login/challenge`, {
            challenge: challenge,
            certificado: certificado
        });
    }

    /**
     * Obter o conteúdo do template para exibir na tela e permitir edição
     * 
     * @param template 
     * @returns 
     */
    getConteudoTemplate(template: String): Observable<any> {
        return this.http.get(environment.pdfApiUrl + `/template/conteudo/` + template, {responseType: 'text'});
    }
}
