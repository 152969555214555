import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { NotificationService } from "../../shared/service/notification.service";
import { catchError, tap } from "rxjs/operators";
import { Injectable } from "@angular/core";

/**
 * Interceptor que verifica erros vindos do back-end e mostra ao usuário final uma mensagem amigável.
 */
@Injectable({
	providedIn: "root",
})
export class HttpErrorInterceptor implements HttpInterceptor {
	constructor(private notificationService: NotificationService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			tap(
				() => {},
				e => {
					console.log(e);
					if (e instanceof HttpErrorResponse) {
						if (!e.hasOwnProperty('status') || e.status != 200) {
							let mensagem = "";
							if (e.error instanceof Blob) {
								mensagem = "Não foi possível processar sua requisição, tente mais tarde.";
							} else {
								switch (e.status) {
									case 403:
										mensagem = "Usuário sem permissão de acessar a funcionalidade.";
										break;
									case 400:
										mensagem = e.error;
										break;
									default:
										mensagem = "Não foi possível processar sua requisição, tente mais tarde.";
								}
							}
							this.notificationService.erro(mensagem);
						}
					}
				}
			)
		);
	}
}
