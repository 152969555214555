<form [formGroup]="form">
    <h5 class="section-title">Encerrar Solicitação</h5>
    <div>
        <app-dropdown [form]="form" [items]="deferido" controlName="deferido" label="Selecione a conclusão da solicitação" placeholder="Selecione" (selectionChange)="deferimentoSelecionado($event)" class="col-8"></app-dropdown>
        <app-mensagem-tipo-padrao *ngIf="arquivadoSelecionado" [tipoMensagem]="tipoMensagemAlerta" [titulo]="titulo" [corpoMensagem]="corpoMensagem"></app-mensagem-tipo-padrao>
    
        <div mat-dialog-actions class="d-flex justify-content-end">
            <button type="button" *ngIf="data.cancelButton && data.cancelButton.show" [mat-dialog-close]="false" class="br-button secondary" (click)="onNoClick()">
                <i *ngIf="data.cancelButton.icon" class="material-icons">{{ data.cancelButton.icon }}</i>
                {{ data.cancelButton.label }}
            </button>

            <button type="button" *ngIf="data.okButton && data.okButton.show" [mat-dialog-close]="true" class="br-button primary" (click)="onOkClick($event)">
                <i *ngIf="data.okButton.icon" class="material-icons">{{ data.okButton.icon }}</i>
                {{ data.okButton.label }}
            </button>
        </div>
    </div>
</form>
