<ul>
	<li class="lista-pesquisa" *ngFor="let historicoDocumentacao of historicoDocumentosDataRows" >
		<span title="Enviado em {{ historicoDocumentacao.dataUpload }}h por {{ historicoDocumentacao.usuario }}" [ngClass]="{'excluido': (historicoDocumentacao.excluido === true) }" (click)="documentoSelecionado(historicoDocumentacao)">{{ historicoDocumentacao.nomeTipoDocumento }} - {{ historicoDocumentacao.id }}</span>
		&nbsp;
		<i title="Documento restrito" class="material-icons" *ngIf="historicoDocumentacao.restrito">lock</i>
		<i title="Documento público" class="material-icons" *ngIf="!historicoDocumentacao.restrito">public</i>
		<i title="Baixar documento" class="material-icons" (click)="downloadArquivo(historicoDocumentacao)">download</i>
	</li>
</ul>

<mat-dialog-content>
	<a href="http://infolab.stanford.edu/pub/papers/google.pdf#toolbar=0&navpanes=0&scrollbar=0"></a>
</mat-dialog-content>