<div class="br-breadcrumb pt-3 breadcrumb" aria-label="Breadcumb">
    <ul class="crumb-list">
        <li class="crumb home">
            <div class="br-button circle">
                <span class="sr-only">Página inicial</span>
                <i class="material-icons">home</i>
            </div>
        </li>
        <li *ngFor="let breadcrumb of breadcrumbs" class="crumb">
            <span [routerLink]="breadcrumb.url" routerLinkActive="router-link-active"></span>
            <i class="material-icons chevron">chevron_right</i>
            <span>{{ breadcrumb.label }}</span>
        </li>
    </ul>
</div>
