import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { ParametrizacaoMensageriaDTO } from "./parametrizacao-mensageria-dto";
import { SelectItem } from "app/core/components/dropdown/select-item";

const API_DOCUMENTACAO = `${environment.cadastroApiUrl}/parametrizacao-mensageria`;

@Injectable({
	providedIn: "root",
})
export class ParametrizacaoMensageriaService {
	constructor(private readonly http: HttpClient) {}

	buscaListaPadronizada(): Observable<SelectItem[]> {
		return this.http.get<SelectItem[]>(`${API_DOCUMENTACAO}/consulta-tipo-mensagem`);
	}

	salvaMensagem(mensagem: ParametrizacaoMensageriaDTO): Observable<ParametrizacaoMensageriaDTO> {
		return this.http.post<ParametrizacaoMensageriaDTO>(`${API_DOCUMENTACAO}/salvar`, mensagem);
	}

	buscaMensagens(): Observable<ParametrizacaoMensageriaDTO[]> {
		return this.http.get<ParametrizacaoMensageriaDTO[]>(`${API_DOCUMENTACAO}/consultar`);
	}

	editaMensagem(mensagem: ParametrizacaoMensageriaDTO): Observable<ParametrizacaoMensageriaDTO> {
		return this.http.patch<ParametrizacaoMensageriaDTO>(`${API_DOCUMENTACAO}/editar`, mensagem);
	}
}
