import { Component, Input, OnChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AcoesTabela } from "app/shared/enums/acoesTabela";
import { DocumentoUploadDTO } from "app/shared/model/documento-upload-dto";
import { TabelaHeader } from "app/shared/model/tabela-model";
import { DocumentoUploadService } from "app/shared/service/documento-upload.service";
import * as moment from "moment";
import { SimpleDialogComponent } from "../simple-dialog/simple-dialog.component";

@Component({
	selector: "app-historico-documentos-solicitacao",
	templateUrl: "./historico-documentos-solicitacao.component.html",
	styleUrls: ["./historico-documentos-solicitacao.component.scss"],
})
export class HistoricoDocumentosSolicitacaoComponent implements OnChanges {
	/* EVENTOS */
	@Input()
    protocolo: string;

	documentoRestrito: boolean = false;
   
	/* HISTÓRICO */
	historicoDocumentosSolicitacaoHeader: TabelaHeader[] = [
		{ title: "Id", field: "idDocumento", render: (index: number, row: DocumentoUploadDTO, field: string) => { return row.id }} ,
		{ title: "Nome", field: "nomeTipoDocumento" },
		{ title: "Nome do Arquivo", field: "nomeArquivo" },
        { title: "Data", field: "dataUpload", render: (index: number, row: DocumentoUploadDTO, field: string) => { return row.dataUpload }},
		{ title: "Ações", field: "acoes", actions: [AcoesTabela.DOWNLOAD] },
	];
	historicoDocumentosDataRows: any[] = [];


	constructor(private readonly documentoUploadService: DocumentoUploadService, private readonly dialog: MatDialog) {}

    ngOnChanges(): void {
		if (this.protocolo) this.detalharHistorico();
	}

	public detalharHistorico() {
        this.documentoUploadService.buscaDocumentosSolicitacao(this.protocolo).subscribe(documentos => {
            documentos.forEach(documento => {
				this.documentoRestrito = documento.tipoDocumento.restrito;
                this.historicoDocumentosDataRows.push({
                    id: documento.id, 
                    nomeTipoDocumento: documento.tipoDocumento.nome, 
                    nomeArquivo: documento.nome,
                    dataUpload: moment(documento.dataUpload).format("DD/MM/YYYY HH:mm"),
                    documento: documento,
					restrito: this.documentoRestrito,
					excluido: documento.excluido,
					usuario: documento.usuarioSso.nome
                });
                this.historicoDocumentosDataRows = JSON.parse(JSON.stringify(this.historicoDocumentosDataRows));
            });
        })
		
	}

    downloadArquivo(event): void {
		this.documentoUploadService.download(event.documento).subscribe(download => {
			this.documentoUploadService.handleDownload(download, `${event.documento.name || event.documento.nome}`);
		});
	}

	documentoSelecionado(documentoSelecionado: any){
		this.documentoUploadService.download(documentoSelecionado.documento).subscribe((response: any) => {
			const arquivo = new Blob([response], { type: "application/pdf" });
			const blob = window.URL.createObjectURL(arquivo);
			this.dialog.open(SimpleDialogComponent, {
				data: {
					title: documentoSelecionado.nomeTipoDocumento,
					url: blob,
					okButton: { show: true, label: "Voltar" },
					cancelButton: { show: false, label: "Cancelar" },
				},
				width: '1000px',
				height: '600px',
			});	
		}
			
		
	
	)}
}