import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Funcionalidade } from "app/shared/enums/funcionalidade";
import { GrupoDocumentoEnum } from "app/shared/enums/grupoDocumentoEnum";
import { Mensagem } from "app/shared/enums/mensagem";
import { DocumentoUploadHelper } from "app/shared/helper/documento-upload-helper";
import { DocumentoUploadDTO } from "app/shared/model/documento-upload-dto";
import { SolicitacaoCredenciamentoDTO } from "app/shared/model/solicitacao-credenciamento-dto";
import { TipoDocumento } from "app/shared/model/tipo-documento";
import { NotificationService } from "app/shared/service/notification.service";
import { SolicitacaoService } from "app/shared/service/solicitacao-service";
import { TipoDocumentoService } from "app/shared/service/tipo-documento.service";
import * as html2pdf from "html2pdf.js"

@Component({
	selector: "solicitacao-envio-documento-assinatura",
	templateUrl: "./solicitacao-envio-documento-assinatura.component.html",
	styleUrls: ["./solicitacao-envio-documento-assinatura.component.scss"],
})
export class SolicitacaoEnvioDocumentoAssinaturaComponent implements OnInit {
    private readonly mensagem = Mensagem;

    @Input() 
    protocolo: string;

    @Input()
    template: string;

    @Output()
    form: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    carregado: EventEmitter<any> = new EventEmitter<any>();


    grupoDocumento: GrupoDocumentoEnum = GrupoDocumentoEnum.DOCUMENTACAO_ASSINADA_NOVA_SOLICITACAO;
    formDocumentosUpload: UntypedFormGroup;
    solicitacaoCredenciamento: SolicitacaoCredenciamentoDTO;
    router: Router;
    documentos: TipoDocumento[] = [];
    _verificarDocumentosExistentesGrupo: Function = DocumentoUploadHelper.verificarDocumentosExistentesGrupo;

    constructor(readonly formBuilder: UntypedFormBuilder, private solicitacaoService: SolicitacaoService, private tipoDocumentoService: TipoDocumentoService, router: Router, private readonly documentoUploadHelper: DocumentoUploadHelper, private readonly notificationService: NotificationService) {
        this.router = router;
    }

    ngOnInit(): void {
        this.formDocumentosUpload = this.formBuilder.group({});
        this.buscaDocumentos();
    }

    public gerarPdf() {
        console.log(this.template)
        let filename = ""
        let element = ""
        if(this.template == "templateAr") {
            filename = 'solicitacao-ar.pdf'  // TODO:mudar isso aqui
            element = 'formularioAr'
        }
        if(this.template == "templateAc1"){
            filename = 'solicitacao-ac1.pdf'
            element = 'formularioAc1'
        }
        if(this.template == "templateAc2"){
            filename = 'solicitacao-ac2.pdf'
            element = 'formularioAc2'
        }
        if(this.template == "templatePSS"){
            filename = 'solicitacao-pss.pdf'
            element = 'formularioPSS'
        }
        if(this.template == "templatePSBio"){
            filename = 'solicitacao-psbio.pdf'
            element = 'formularioPSBio'
        }
        if(this.template == "templatePSC"){
            filename = 'solicitacao-psc.pdf'
            element = 'formularioPSC'
        }
        if(this.template == "templateManutencaoEnderecoAr"){
            filename = 'solicitacao-manutencao-endereco-ar.pdf'
            element = 'formularioManutencaoEnderecoAr'
        }
        if(this.template == "templateAct") {
            filename = 'solicitacao-act.pdf' 
            element = 'formularioAct'
        }
        const options = {
            filename: filename,
            image: {type: 'jpeg'},
            html2canvas: {},
            jsPDF: { orientation: 'landscape' }
        };
        
        const content: Element = document.getElementById(element)
        html2pdf().from(content).set(options).save();
       
        
    }

    private salvarDocumentoAssinado() {
        this.solicitacaoService.buscarSolicitacaoPeloProtocolo(this.protocolo).subscribe({
            next: (solicitacaoCredenciamento: SolicitacaoCredenciamentoDTO) => {
                this.solicitacaoCredenciamento = solicitacaoCredenciamento;               
                this.solicitacaoService.salvarDocumentosUpload(this.solicitacaoCredenciamento, this.documentoUploadHelper.converterFormGroupToArray(this.formDocumentosUpload)).subscribe();
            }
        });

    }

    public enviarSolicitacaoAssinada() {
        this.formDocumentosUpload.markAllAsTouched();
        if(this.formDocumentosUpload.valid) {
            this.salvarDocumentoAssinado();
            this.solicitacaoService.salvarDocumentoAssinado(this.protocolo).subscribe(res => {
                this.voltarConsulta()
            });
        } else {
            this.notificationService.erro(this.mensagem.ERRO.PREENCHA_CAMPOS_OBRIGATORIOS);
        }
    }

    async voltarConsulta(): Promise<void> {
		await this.router.navigate(["/credenciamento/consultar-solicitacao"]);
	}

    private buscaDocumentos(): void {
		this.tipoDocumentoService.buscarPorGrupoDocumento(this.grupoDocumento).subscribe((docs: TipoDocumento[]) => {
			this.documentos = docs;
            this.formDocumentosUpload = this.documentoUploadHelper.criarFormDocumentosUpload(this.formDocumentosUpload, docs)
		});
        this.form.emit(this.formDocumentosUpload);
        this.carregado.emit(true); 

	}

    async voltar() {
		this.router.navigate(["/credenciamento/consultar-solicitacao"]);
	}
}