import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "environments/environment";
import { DownloadHelper } from "app/shared/helper/download-helper";
import { DocumentoUploadDTO } from "app/shared/model/documento-upload-dto";
import { SolicitacaoCredenciamentoDTO } from "app/shared/model/solicitacao-credenciamento-dto";

const API_DOCUMENTACAO = `${environment.cadastroApiUrl}/documento-upload`;

@Injectable({
	providedIn: "root",
})
export class DsInputUploadFormService {
	constructor(private http: HttpClient) {}

	upload(documento: DocumentoUploadDTO, url?: string): Observable<DocumentoUploadDTO> {
		url = url || API_DOCUMENTACAO;
		return this.http.post<DocumentoUploadDTO>(url, documento);
	}

	download(documento: DocumentoUploadDTO): Observable<Blob> {
		return this.http.post<Blob>(API_DOCUMENTACAO + "/download", documento, DownloadHelper.header());
	}

	delete(documentoId: number, url?: string): Observable<void> {
		let _url = (url != null && url.length > 0 ? url : API_DOCUMENTACAO);
		return this.http.delete<void>(`${_url}/${documentoId}`);
	}

	handleDownload(response: any, nomeArquivo: string): any {
		const arquivo = new Blob([response], { type: response.type });
		const blob = window.URL.createObjectURL(arquivo);
		const link = document.createElement("a");

		link.href = blob;
		link.download = nomeArquivo;

		link.dispatchEvent(
			new MouseEvent("click", {
				bubbles: true,
				cancelable: true,
				view: window,
			})
		);

		// time out para firefox
		setTimeout(() => {
			window.URL.revokeObjectURL(blob);
			link.remove();
		}, 100);
	}
}
