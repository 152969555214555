export enum TipoSolicitacao {
	//- Cadastro de usuários
	PRESTADORES_SERVICO_BIOMETRICO = "Prestadores de Serviço Biométrico",
	PRESTADOR_SERVICO_CONFIANCA = "Prestador de Serviço de Confiança",
	PRESTADOR_SERVICO_SUPORTE = "Prestador de Serviço de Suporte",
	AGENTE_CREDENCIAMENTO = "Agente de Credenciamento",
	AGENTE_CERTIFICADO = "Agente Certificado",
	AGENTE_REGISTRO = "Agente de Registro",
	OPERADOR = "Operador",
	OPERADOR_AC = "Operador AC",
	REPRESENTANTE_LEGAL_AC = "Representante Legal da Autoridade Certificadora",
	REPRESENTANTE_LEGAL_AR = "Representante Legal da Autoridade de Registro",
	ADMINISTRADOR = "Administrador",

	//- Cadastro de Autoridades Certificadoras e de Registro
	AUTORIDADE_CERTIFICADORA = "Autoridade Certificadora",
	AUTORIDADE_CARIMBO_TEMPO = "Autoridade de Carimbo do Tempo",
	HABILITACAO_AUTORIDADE_CARIMBO_TEMPO = "Habilitação de Autoridade de Carimbo do Tempo",
	AUTORIDADE_REGISTRO = "Autoridade de Registro",
	CADASTRO_EMPRESA_AUDITORIA = "Cadastro de Auditoria",
    AUTORIDADE_CERTIFICADORA_NIVEL_2 = "Autoridade Certificadora Nível 2",
	AUTORIDADE_CERTIFICADORA_NIVEL_1 = "Autoridade Certificadora Nível 1",
	SOLICITACAO_CREDENCIAMENTO_AC1 = "Solicitação de Credenciamento de AC1",
	SOLICITACAO_CREDENCIAMENTO_PSS = "Solicitação de Credenciamento de PSS",

	//- Credenciamentos e Descredenciamentos
	CREDENCIAMENTO_AR = "Credenciamento AR",
	DESCREDENCIAMENTO_AR = "Solicitação de Descredenciamento AR",
	SOLICITACAO_DESCREDENCIAMENTO_AC1 = "Solicitação de Descredenciamento AC1",
	SOLICITACAO_DESCREDENCIAMENTO_AC2 = "Solicitação de Descredenciamento AC2",
	SOLICITACAO_DESCREDENCIAMENTO_PSS = "Solicitação de Descredenciamento PSS",


	//- Manutenções AR
	MANUTENCAO_INFORMAR_DADOS_ENDERECO = "Manutenção AR - Informar dados de endereço",
	MANUTENCAO_NOVA_VINCULACAO = "Manutenção AR - Nova Vinculação",
	MANUTENCAO_ALTERACAO_REPRESENTANTE_AR = "Manutenção AR - Alteração Representante Ar",
	MANUTENCAO_ALTERACAO_NOME_CONTRATUAL = "Manutenção AR - Alteração de Nome Contratual",
	MANUTENCAO_ALTERACAO_SOCIOS = "Manutenção AR - Alteração de Sócios",
	MANUTENCAO_INFORMAR_DADOS_AUDITORIA = "Manutenção AR - Informar dados de Auditoria",
	MANUTENCAO_ALTERACAO_REPRESENTANTE = "Manutenção AR - Alteração de Representante",
	
	PLANO_ANUAL_AUDITORIA_OPERACIONAL = "Plano Anual de Auditoria Operacional",

	//- Manutenções AC 1
	MANUTENCAO_AC1_NOVA_VINCULACAO = "Manutenção AC1 - Nova Vinculação",
	MANUTENCAO_AC1_NOME_CONTRATUAL = "Manutenção de AC1 - Alterar Nome Contratual",
	INFORMAR_DADOS_ENDERECO_AC1 = "Manutenção de AC1 - Informar dados de endereço",
	MANUTENCAO_AC1_ALTERACAO_SOCIO = "Manutenção de AC1 - Alteração de Sócios",
	PLANO_ANUAL_AUDITORIA_OPERACIONAL_AC1 = "Manutenção de AC1 - Plano Anual de Auditoria Operacional",
	MANUTENCAO_AC1_ALTERACAO_REPRESENTANTE = "Manutenção de AC1 - Alteração de Representante",
	MANUTENCAO_AC1_CREDENCIAMENTO_DESCREDENCIAMENTO_PSS = "Manutenção de AC1 - Credenciamento e Descredenciamento PSS",

	//- Manutenção de AC 2
	MANUTENCAO_AC2_NOVA_VINCULACAO = "Manutenção de AC2 - Nova Vinculação",
	MANUTENCAO_AC2_NOME_CONTRATUAL = "Manutenção de AC2 - Alterar Nome Contratual",
	INFORMAR_DADOS_ENDERECO_AC2 = "Manutenção de AC2 - Informar dados de endereço",
	MANUTENCAO_AC2_ALTERACAO_SOCIO = "Manutenção de AC2 - Informar Dados de Sócios",
	MANUTENCAO_AC2_ALTERACAO_REPRESENTANTE = "Manutenção de AC2 - Alterar Dados de Representante Legal",
    PLANO_ANUAL_AUDITORIA_OPERACIONAL_AC2 = "Manutenção de AC2 - Plano Anual de Auditoria Operacional",
	MANUTENCAO_AC2_CREDENCIAMENTO_DESCREDENCIAMENTO_PSS = "Manutenção de AC2 - Credenciamento e Descredenciamento PSS",

	// - Manutenção de PSS
	MANUTENCAO_PSS_ALTERACAO_SOCIO = "Manutenção de PSS - Informar Dados de Sócios",
	MANUTENCAO_PSS_ALTERACAO_REPRESENTANTE = "Manutenção de PSS - Alterar Dados de Representante Legal",
	MANUTENCAO_PSS_NOME_CONTRATUAL = "Manutenção de PSS - Alterar Nome Contratual",
    MANUTENCAO_PSS_NOVA_VINCULACAO = "Manutenção de PSS - Nova Vinculação",
	MANUTENCAO_PSS_ALTERAR_ENDERECO = "Manutenção de PSS - Alterar Dados de Endereço",

	// - Manutenção de PSBio
	MANUTENCAO_PSBIO_ALTERAR_ENDERECO = "Manutenção de PSBio - Alterar Dados de Endereço",
	MANUTENCAO_PSBIO_NOME_CONTRATUAL = "Manutenção de PSBio - Alterar Nome Contratual",
	
	// Credencamento PSC
	HABILITACAO_PSC = "Habilitação de PSC",
	CREDENCIAMENTO_PSC = "Credenciamento PSC",

	// - Manutenção de PSC
	MANUTENCAO_PSC_ALTERAR_ENDERECO = "Manutenção de PSC - Alterar Dados de Endereço",
	MANUTENCAO_PSC_NOME_CONTRATUAL = "Manutenção de PSC - Alterar Nome Contratual",

	// - Manutenção de ACT
	MANUTENCAO_ACT_ALTERAR_ENDERECO = "Manutenção de ACT - Alterar Dados de Endereço",
	MANUTENCAO_ACT_ALTERAR_NOME_CONTRATUAL = "Manutenção de ACT - Alteração de Nome Contratual",
}
