import {Component} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(
		private translate: TranslateService
  ) {
    // translate.setDefaultLang('en'); 
    // translate.use('en');
    this.translate.setDefaultLang(translate.getBrowserCultureLang());
  }
}
