<form [formGroup]="form" class="mt-3">
	<h4 *ngIf="perfilITI" class="form-title posicao-titulo" translate>fieldset.detalhe.legend.solicitacao-pss</h4>
	<h4 *ngIf="perfilGOVBR" class="form-title" translate>fieldset.detalhe.legend.solicitacao-pss</h4>
	<div>
		<div class="row">
			<div *ngIf="perfilITI" class="col-3">
				<section>
					<h5 class="section-title" translate>fieldset.legend.historico-documentos</h5>
					<app-historico-documentos-solicitacao [protocolo]="protocolo"></app-historico-documentos-solicitacao>
				</section>
			</div>
			
			<div [ngClass]="(perfilITI) ? 'col-9' : 'col-12'">
				<section>
					<app-cabecalho-solicitacao [solicitacaoCabecalho]="infoSolicitacao" [statusCredenciamentoDescricao]="statusCredenciamentoDescricao" [protocolo]="protocolo" [empresaCredenciada]="empresaCredenciada"></app-cabecalho-solicitacao>
					<app-historico-solicitacao [protocolo]="protocolo"></app-historico-solicitacao>
				</section>

				<h4 *ngIf="credenciamentoPss != null && credenciamentoPss.length > 0" class="form-title mt-4" translate>fieldset.solicitacoes.pss.credenciamento</h4>
				<section *ngIf="credenciamentoPss != null && credenciamentoPss.length > 0">
			
					<h5 class="section-title mt-3" translate>fieldset.solicitacoes.pss.dados-pss</h5>
					<super-tabela
					[header]="pssCredenciamentoHeader"
					[rows]="pssCredenciamentoRows"
					></super-tabela>

					<div *ngIf="aguardandoComplementacao" class="row align-self-center">
						<app-dropdown [form]="form" [items]="tiposDocumentos" controlName="tipoDocumento" placeholder="Selecione o documento" (selectionChange)="tipoDocumentoSelecionado($event)" class="col-4 align-self-center"></app-dropdown>
						<app-input-text [form]="form" controlName="descricaoAnexo" placeholder="Descrição do Anexo" [showLabel]="false" class="col-4 align-self-center"></app-input-text>
						
						<form [formGroup]="formDocumentosUpload">
							<div class="form-row">
								<div *ngFor="let tipoDoc of _tiposDocumentos[funcionalidadeCredenciamento]">
									<div *ngIf="tipoDoc.grupo != null && tipoDoc.grupo.id === grupoCredenciamento" >
										<app-ds-input-upload-form 
													[formControlName]="'upload_'+tipoDoc.id"
													[solicitacaoProtocolo]="protocolo"
													[tipoDocumentoId]="tipoDoc.id"
													[multiplosAquivos]="!tipoDoc.unico"
													[label]="tipoDoc.nome"
													[obrigatorio]="tipoDoc.obrigatorio"
													[orientacao]="tipoDoc.orientacao"
										></app-ds-input-upload-form>
									</div>
								</div>
								<hr class="dropdown-divider">
							</div>
						</form>
					</div>
					<div  *ngIf="aguardandoComplementacao" class="d-flex justify-content-end">
						<button class="br-button secondary ml-lg-auto mr-1" type="button" (click)="adicionarDocumentos()">
							<i class="material-icons">add</i> <span translate>botoes.adicionar</span>
						</button>
					</div>
			
					<h5 class="section-title mt-3" translate>fieldset.detalhe.legend.dados-documentacao</h5>
					<super-tabela
						[header]="documentosPSSHeader"
						[rows]="documentosPSSRows"
						(customBtn)="onChangeCheckboxDialogComplementacao($event.entity.documento)"
						(download)="downloadArquivo($event)"
					></super-tabela>
				</section>
			
				<!-- DESCREDENCIAMENTO PSS -->
				<h4 *ngIf="descredenciamentoPss != null && descredenciamentoPss.length > 0" class="form-title mt-4" translate>fieldset.legend.descredenciamento-pss</h4>
				<section *ngIf="descredenciamentoPss != null && descredenciamentoPss.length > 0">
					<h5 class="section-title mt-3" translate>fieldset.solicitacoes.pss.dados-pss</h5>
					<super-tabela
					[header]="pssDescredenciamentoHeader"
					[rows]="pssDescredenciamentoRows"
					></super-tabela>

					<div *ngIf="aguardandoComplementacao" class="row align-self-center">
						<app-dropdown [form]="form" [items]="tiposDocumentos" controlName="tipoDocumentoDescredenciamento" placeholder="Selecione o documento" (selectionChange)="tipoDocumentoSelecionadoDescredenciamento($event)" class="col-4 align-self-center"></app-dropdown>
						<app-input-text [form]="form" controlName="descricaoAnexoDescredenciamento" placeholder="Descrição do Anexo" [showLabel]="false" class="col-4 align-self-center"></app-input-text>
						
						<form [formGroup]="formDocumentosDescredenciamentoUpload">
							<div class="form-row">
								<div *ngFor="let tipoDoc of _tiposDocumentos[funcionalidadeDescredenciamento]">
									<div *ngIf="tipoDoc.grupo != null && tipoDoc.grupo.id === grupoDescredenciamento" >
										<app-ds-input-upload-form 
													[formControlName]="'upload_'+tipoDoc.id"
													[solicitacaoProtocolo]="protocolo"
													[tipoDocumentoId]="tipoDoc.id"
													[multiplosAquivos]="!tipoDoc.unico"
													[label]="tipoDoc.nome"
													[obrigatorio]="tipoDoc.obrigatorio"
													[orientacao]="tipoDoc.orientacao"
										></app-ds-input-upload-form>
									</div>
								</div>
								<hr class="dropdown-divider">
							</div>
						</form>
					</div>
					<div  *ngIf="aguardandoComplementacao" class="d-flex justify-content-end">
						<button class="br-button secondary ml-lg-auto mr-1" type="button" (click)="adicionarDocumentosDescredenciamento()">
							<i class="material-icons">add</i> <span translate>botoes.adicionar</span>
						</button>
					</div>
					
					<h5 class="section-title mt-3" translate>fieldset.detalhe.legend.dados-documentacao</h5>
					<super-tabela
						[header]="documentosDescredenciamentoHeader"
						[rows]="documentosDescredenciamentoRows"
						(download)="downloadArquivo($event)"
						(customBtn)="onChangeCheckboxDialogComplementacao($event.entity.documento)"
					></super-tabela>
				</section>

				<section *ngIf="perfilITI">
					<app-historico-solicitacao-encaminhamento [protocolo]="protocolo"></app-historico-solicitacao-encaminhamento>
				</section>
				
				<!-- ** ANALISE ITI ** -->
				<app-analise-area-atuacao *ngIf="acesso_campos.analise_iti"
					[protocolo]="protocolo"
					(isFormValid)="isFormValidAnaliseTecnica($event)"
					(deleteEvent)="resultadoCheckboxDialogComplementacaoDelete($event)"
					[idAreaAtuacao]="idAreaAtuacao"
					[complementacaoHeader]="complementacaoDataHeader"
					[complementacaoDataRows]="complementacaoDataRows"
				></app-analise-area-atuacao>

				<section *ngIf="acesso_campos.assinatura_pdf_documento_solicitacao && perfilGOVBR">
					<h5 class="section-title" translate>fieldset.solicitacoes.gerar-pdf</h5>
					<solicitacao-envio-documento-assinatura [protocolo]="protocolo"></solicitacao-envio-documento-assinatura>
				</section>
			
				<!-- Botões -->
				<div class="d-flex justify-content-between mt-3">
					<button class="br-button secondary" type="button" (click)="voltar()" translate>botoes.voltar</button>
					<div>			
						<!-- Iniciar análise -->
						<button *ngIf="acesso_campos.btn_iniciar_analise" class="br-button primary ml-2" type="button" (click)="iniciarAnalise()" translate>botoes.iniciar-analise</button>
			
						<!-- Enviar complementação de solicitação do solicitante -->
						<button *ngIf="acesso_campos.btn_enviar_complementacao" type="button" class="br-button primary ml-2" (click)="enviarComplementacaoSolicitante()" translate>botoes.enviar</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
