import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { TipoEmpresaEnum } from '../enums/tipoEmpresaEnum';
import { BuscarEmpresaCredenciadaDTO } from '../model/buscar-empresa-credenciada-dto';
import { EmpresaCredenciadaDTO } from '../model/empresa-credenciada-dto';

@Injectable({
  providedIn: 'root'
})
export class EmpresaCredenciadaService {

  constructor(private readonly http: HttpClient) { }

  buscarEmpresasCredenciadas(filtro: BuscarEmpresaCredenciadaDTO): Observable<EmpresaCredenciadaDTO[]> {
    if (typeof filtro.cnpjEmpresa === "undefined" || filtro.cnpjEmpresa == null) {
      filtro.cnpjEmpresa = "";
    }
    if (typeof filtro.nomeEmpresa === "undefined" || filtro.nomeEmpresa == null) {
      filtro.nomeEmpresa = "";
    }
		return this.http.post<EmpresaCredenciadaDTO[]>(`${environment.cadastroApiUrl}/empresa-credenciada/buscar`, filtro);
	}

  buscaEmpresaCredenciadaPeloCnpj(cnpj: string): Observable<EmpresaCredenciadaDTO[]> {
		return this.http.get<EmpresaCredenciadaDTO[]>(`${environment.cadastroApiUrl}/empresa-credenciada/busca-empresa-credenciada/${cnpj}`);
	}

  buscaAcRaiz(): Observable<EmpresaCredenciadaDTO> {
		return this.http.get<EmpresaCredenciadaDTO>(`${environment.cadastroApiUrl}/empresa-credenciada/busca-ac-raiz`);
	}

  buscaAtividadePeloId(id: number): Observable<EmpresaCredenciadaDTO> {
    return this.http.get<EmpresaCredenciadaDTO>(`${environment.cadastroApiUrl}/empresa-credenciada/busca-atividade-atuacao/${id}`);
  }

  buscaAtividadePelaSolicitacao(id: number): Observable<EmpresaCredenciadaDTO> {
    return this.http.get<EmpresaCredenciadaDTO>(`${environment.cadastroApiUrl}/empresa-credenciada/busca-atividade-solicitacao/${id}`);
  }

  buscaAtividadePeloProtocolo(protocolo: string): Observable<EmpresaCredenciadaDTO> {
    return this.http.get<EmpresaCredenciadaDTO>(`${environment.cadastroApiUrl}/empresa-credenciada/busca-atividade-protocolo/${protocolo}`);
  }

}
