import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import {DialogData} from "./simple-dialog-data";

@Component({
    selector: 'simple-dialog',
    templateUrl: 'simple-dialog.component.html',
    styleUrls: ['./simple-dialog.component.scss'],
})
export class SimpleDialogComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<SimpleDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, private sanitizer: DomSanitizer) {}
   
    ngOnInit(): void {
        this.url = this.safeUrl(this.data.url);
    }

    @Output()
    result = new EventEmitter();

    url: SafeResourceUrl;
    dialogResult: any;

    

    onNoClick(): void {
        this.dialogRef.close();
    }

    safeUrl(url:string) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
    
}
