import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { NotificacaoMensageria } from "../model/notificacao-mensageria-dto";
import { Page, QueryBuilder } from "../util/pagination";

@Injectable({
  providedIn: "root",
})
export class NotificacaoMensageriaService {
  constructor(private http: HttpClient) { }

  buscarTabela(queryBuilder: QueryBuilder): Observable<Page<NotificacaoMensageria>> {
    return this.http
    .get<NotificacaoMensageria[]>(`${environment.cadastroApiUrl}/notificacao-mensageria?${queryBuilder.buildQueryString()}`, { observe:"response" })
    .pipe(map(response => <Page<NotificacaoMensageria>>Page.fromResposne(response)));
  }

  buscarTotal(): Observable<Number> {
    return this.http.get<Number>(`${environment.cadastroApiUrl}/notificacao-mensageria/total`);
  }

  atualizarStatus(notificacaoMensageria: NotificacaoMensageria): Observable<any> {
    return this.http.patch(`${environment.cadastroApiUrl}/notificacao-mensageria/atualizar-status`, notificacaoMensageria);
  }
}
