<div class="py-2 px-1 pl-lg-5 pr-lg-2">
	<div class="header-top">
		<button class="btn-menu" (click)="openMenu()"><i class="material-icons">menu</i></button>
		<div class="header-logo">
			<img src="./assets/img/logo_iti_navbar.svg" width="85" height="25" alt="logo" />
			<h1>Portal Cubo</h1>
		</div>
		<div class="header-actions">
			<div class="header-links dropdown">
				<div class="br-list">
					<a class="br-item" type="button" href="perguntas-frequentes">Perguntas Frequentes</a>
					<a class="br-item" type="button" (click)="mudarContraste()"  title="Alterar para alto contraste"><i class="fas fa-adjust"></i></a>
					<a class="br-item" type="button" (click)="mudarFonteDislexia()" title="Alterar fonte do texto para Dislexia"><span style="font-family:Arial, Helvetica, sans-serif;">Qq</span> &rarr; <span style="font-family: 'Open-Dyslexic', sans-serif;">qQ</span></a>
				</div>
				<div>
					
				</div>
			</div>
			<span class="br-divider vertical mx-half mx-sm-1"></span>
			<div class="header-login">
				<div class="header-sign-in">
					<a class="nav-link" href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<div>
							<div class="photo">
								<img alt="avatar" src="./assets/img/profile.png" />
								&nbsp;
								<span>{{ profile?.firstName }} {{ profile?.lastName }}</span>
							</div>
						</div>
					</a>
					<div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
						<a class="dropdown-item" href="javascript:void(0)">
							<span>{{ profile?.username }}</span>
						</a>
						<a class="dropdown-item" href="javascript:void(0)" (click)="logout()">Logout</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
