<form [formGroup]="form" class="mt-3">
	<h4 class="form-title" translate>fieldset.legend.mensagem</h4>

	<section class="form-row align-items-center">
		<app-dropdown [form]="form" controlName="tiposDeMensagem" label="Tipo de Mensagem" class="col-5 dropdown" [items]="listaMensagens"></app-dropdown>

		<app-input-number [form]="form" controlName="time" class="col-2 pl-5" label="Tempo:"></app-input-number>
		<button class="br-button primary mb-4 ml-2" type="button" (click)="salvarDados()" translate>botoes.salvar</button>

		<super-tabela [header]="parametrizacaoHeader" [rows]="parametrizacaoRows" class="col-12 mt-4" (editar)="editarDados($event)"></super-tabela>
	</section>
</form>
