<div [ngClass]="{'template-base': true, 'alto-contraste': comContraste, 'fonte-dislexia': fonteDislexia}">
	<block-ui>
		<!-- Header aqui -->
		<header class="br-header" id="header" data-sticky>
			<app-navbar  
			(open)="opened = $event" 
			(comContraste)="receberEventoComContraste($event)"
			(fonteDislexia)="receberEventoFonteDislexia($event)"
			></app-navbar>
		</header>

		<!-- Área de conteúdo -->
		<main id="main">
			<!-- Define a largura da página -->
			<div class="pr-lg-3">
				<div class="row">
					<!-- Menu aqui -->
					<div class="br-menu">
						<app-sidebar [toggle]="opened"></app-sidebar>
					</div>
					<!-- Coluna do conteúdo aqui -->
					<div class="br-content-container col pt-3 pb-5 pl-2" [ngClass]="{ 'sidebar-active': opened }">
						<!-- Breadcrumbs aqui -->
						<div class="br-breadcrumb pl-sm-3">
							<app-breadcrumb></app-breadcrumb>
						</div>
						<!-- Conteúdo Principal aqui -->
						<div class="main-content pl-sm-3">
							<div class="container-fluid">
								<div class="row">
									<div class="col-md-12">
										<router-outlet></router-outlet>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	</block-ui>
	<!-- Footer aqui -->
		<!-- <footer id="footer" class="br-footer mt-auto"> -->
			<app-footer></app-footer>
		<!-- </footer> -->
</div>
