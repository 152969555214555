import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

import {GeradorAssinadorPdfData} from "./gerador-assinador-pdf-data";

@Component({
    selector: 'gerador-assinador-pdf',
    templateUrl: 'gerador-assinador-pdf.component.html',
    styleUrls: ['./gerador-assinador-pdf.component.scss'],
})
export class GeradorAssinadorPdfDialogComponent {

    constructor(public dialogRef: MatDialogRef<GeradorAssinadorPdfDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: GeradorAssinadorPdfData) {}

    static readonly ETAPA_GERACAO_ANDAMENTO: number = 1;
    static readonly ETAPA_GERACAO_SUCESSO: number = 2;
    static readonly ETAPA_GERACAO_ERRO: number = 3;
    static readonly ETAPA_ASSINATURA_ANDAMENTO: number = 4;
    static readonly ETAPA_ASSINATURA_AGUARDANDO_USUARIO: number = 8;
    static readonly ETAPA_ASSINATURA_SUCESSO: number = 5;
    static readonly ETAPA_ASSINATURA_ERRO: number = 6;
    static readonly ETAPA_ASSINATURA_SDK_NAO_INICIADO: number = 7;

    static readonly ETAPA_AGUARDANDO_SENHA_CERTIFICADO_DIGITAL: number = 9;
    static readonly ETAPA_AGUARDANDO_REDIRECIONAMENTO: number = 10;

    // Alternativa para acessar propriedades estaticas dentro do html
    GeradorAssinadorPdfDialogComponent = GeradorAssinadorPdfDialogComponent;

    @Output()
    result = new EventEmitter();

    dialogResult: any;

    onNoClick(): void {
        this.dialogRef.close();
    }
    
}
