import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { ROUTES } from "../sidebar/sidebar.component";
import { Location } from "@angular/common";
import { KeycloakProfile } from "keycloak-js";
import { KeycloakService } from "keycloak-angular";
import { NotificacaoMensageriaService } from "app/shared/service/notificacao-mensageria.service";
import { EventBusService } from "app/shared/service/event-bus.service";
import { EventBusData } from "app/shared/class/event-bus-data.class";

/**
 * Barra lateral superior, fragmento do template, guarda as informações do usuário logado.
 */
@Component({
	selector: "app-navbar",
	templateUrl: "./navbar.component.html",
	styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
	private navegacao: any[];
	logado = false;
	profile: KeycloakProfile | null = null;
	totalNotificacoes: Number;

	opened: boolean = true;
	
	comContrasteStatus: boolean = false;
	
	fonteDislexiaStatus: boolean = false;
	
	@Output() 
	open: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output()
	comContraste: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output()
	fonteDislexia: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(protected readonly location: Location, protected readonly keycloack: KeycloakService, private notificacaoService: NotificacaoMensageriaService, private eventBusService: EventBusService ) {}

	async ngOnInit() {

		this.eventBusService.on('toggle-sidebar', (opened: boolean) => {
			this.opened = opened;
			this.open.emit(this.opened);
		} )

		this.navegacao = ROUTES.filter(listTitle => listTitle);
		this.logado = await this.keycloack.isLoggedIn();

		if (this.logado) {
			this.profile = await this.keycloack.loadUserProfile();
		}

		this.notificacaoService.buscarTotal().subscribe(notificacoes => {
			this.totalNotificacoes = notificacoes;
		})	
	}

	ngAfterViewInit(){

		// this.eventBusService.emit(new EventBusData('dddddddd', false))


	}

	getTitle() {
		let titlee = this.location.prepareExternalUrl(this.location.path());
		if (titlee.charAt(0) === "#") {
			titlee = titlee.slice(1);
		}
		for (let item = 0; item < this.navegacao.length; item++) {
			if (this.navegacao[item].routerLink === titlee) {
				return this.navegacao[item].title;
			}
		}
		return "Bem vindo ao Cubo!";
	}

	logout(): void {
		this.keycloack.logout("");
		sessionStorage.setItem("filtro_consulta", null);
	}

	openMenu() {
		this.opened = !this.opened;
		this.open.emit(this.opened);
	}

	mudarContraste(){
		this.comContrasteStatus = !this.comContrasteStatus

		this.comContraste.emit(this.comContrasteStatus) 

	}
	mudarFonteDislexia(){
		this.fonteDislexiaStatus = !this.fonteDislexiaStatus
		this.fonteDislexia.emit(this.fonteDislexiaStatus) 

	}
}
