import {EventEmitter, Injectable} from '@angular/core';
import {TableSource} from './table-item';
import { TableComponent } from './table.component';

@Injectable()
export class TableService {

    emitirTabelaAlterada = new EventEmitter<any>();

    tableElements: TableSource;
    source = [];

    constructor() {
    }

    setTableElement(tableElement: TableSource): void {
        this.tableElements = tableElement;
        this.source = this.tableElements.source;
    }

    getColumns() {
        return this.tableElements.columns;
    }

    getSource() {
        return this.source;
    }

    addElement(data: any) {
        this.source = [...this.source, data];
        this.emitirTabelaAlterada.emit(data);
    }

    deleteElement(data: any) {
        let dataIndex: number;

        this.tableElements.columns.forEach(column => this.source.filter((element, index) => {
            if(element[column] && data[column]){
                if(element[column] === data[column]) {
                    dataIndex = index;
                }
            }
        }));

        this.source.splice(dataIndex, 1);
        const newSource = [...this.source];
        this.emitirTabelaAlterada.emit(newSource);
    }

    editElement(data: any): any {
        let elementReturn: any;

        this.tableElements.columns.forEach(column => this.source.filter(element => {
            if(element[column] && data[column]){
                if(element[column] === data[column]) {
                    elementReturn = element;
                }
            }
        }));

        return elementReturn;
    }

    clearSource() {
        this.source.splice(0, this.source.length);
        const newSource = [...this.source];
        this.emitirTabelaAlterada.emit(newSource);
    }

    adicionarElemento(nome: string, tipo: string, tamanho: string, idTabela: string){
        const arquivo = {
            nome: nome,
            tipo: tipo,
            tamanho: tamanho
        }
        // const botaoRemover = this.criarBotaoRemover();
        // botaoRemover.innerHTML = `<i class="material-icons">delete</i>`;
        let botoesDeAcao = [] as HTMLAnchorElement[];
        botoesDeAcao.push();
        let tabela = new TableComponent(new TableService);
        tabela.setObjeto(arquivo, idTabela, botoesDeAcao, false, false) ;
    }
}
