<div [ngClass]="{ 'sidebar-active': toggle }">
	<div class="sidebar-wrapper">
		<ul class="nav">
			<li routerLinkActive="active" *ngFor="let menu of menuItems" class="nav-item">
				<a class="nav-link mr-0 mt-0" [routerLink]="[menu.routerLink]" *ngIf="menu.routerLink">
					<i class="material-icons">{{ menu.icon }}</i>
					<p>{{ menu.title }}</p>
				</a>

				<a class="nav-link mr-0 mt-0" data-toggle="collapse" *ngIf="menu.submenus" (click)="menu.collapsed = !menu.collapsed" [attr.aria-expanded]="!menu.collapsed" [attr.aria-controls]="menu.collapse">
					<i class="material-icons">{{ menu.icon }}</i>
					<p class="d-flex justify-content-between">
						<span>{{ menu.title }}</span>
						<i class="material-icons icone-collapse">navigate_next</i>
					</p>
				</a>
				<div id="{{ menu.collapse }}" class="collapse" *ngIf="menu.submenus" [collapse]="menu.collapsed" [isAnimated]="true">
					<ul class="nav">
						<li class="nav-item " routerLinkActive="active" *ngFor="let submenu of menu.submenus">
							<a class="nav-link mr-0 mt-0 py-3" [routerLink]="[submenu.routerLink]" (click)="submenu.collapsed = !submenu.collapsed">
								<i class="material-icons">{{ submenu.icon }}</i>
								<span>{{ submenu.title }}</span>
							</a>
						</li>
					</ul>
				</div>
			</li>
		</ul>
	</div>
</div>
