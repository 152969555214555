import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { EventBusData } from '../class/event-bus-data.class';

/**
 * Service para componentes comunicarem entre si independente da ordem hierarquica
 */
@Injectable({
  providedIn: 'root'
})
export class EventBusService {
    private subject$ = new Subject();

    constructor() {
        // Construtor em branco
    }

    emit(event: EventBusData) {
        this.subject$.next(event);
    }

    on(eventName: string, payload: any): Subscription {
        return this.subject$.pipe(
            filter((e: EventBusData) => e.name === eventName),
            map((e: EventBusData) => e["payload"])
        ).subscribe(payload);
    }
}