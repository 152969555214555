import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrimPipe } from './trim.pipe';
import { JsonFilterPipe } from './json-filter.pipe';
import { CnpjPipe } from './cnpj.pipe';
import { CapitalizarPipe } from './capitalizar.pipe';
import { IconSearchPipe } from './search-filter.pipe';
import { FileSizePipe } from "./file-size.pipe";

@NgModule({
	declarations: [TrimPipe, JsonFilterPipe, CnpjPipe, CapitalizarPipe, IconSearchPipe, FileSizePipe],
	exports: [TrimPipe, JsonFilterPipe, CnpjPipe, IconSearchPipe, FileSizePipe],
	imports: [CommonModule],
})
export class CuboPipeModule {}
