import { Component, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AcoesTabela } from 'app/shared/enums/acoesTabela';
import { SolicitacaoGeralEncaminhamentoDTO } from 'app/shared/model/solicitacao-geral-encaminhamento-dto';
import { TabelaHeader } from 'app/shared/model/tabela-model';
import { SolicitacaoService } from 'app/shared/service/solicitacao-service';
import * as moment from 'moment';
import { SimpleDialogComponent } from '../simple-dialog/simple-dialog.component';

@Component({
	selector: "app-historico-solicitacao-encaminhamento",
	templateUrl: "./historico-solicitacao-encaminhamento.component.html",
	styleUrls: ["./historico-solicitacao-encaminhamento.component.scss"],
})
export class HistoricoSolicitacaoEncaminhamentoComponent implements OnChanges {
	/* EVENTOS */
	@Input() protocolo: string;

	/* HISTÓRICO */
	historicoEncaminhamentoHeader: TabelaHeader[] = [
		{ title: "Data", field: "data" },
		{ title: "Área anterior", field: "areaAnterior" },
		{ title: "", field: "seta", render: (index: number, row: TabelaHeader, field: string) => { return row.title = "→" } },
		{ title: "Área encaminhada", field: "areaEncaminhada" },
        { title: "Responsável pelo encaminhamento", field: "responsavel" },
		{ title: "Ações", field: "acoes", actions: [AcoesTabela.VISUALIZAR]},
	];
	historicoEncaminhamentoRows: any[] = [];

	constructor(private readonly solicitacaoService: SolicitacaoService, public dialog: MatDialog) {}

	ngOnChanges(): void {
		if (this.protocolo) this.detalharHistorico();
	}

	public visualizarRegistro(payload: any) {
		this.dialog.open(SimpleDialogComponent, {
			data: {
				title: "Visualização do parecer",
				content: (payload.entity.encaminhamento.parecer != null && payload.entity.encaminhamento.parecer.length > 0 ? payload.entity.encaminhamento.parecer : "Nenhum parecer fornecido"),
				okButton: { show: true, label: "Ok" },
				cancelButton: { show: false, label: "Cancelar" },
			},
            width: "500px",
            height: "500px"
		});
	}

	public detalharHistorico() {
        this.solicitacaoService.buscarEncaminhamentoPelaSolicitacao(this.protocolo).subscribe(encaminhamentos => {
            encaminhamentos.forEach(encaminhamento => {
                this.historicoEncaminhamentoRows.push({
                    data: moment(encaminhamento.dataEncaminhamento).format("DD/MM/YYYY HH:mm")+"h",
                    areaAnterior: (encaminhamento.areaAtuacaoAnterior != null ? encaminhamento.areaAtuacaoAnterior.nome : "Solicitante"),
                    areaEncaminhada: (encaminhamento.areaAtuacaoEncaminhada != null ? encaminhamento.areaAtuacaoEncaminhada.nome : "Solicitante"),
                    responsavel: encaminhamento.usuarioSsoResponsavel.nome,
                    encaminhamento: encaminhamento
                });
                this.historicoEncaminhamentoRows = JSON.parse(JSON.stringify(this.historicoEncaminhamentoRows));
            });
        });
	}
}
