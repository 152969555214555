import { Component, Input, OnInit, AfterViewInit, Output, EventEmitter } from "@angular/core";
import { BaseInput } from "../base/base-input";
import { SelectItem, SelectItemGroup } from "./select-item";
import { UntypedFormGroup } from "@angular/forms";

@Component({
	selector: "app-dropdown",
	templateUrl: "./dropdown.component.html",
	styleUrls: ["./dropdown.component.scss"],
})
export class DropdownComponent extends BaseInput implements OnInit, AfterViewInit {
	@Input()
	items?: SelectItem[];

	@Input()
	groupItems?: Array<SelectItemGroup>;

	@Input()
	controlName: any;

	@Input()
	label = "";

	@Input()
	form: UntypedFormGroup;

	@Input()
	disabled: Boolean;

	@Input()
	placeholder: "";

	@Output()
	selectionChange: EventEmitter<any> = new EventEmitter<any>();

	constructor() {
		super();
	}

	ngOnInit(): void {

	}

	ngAfterViewInit(): void {
		this.form.get(this.controlName).valueChanges.subscribe(value => {
			this.selectionChange.emit(value);
		})
	}
}
