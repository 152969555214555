<h2 mat-dialog-title>{{data.title}}</h2>

<div mat-dialog-content *ngIf="data.content">
    <div *ngFor="let content of data.content">
        <h3 mat-dialog-title *ngIf="content.title">{{content.title}}</h3>
        <p *ngFor="let paragraph of content.paragraphs">{{paragraph}}</p>
    </div>
</div>
<div mat-dialog-content *ngIf="data.dialogComponent" [formGroup]="data?.dialogComponent.form">
    <app-dropdown *ngIf="data?.dialogComponent.type === 'dropdown'" [form]="data?.dialogComponent.form" [controlName]="data?.dialogComponent.controlName" [label]="data?.dialogComponent.label" [items]="data?.dialogComponent?.itemsDropdown"></app-dropdown>

    <app-input-text
        *ngIf="data?.dialogComponent.type === 'textarea'"
        [form]="data?.dialogComponent.form"
        [controlName]="data?.dialogComponent.controlName"
        [label]="data?.dialogComponent.label"
    ></app-input-text>

</div>

<div *ngIf="!data.dialogComponent; then defaultButtons; else componentButtons"></div>
<ng-template #defaultButtons>
    <div mat-dialog-actions class="d-flex justify-content-end">
        <button *ngIf="data.cancelButton.show" mat-dialog-close [mat-dialog-close]="false" class="br-button secondary">
            <i *ngIf="data.cancelButton.icon" class="material-icons">{{data.cancelButton.icon}}</i>
            {{data.cancelButton.label}}
        </button>

        <button *ngIf="data.okButton.show" [mat-dialog-close]="true" class=" br-button primary">
            <i *ngIf="data.okButton.icon" class="material-icons">{{data.okButton.icon}}</i>
            {{data.okButton.label}}
        </button>
    </div>
</ng-template>

<ng-template #componentButtons>
    <div mat-dialog-actions class="d-flex justify-content-end">
        <button *ngIf="data.cancelButton.show" mat-dialog-close [mat-dialog-close]="false" class="br-button secondary">
            <i *ngIf="data.cancelButton.icon" class="material-icons">{{data.cancelButton.icon}}</i>
            {{data.cancelButton.label}}
        </button>

        <button *ngIf="data.okButton.show" [mat-dialog-close]="data.dialogComponent.form.get(data.dialogComponent.controlName).value" class="br-button primary">
            <i *ngIf="data.okButton.icon" class="material-icons">{{data.okButton.icon}}</i>
            {{data.okButton.label}}
        </button>
    </div>
</ng-template>
