import { Injectable } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GrupoDocumentoEnum } from '../enums/grupoDocumentoEnum';
import { DocumentoUploadDTO } from '../model/documento-upload-dto';
import { TipoDocumento } from '../model/tipo-documento';

@Injectable({
	providedIn: 'root',
})
export class DocumentoUploadHelper {

	constructor(
		readonly formBuilder: UntypedFormBuilder
	) {

	}

	/**
	 * 
	 * @param documentos Função que verifica se existe pelo menos 1 documento, dentro de um array fornecido, que pertença a um grupo específico
	 * @param grupo 
	 * @returns 
	 */
	static verificarDocumentosExistentesGrupo(documentos: TipoDocumento[], grupo: GrupoDocumentoEnum): boolean {
		if (documentos != null && Array.isArray(documentos) && documentos.length > 0 && grupo != null) {
			return documentos.some(doc => (doc != null && doc.grupo != null && doc.grupo.id == grupo));
		}
		return false;
	}

	/**
	 * 
	 * @param formUpload Função para agilizar a criação de FormGroup dinâmico para o upload de arquivos
	 * @param docs 
	 * @returns 
	 */
	criarFormDocumentosUpload(formUpload: UntypedFormGroup, docs: TipoDocumento[]): UntypedFormGroup{
		docs.forEach((documento: TipoDocumento) => {
			formUpload.addControl('upload_'+documento.id, new FormControl(null, (documento.obrigatorio == true ? Validators.required : [])));
		});

		return formUpload;
	}

	/**
	 * 
	 * @param formUpload Função para agilizar o ato de popular todos os FormGroups, de maneira dinâmica, de todos os documentos carregados
	 * @param documentos 
	 * @returns 
	 */
	popularFormDocumentosUpload(formUpload: UntypedFormGroup, documentos: DocumentoUploadDTO[]): UntypedFormGroup {
		console.log('popularFormDocumentosUpload(formUpload: UntypedFormGroup, documentos: DocumentoUploadDTO[]): UntypedFormGroup',documentos,formUpload)
		if (Array.isArray(documentos) && documentos.length > 0) {
			documentos.forEach((elem: DocumentoUploadDTO, index, arr) => {
				if (typeof formUpload.controls['upload_' + elem.tipoDocumento.id] !== "undefined") {
					let docs: DocumentoUploadDTO[] = formUpload.controls['upload_' + elem.tipoDocumento.id].value;
					if (docs == null) {
						docs = [];
					}
					docs.push(elem);
					formUpload.controls['upload_' + elem.tipoDocumento.id].setValue(docs);
				}
			});
		}
		return formUpload;
	}
	popularFormDocumentosUploadAGR(formUpload: UntypedFormGroup, documentos: DocumentoUploadDTO[]): UntypedFormGroup {
		if (Array.isArray(documentos) && documentos.length > 0) {
			documentos.forEach((elem: DocumentoUploadDTO, index, arr) => {
				if (typeof formUpload.controls['upload_' + elem.tipoDocumentoId] !== "undefined") {
					let docs: DocumentoUploadDTO[] = formUpload.controls['upload_' + elem.tipoDocumentoId].value;
					if (docs == null) {
						docs = [];
					}
					docs.push(elem);
					formUpload.controls['upload_' + elem.tipoDocumentoId].setValue(docs);
				}
			});
		}
		return formUpload;
	}
	converterFormGroupToArray(form: UntypedFormGroup): DocumentoUploadDTO[] {
		let documentos: DocumentoUploadDTO[] = [];
		Object.keys(form.controls).forEach(keyForm => {
			if (form.controls[keyForm].value != null) {
				if (Array.isArray(form.controls[keyForm].value)) {
					form.controls[keyForm].value.forEach(valDocUpload => {
						documentos.push(valDocUpload);
					})
				} else {
					documentos.push(form.controls[keyForm].value);
				}
			}
		});
		return documentos;
	}

}
