
import { environment } from "../../../environments/environment";
import { Observable } from 'rxjs';
import { MatDialog } from "@angular/material/dialog";
import { PdfService } from "app/shared/service/pdf.service";
import { GeradorAssinadorPdfDialogComponent } from "app/core/components/gerador-assinador-pdf/gerador-assinador-pdf.component";
import { Buffer } from 'buffer';

//Declaração para encontrar o 'sdkDesktop' importado por meio de scripts no angular.json
declare let sdkDesktop: any;

/**
 * Helper para interagir com o EVO SDK para assinatura de PDF's
 */
export class AssinaturaPdfHelper {

    _pdfDialog: MatDialog;
    _pdfService: PdfService;

    constructor(
        public pdfDialog: MatDialog,
        public pdfService: PdfService
    ) {
        this._pdfDialog = pdfDialog;
        this._pdfService = pdfService;
    }

    static files = [];

    static isSdkExecutando(callbackSucesso: Function, callbackErro?: Function) {
        sdkDesktop.checkStarted((r: any) => {
            if (callbackSucesso) {
                callbackSucesso(r);
            }
        }, (r: any) => {
            if (callbackErro) {
                callbackErro(r);
            } else {
                /**
                 * @todo implementar aviso de erro personalizado
                 */
            }
        });
    }

    static getSdkDesktop() {
        return sdkDesktop;
    }

    static setParametrosIniciais() {
        sdkDesktop.setParameters({
            "colCount" : 1,
            "colName.0" : "Arquivo",
            "colAlias.0" : "fileName",
            "serverURL": environment.pdfApiUrlEvoSdk + "/pdf-assinado/salvar",
            //"cookie": "JSESSIONID=#{session.getId()}",
            "sendMode": "Multipart",
            "showCertDialog": "true",
            "detachedSignature": "false",
            "addCertificatePath": "true",
            "digestAlgorithm": "SHA512",
            "envelopeType": "pades",
            "usePolicy": "true",
            //https://www.gov.br/iti/pt-br/assuntos/repositorio/assinatura-digital-com-referencias-completas-ad-rc
            "policyURL": "http://politicas.icpbrasil.gov.br/PA_PAdES_AD_RC_v1_2.der",
            "policyURLInSignature": "http://politicas.icpbrasil.gov.br/PA_PAdES_AD_RC_v1_2.der",
          });
    }

    static addArquivo(filename, url) {
        this.files.push({
            "fileName" : filename,
            "url" : url,
            "checked" : true
        })
      
    }

    static assinarDocumentos(callbackAssinatura: Function) {
        sdkDesktop.setFileParameters(this.files);
        sdkDesktop.signAndSendDocuments((ret: any) => {
            if (callbackAssinatura) {
                callbackAssinatura(ret);
            }
        });
    }

    gerarPdfDoTexto(conteudo: string): Observable<any> {
        return new Observable((observer) => {
            this._pdfDialog.open(GeradorAssinadorPdfDialogComponent, { data: { etapa: GeradorAssinadorPdfDialogComponent.ETAPA_GERACAO_ANDAMENTO } });
            
            //observer.next(position);
            this._pdfService.gerarPdfDeTexto(conteudo).subscribe(ret => {
                if (ret.hasOwnProperty("nomeArquivo") && ret.nomeArquivo != null && ret.nomeArquivo.length > 0) {
                    this._pdfDialog.closeAll();
                    observer.next(ret.nomeArquivo)
                } else {
                    observer.error("Algum erro ocorreu na geração do PDF");
                }
            });
        });
        
    }

    assinarDocumento(nomeArquivo): Observable<any> {
        return new Observable((observer) => {
            AssinaturaPdfHelper.isSdkExecutando((r) => {
                this._pdfDialog.open(GeradorAssinadorPdfDialogComponent, { data: { etapa: GeradorAssinadorPdfDialogComponent.ETAPA_ASSINATURA_ANDAMENTO } });

                AssinaturaPdfHelper.setParametrosIniciais();

                AssinaturaPdfHelper.addArquivo(nomeArquivo, environment.pdfApiUrlEvoSdk + "/tmp_files/" + nomeArquivo);

                this._pdfDialog.closeAll();
                this._pdfDialog.open(GeradorAssinadorPdfDialogComponent, { data: { etapa: GeradorAssinadorPdfDialogComponent.ETAPA_ASSINATURA_AGUARDANDO_USUARIO } });

                AssinaturaPdfHelper.assinarDocumentos((r) => {
                    this._pdfDialog.closeAll();
                    this._pdfDialog.open(GeradorAssinadorPdfDialogComponent, { data: { etapa: GeradorAssinadorPdfDialogComponent.ETAPA_ASSINATURA_SUCESSO } });
                    observer.next();
                });
            }, (r) => {
                observer.error("SDK Desktop não está em execução");
                this._pdfDialog.open(GeradorAssinadorPdfDialogComponent, {data: { etapa: GeradorAssinadorPdfDialogComponent.ETAPA_ASSINATURA_SDK_NAO_INICIADO }});
            });
        });
    }

    loginChallenge(): Observable<any> {
        return new Observable((observer) => {
            AssinaturaPdfHelper.isSdkExecutando((r) => {
                sdkDesktop.setParameters({
                    "challenge": Buffer.from("teste").toString('base64'),
                    "challengeWithMac": false
                });

                this._pdfDialog.open(GeradorAssinadorPdfDialogComponent, { data: { etapa: GeradorAssinadorPdfDialogComponent.ETAPA_AGUARDANDO_SENHA_CERTIFICADO_DIGITAL } });
                
                sdkDesktop.generateResponse((ret: any) => {
                    if (ret !== null && JSON.parse(ret).hasOwnProperty("response")) {
                        this._pdfDialog.closeAll();

                        this._pdfDialog.open(GeradorAssinadorPdfDialogComponent, { data: { etapa: GeradorAssinadorPdfDialogComponent.ETAPA_AGUARDANDO_REDIRECIONAMENTO } });

                        observer.next(JSON.parse(ret));
                    } else {
                        observer.error(ret);
                    }
                });
            }, (r) => {
                observer.error("SDK Desktop não está em execução");
                this._pdfDialog.open(GeradorAssinadorPdfDialogComponent, {data: { etapa: GeradorAssinadorPdfDialogComponent.ETAPA_ASSINATURA_SDK_NAO_INICIADO }});
            });
        });
    }

}
