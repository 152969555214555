import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClient} from '@angular/common/http';
import {RouterModule} from '@angular/router';

import {AppRoutingModule} from './app.routing';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {ToastrModule} from 'ngx-toastr';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {initializeKeycloak} from './core/seguranca/keycloack-config';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {BlockUIModule} from 'ng-block-ui';
import {BlockUIInterceptor} from './core/interceptor/blockui.interceptor';
import {HttpErrorInterceptor} from './core/interceptor/http-error.interceptor';
import {BrowserModule} from '@angular/platform-browser';
import {TableService} from './core/components/table/table.service';
import {MatExpansionModule} from '@angular/material/expansion';
import { ParametrizacaoModule } from './modulos/parametrizacao/parametrizacao.module';
import { CredenciamentoDescredenciamentoPssModule } from './modulos/credenciamento-descredenciamento-pss/credenciamento-descredenciamento-pss.module';
import { ManutencaoRepresentanteModule } from './modulos/manutencao-representante/manutencao-representante.module';
import { TranslateService } from '@ngx-translate/core';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        CoreModule.forRoot(),
        RouterModule,
        AppRoutingModule,
        KeycloakAngularModule,
        MatExpansionModule,
        ParametrizacaoModule,
        CredenciamentoDescredenciamentoPssModule,
        ManutencaoRepresentanteModule,
        ToastrModule.forRoot(),
        CollapseModule.forRoot(),
        BlockUIModule.forRoot({
            message: 'Carregando..'
        }),
        
    ],
    declarations: [
        AppComponent,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BlockUIInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        TableService,
        HttpClient
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
