import { Component, Input, OnInit } from '@angular/core';
import { TipoMensagem } from 'app/shared/enums/tipoMensagemEnum';

@Component({
  selector: 'app-mensagem-tipo-padrao',
  templateUrl: './mensagem-tipo-padrao.component.html',
  styleUrls: ['./mensagem-tipo-padrao.component.scss']
})
export class MensagemTipoPadraoComponent implements OnInit {

  
  @Input() tipoMensagem: TipoMensagem;
  @Input() titulo: string;
  @Input() corpoMensagem: string;
  
  tipoMensagemAlerta:TipoMensagem = TipoMensagem.ALERTA;
  tipoMensagemSucesso:TipoMensagem = TipoMensagem.SUCESSO;
  tipoMensagemInformacao:TipoMensagem = TipoMensagem.INFORMACAO;
  tipoMensagemAtencao:TipoMensagem = TipoMensagem.ATENCAO;


  constructor() { }
  

  ngOnInit(): void {
  }

}
