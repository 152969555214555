import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { DownloadHelper } from "../helper/download-helper";
import { DocumentoSolicitacao } from "../model/documento-solicitacao";
import { Observable } from "rxjs";

const API_DOCUMENTACAO = `${environment.cadastroApiUrl}/documento-solicitacao`;

@Injectable({
	providedIn: "root",
})
export class DocumentoSolicitacaoService {
	constructor(private readonly http: HttpClient) {}

	async download(documento: DocumentoSolicitacao): Promise<void> {
		return this.http
			.post<Blob>(API_DOCUMENTACAO + "/download", documento.identificador, DownloadHelper.header())
			.toPromise()
			.then((blob) => {
				DownloadHelper.download(URL.createObjectURL(blob), documento.nome + ".pdf");
			});
	}

	async salvar(documento: DocumentoSolicitacao): Promise<DocumentoSolicitacao> {
		return this.http.post<DocumentoSolicitacao>(API_DOCUMENTACAO, documento).toPromise();
	}

	async excluir(documentoId: number): Promise<void> {
		return this.http.delete<void>(API_DOCUMENTACAO + `/${documentoId}`).toPromise();
	}

	buscarDocumentacaoSolicitacao(protocolo: string): Observable<any> {
		return this.http.get<any>(environment.cadastroApiUrl + `/documento-solicitacao/detalhar/${protocolo}`);
	}

	salvarDocumentoBanco(nomeArquivo: string, tipoDocumentoId: number): Observable<DocumentoSolicitacao>{
		const documento: DocumentoSolicitacao = {
			tipoDocumentoId: tipoDocumentoId,
			nome: nomeArquivo,
			identificador: nomeArquivo.split(".")[0],
		};

		return this.http.post<DocumentoSolicitacao>(`${environment.cadastroApiUrl}/documento-upload/pdf-assinado`, documento);
	}
}
