<div class="mb-2">
	<h5>{{ title }}</h5>

	<div class="mat-elevation-z2">
		<div *ngIf="rows; then dataExists; else dataNotExists"></div>
		<ng-template #dataExists>
			<table mat-table [dataSource]="dataSource.data">
				<!-- Columns -->
				<ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
					<div *ngIf="column !== 'ações' && column !== 'obrigatoriedade'; then defaultColumn; else actionColumn"></div>
					<ng-template #defaultColumn>
						<th mat-header-cell *matHeaderCellDef>{{ column.toUpperCase() }}</th>
						<td mat-cell *matCellDef="let row">
							{{ contentWithMaxCharsLimit(row[column]) }}
						</td>
					</ng-template>

					<ng-template #actionColumn>
						<div *ngIf="column !== 'ações' && column === 'obrigatoriedade'; then obrColumn; else acao"></div>
						<ng-template #acao>
							<th mat-header-cell *matHeaderCellDef>{{ column.toUpperCase() }}</th>
							<td mat-cell *matCellDef="let row">
								<!-- <button *ngIf="!row['ações']?.redirect && !row['ações']?.show" mat-button type="button" class="btn-action" (click)="edit(row)"><i class="material-icons">edit</i></button> -->
								<app-dialog *ngIf="botaoEditar" modalButtonName="" modalButtonIcon="edit" modalButtonType="info" [content]="editRow" (result)="edit($event, row)"></app-dialog>
								<a style="cursor: pointer" *ngIf="botaoEditarSemModal" (click)="editSemModal($event, row)"><i class="material-icons">edit</i></a>
								<app-dialog
									*ngIf="botaoVisualizar"
									modalButtonName=""
									modalButtonIcon="visibility"
									modalButtonType="info"
									[content]="visibilityRow"
									[contentPreview]="[row['Descrição da complementação']]"
									(result)="showPreview(row)"
								></app-dialog>
								<!-- <button *ngIf="!row['ações']?.redirect && !row['ações']?.show" mat-button type="button" class="btn-action" (click)="visibility(row)"><i class="material-icons">visibility</i></button> -->
								<a *ngIf="row['ações']?.redirect && row['ações']?.show" [href]="[row['ações']?.link]" mat-button class="btn-action">
									<i class="material-icons">{{ row["ações"]?.icon }}</i>
								</a>
								<app-dialog *ngIf="botaoDeletar" modalButtonName="" modalButtonIcon="delete" modalButtonType="info" [content]="deleteRow" (result)="delete($event, row)"></app-dialog>
							</td>
						</ng-template>
						<ng-template #obrColumn>
							<th mat-header-cell *matHeaderCellDef>{{ column.toUpperCase() }}</th>
							<td mat-cell *matCellDef="let row">
								<div *ngIf="row.ignoredObrigatoriedade == false; then naoChecado; else checado"></div>
								<ng-template #naoChecado>
									<input type="checkbox" [id]="row[column]" name="" (change)="onMudouObrigatoriedade($event , row)" />
								</ng-template>
								<ng-template #checado>
									<input type="checkbox" [id]="row[column]" name="" checked  (change)="onMudouObrigatoriedade($event, row)"/>
								</ng-template>
							</td>
						</ng-template>
					</ng-template>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

				<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Selecione a página"></mat-paginator>
			</table>
		</ng-template>

		<ng-template #dataNotExists>
			<table mat-table [dataSource]="[]">
				<!-- Columns -->
				<ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
					<th mat-header-cell *matHeaderCellDef>{{ column.toUpperCase() }}</th>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			</table>
		</ng-template>
	</div>
</div>
