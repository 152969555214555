export enum TipoOperacao {
	ENVIO_SOLICITACAO = "Envio da Solicitação",
	ATRIBUIR_SOLICITACAO = "Atribuir Solicitacao",
	INICIO_ANALISE = "Início da Análise",
	RESULTADO_ANALISE = "Resultado da Análise",
	ENVIAR_PARECER = "Enviar Parecer",
	DEVOLVER_PARA_ANALISTA = "Devolver para Analista",
	ENVIAR_DESPACHO = "Enviar Despacho CGAFI",
	DEVOLVER_PARA_CGAFI = "Devolver para CGAFI",
	ENVIAR_DESPACHO_DAFN = "Enviar Despacho DAFN",
	DEVOLVER_PARA_DAFN = "Devolver para DAFN",
	ENVIAR_DESPACHO_DIRETOR = "Enviar Despacho Diretor Presidente",
	COMPLEMENTACAO_SOLICITADA = "Complementação solicitada",
	COMPLEMENTACAO_ENVIADA = "Complementação enviada",
}
