import {KeycloakService} from 'keycloak-angular';
import {environment} from '../../../environments/environment';

/**
 * Factory que parametriza o uso do keycloak
 *
 * @param {KeycloakService} keycloak
 * @returns {() => Promise<boolean>}
 */
export function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.keycloakUrl,
        realm: environment.keycloakRealm,
        clientId: environment.keycloakClientId,
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html',
      },
      bearerExcludedUrls: ['/assets', '/clients/public'],
    });
}
