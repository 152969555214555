import { Component, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AreaAtuacaoService } from 'app/modulos/area-atuacao/area-atuacao.service';
import { Mensagem } from 'app/shared/enums/mensagem';
import { StatusCredenciamento } from 'app/shared/enums/statusCredenciamento';
import { TipoMensagem } from 'app/shared/enums/tipoMensagemEnum';
import { AreaAtuacaoDTO } from 'app/shared/model/area-atuacao-dto';
import { SolicitacaoCredenciamentoDTO } from 'app/shared/model/solicitacao-credenciamento-dto';
import { SolicitacaoGeralEncaminhamentoDTO } from 'app/shared/model/solicitacao-geral-encaminhamento-dto';
import { SolicitacaoSalvarEncaminharDTO } from 'app/shared/model/solicitacao-salvar-encaminhar-dto';
import { NotificationService } from 'app/shared/service/notification.service';
import { SolicitacaoService } from 'app/shared/service/solicitacao-service';
import { DialogData } from '../dialog/dialog-data';
import { SelectItem } from '../dropdown/select-item';

@Component({
  selector: 'app-encerrar-solicitacao-modal',
  templateUrl: './encerrar-solicitacao-modal.component.html',
  styleUrls: ['./encerrar-solicitacao-modal.component.scss']
})
export class EncerrarSolicitacaoModalComponent implements OnInit {
  private readonly mensagem = Mensagem;

  form: UntypedFormGroup;
  areaAtuacao: SelectItem[] = [];
  deferido: SelectItem[] = [{ label:'Deferido', value: StatusCredenciamento.DEFERIDO },{ label:'Indeferido', value: StatusCredenciamento.INDEFERIDO }, { label:'Arquivado', value: StatusCredenciamento.ENCERRADO }];
  arquivadoSelecionado: boolean = false;
  itemSelecionado: StatusCredenciamento;

  tipoMensagemAlerta = TipoMensagem.ATENCAO
  titulo = 'Alerta!';
  corpoMensagem = 'Após uma solicitação/processo ser arquivado, não é possível desfazer este arquivamento.';

	constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
		readonly formBuilder: UntypedFormBuilder,
		readonly areaAtuacaoService: AreaAtuacaoService,
		public modalRef: MatDialogRef<EncerrarSolicitacaoModalComponent>,
    private solicitacaoService: SolicitacaoService,
    private notificationService: NotificationService,
    private router: Router,
	) {
	
	}

	ngOnInit(): void {
    this.form = this.formBuilder.group({
      areaAtuacao: [null, Validators.required],
      deferido: [null, null]
    });
	}

  onOkClick(){
    const solicitacao: SolicitacaoCredenciamentoDTO = {
      protocolo: this.data.protocolo,
    }
    const encaminhamento: SolicitacaoGeralEncaminhamentoDTO = {
      areaAtuacaoAnterior: this.data.areaAtuacaoAnterior,
      parecer: this.data.parecer,
    }
    
    const solicitacaoEncerrada: SolicitacaoSalvarEncaminharDTO = {
      solicitacao: solicitacao,
      encaminhamento: encaminhamento,
      deferido: Object.keys(StatusCredenciamento).find(sc => StatusCredenciamento[sc] == this.itemSelecionado) as StatusCredenciamento,
      listaDocumentos: this.data.documentos,
      encerrado: this.arquivadoSelecionado
    }
    this.solicitacaoService.encerrarSolicitacao(solicitacaoEncerrada).subscribe(r => {
      this.voltarTelaConsulta();
    });
  }

  onNoClick(): void {
    this.modalRef.close(null);
  }


  deferimentoSelecionado(payload: any) {
    if(payload == StatusCredenciamento.ENCERRADO) {
      this.arquivadoSelecionado = true;
    } else {
      this.arquivadoSelecionado = false;
    }
    this.itemSelecionado = payload;
  }

  async voltarTelaConsulta(mensagem?: string) {
    if (mensagem) this.notificationService.sucesso(mensagem);
    this.router.navigate(["/credenciamento/consultar-solicitacao"]);
  }
}