import { Component, Input, OnInit } from "@angular/core";
import { Menu } from "./menu/menu";
import { KeycloakProfile } from "keycloak-js";
import { KeycloakService } from "keycloak-angular";
import { UsuarioService } from "app/shared/service/usuario.service";


export const ROUTES: Menu[] = [
	/*
	{
		routerLink: "/",
		title: "Dashboard",
		icon: "dashboard",
		type: "link",
		collapsed: false,
	},
	{
		routerLink: "/usuario/perfil",
		title: "Profile",
		icon: "unarchive",
		type: "link",
		collapsed: false,
	},
	{
		routerLink: "/usuario/componentes",
		title: "Componentes",
		icon: "settings",
		type: "link",
		collapsed: false,
	},
	{
		title: "Perfis",
		icon: "manage_accounts",
		type: "submenu",
		collapsed: true,
		submenus: [
			{
				routerLink: "/perfis/cadastro",
				title: "Cadastrar",
				type: "link",
				collapsed: false,
				icon: "person_add",
			},
			{
				routerLink: "/perfis/consultar",
				title: "Consultar",
				type: "link",
				collapsed: false,
				icon: "contacts",
			},
		],
	},
	{
		title: "Usuários",
		icon: "account_circle",
		type: "submenu",
		collapsed: true,
		submenus: [
			{
				routerLink: "/usuarios/consultar",
				title: "Consultar",
				type: "link",
				collapsed: false,
				icon: "contacts",
			},
		],
	},
	{
		title: "Credenciamento",
		icon: "lock",
		type: "submenu",
		collapsed: true,
		submenus: [
			{
				routerLink: "/credenciamento/solicitacao",
				title: "Solicitação",
				type: "link",
				collapsed: false,
				icon: "unarchive",
			},
			{
				routerLink: "/credenciamento/consultar-solicitacao",
				title: "Consultar Solicitação",
				type: "link",
				collapsed: false,
				icon: "unarchive",
			},
			{
				routerLink: "/credenciamento/parametrizacao",
				title: "Parametrização",
				type: "link",
				collapsed: false,
				icon: "unarchive",
			},
			{
				routerLink: "/credenciamento/telas",
				title: "Listagem de Telas",
				type: "link",
				collapsed: false,
				icon: "unarchive",
			},
			*/
			/*
            {
                routerLink: '/credenciamento/aditivo',
                title: 'Aditivo',
                type: 'link',
                collapsed: false,
                icon: 'person',
            },
            {
                routerLink: '/credenciamento/cadastro',
                title: 'Cadastro',
                type: 'link',
                collapsed: false,
                icon: 'build',
            },
            {
                routerLink: '/credenciamento/homologacao',
                title: 'Homologação',
                type: 'link',
                collapsed: false,
                icon: 'unarchive',
            },
             */
			/*
		],
	},
	*/
	/*
    {
        title: 'Auditoria',
        icon: 'lock',
        type: 'submenu',
        collapsed: true,
        submenus: [
            {
                routerLink: '/auditoria/compliance',
                title: 'Compliance',
                type: 'link',
                collapsed: false,
                icon: 'person',
            },
            {
                routerLink: '/auditoria/inloco',
                title: 'In Loco',
                type: 'link',
                collapsed: false,
                icon: 'unarchive',
            },
            {
                routerLink: '/auditoria/validacaoverificacao',
                title: 'Verificação e Validação',
                type: 'link',
                collapsed: false,
                icon: 'unarchive',
            }
        ]
    },

     */
];
 
/**
 * Fragmento do template, menu esquerdo com as rotas e montagem.
 */
@Component({
	selector: "app-sidebar",
	templateUrl: "./sidebar.component.html",
	styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
	menuItems: Menu[];
	profile: KeycloakProfile | null;

	@Input()
	toggle = false;

	constructor(protected readonly keycloak: KeycloakService, private usuarioService: UsuarioService) {}

	ngOnInit() {
		this.menuItems = ROUTES.filter(menuItem => menuItem);
		this.keycloak.loadUserProfile().then(r => {
			this.profile = r;
			this.usuarioService.getAcessosSidebar().subscribe(resultado => (this.menuItems = resultado.sidebar));
		});
		
	}
}
