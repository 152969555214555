import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AreaAtuacaoService } from 'app/modulos/area-atuacao/area-atuacao.service';
import { AreaAtuacaoDTO } from 'app/shared/model/area-atuacao-dto';
import { SolicitacaoCredenciamentoDTO } from 'app/shared/model/solicitacao-credenciamento-dto';
import { SolicitacaoGeralEncaminhamentoDTO } from 'app/shared/model/solicitacao-geral-encaminhamento-dto';
import { SolicitacaoSalvarEncaminharDTO } from 'app/shared/model/solicitacao-salvar-encaminhar-dto';
import { NotificationService } from 'app/shared/service/notification.service';
import { DialogData } from '../dialog/dialog-data';
import { SelectItem } from '../dropdown/select-item';
import { SolicitacaoService } from 'app/shared/service/solicitacao-service';

@Component({
  selector: 'app-analise-area-atuacao-modal',
  templateUrl: './analise-area-atuacao-modal.component.html',
  styleUrls: ['./analise-area-atuacao-modal.component.scss']
})
export class AnaliseAreaAtuacaoModalComponent implements OnInit {
  
  cancelouSolicitacao: boolean = false;

  form: UntypedFormGroup;
  areaAtuacao: SelectItem[] = [];
  areaAtuacaoEscolhida: AreaAtuacaoDTO;

	constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
		readonly formBuilder: UntypedFormBuilder,
		readonly areaAtuacaoService: AreaAtuacaoService,
		public modalRef: MatDialogRef<AnaliseAreaAtuacaoModalComponent>,
    private notificationService: NotificationService,
    private solicitacaoService: SolicitacaoService,
    private router: Router,
	) {
	
	}

	ngOnInit(): void {
    this.buscarAreaAtuacao();
    
    this.form = this.formBuilder.group({
      areaAtuacao: [null, Validators.required],
    });
	}

  onOkClick(){
    const encaminhamento: SolicitacaoGeralEncaminhamentoDTO = {
			areaAtuacaoAnterior: this.data.areaAtuacaoAnterior,
			areaAtuacaoEncaminhada: this.areaAtuacaoEscolhida,
			parecer: this.data.parecer,
		}

		const solicitacao: SolicitacaoCredenciamentoDTO = {
			protocolo: this.data.protocolo,
		}

    const solicitacaoEncaminhada: SolicitacaoSalvarEncaminharDTO = {
      solicitacao: solicitacao,
			encaminhamento: encaminhamento,
			listaDocumentos: this.data.documentos,
			encerrado: false
    }
    
    this.solicitacaoService.encaminharSolicitacao(solicitacaoEncaminhada).subscribe(r => {
      this.voltarTelaConsulta();
    });
  }

  onNoClick(): void {
    this.modalRef.close(null);
  }

  buscarAreaAtuacao(){
    this.areaAtuacaoService.buscarAreasAtuacao().subscribe(r=>{
      r.forEach(areaAtuacao=>{
        this.areaAtuacao.push(
          {label: areaAtuacao.nome, value: areaAtuacao});
      });
    })
  }

  areaAtuacaoSelecionado(event) {
    this.areaAtuacaoEscolhida = event;
  }

  async voltarTelaConsulta(mensagem?: string) {
		if (mensagem) this.notificationService.sucesso(mensagem);
		this.router.navigate(["/credenciamento/consultar-solicitacao"]);
	}
}