import { AreaAtuacaoDTO } from 'app/shared/model/area-atuacao-dto';
import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { AreaAtuacaoService } from 'app/modulos/area-atuacao/area-atuacao.service';
import { Funcionalidade } from 'app/shared/enums/funcionalidade';
import { Mensagem } from 'app/shared/enums/mensagem';
import { AssinaturaPdfHelper } from 'app/shared/helper/assinatura-pdf-helper';
import { ComplementacaoDocumentos } from 'app/shared/model/complementacao-documentos';
import { Documento } from 'app/shared/model/Documento';
import { DocumentoUploadDTO } from 'app/shared/model/documento-upload-dto';
import { PayloadTabela } from 'app/shared/model/tabela-model';
import { TipoDocumento } from 'app/shared/model/tipo-documento';
import { DocumentoSolicitacaoService } from 'app/shared/service/documento-solicitacao.service';
import { DocumentoUploadService } from 'app/shared/service/documento-upload.service';
import { EventBusService } from 'app/shared/service/event-bus.service';
import { NotificationService } from 'app/shared/service/notification.service';
import { PdfService } from 'app/shared/service/pdf.service';
import { TipoDocumentoService } from 'app/shared/service/tipo-documento.service';
import { Page } from 'app/shared/util/pagination';
import { environment } from 'environments/environment';
import { AnaliseAreaAtuacaoModalComponent } from '../analise-area-atuacao-modal/analise-area-atuacao-modal.component';
import { DialogComponent } from '../dialog/dialog.component';
import { SelectItem } from '../dropdown/select-item';
import { DsInputUploadComponent } from '../ds-input-upload/ds-input-upload.component';
import { SimpleDialogComponent } from '../simple-dialog/simple-dialog.component';
import { TextareaDialogComponent } from '../textarea-dialog/textarea-dialog.component';
import { SolicitacaoService } from 'app/shared/service/solicitacao-service';
import { SolicitacaoSalvarEncaminharDTO } from 'app/shared/model/solicitacao-salvar-encaminhar-dto';
import { SolicitacaoGeralEncaminhamentoDTO } from 'app/shared/model/solicitacao-geral-encaminhamento-dto';
import { SolicitacaoCredenciamentoDTO } from 'app/shared/model/solicitacao-credenciamento-dto';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Router } from '@angular/router';
import { StatusCredenciamento } from 'app/shared/enums/statusCredenciamento';
import { AnaliseTecnicaResultadoService } from 'app/shared/service/analise-tecnica-resultado.service';
import { Utils } from 'app/shared/util/utils';
import { AnaliseTecnica } from 'app/shared/model/analise-tecnica';
import { TipoMensagem } from 'app/shared/enums/tipoMensagemEnum';
import { EncerrarSolicitacaoModalComponent } from '../encerrar-solicitacao-modal/encerrar-solicitacao-modal.component';
import { SolicitarComplementosModalComponent } from '../solicitar-complementos-modal/solicitar-complementos-modal.component';
import { Observable, PartialObserver } from 'rxjs';
@Component({
	selector: 'app-analise-area-atuacao',
	templateUrl: './analise-area-atuacao.component.html',
	styleUrls: ['./analise-area-atuacao.component.scss']
})
export class AnaliseAreaAtuacaoComponent implements OnInit {

	private readonly URL_API = `${environment.cadastroApiUrl}/manutencao/documento`;
	private readonly URL_API_DELETE = `${environment.cadastroApiUrl}/documento-solicitacao/documento-upload-solicitacao`;
	private readonly mensagem = Mensagem;

	@ViewChild("dialogVisualizar") dialogVisualizar: DialogComponent;
	@ViewChildren(DsInputUploadComponent) campoDeArquivo: QueryList<DsInputUploadComponent>;
	@ViewChild("dialogEncerrarSolicitacao") dialogEncerrarSolicitacao: DialogComponent;

	//- Informações que componente recebe
	@Input() protocolo: string;
	@Input() form: UntypedFormGroup;
	@Input() complementacaoDataRows: any[] = [];
	@Input() complementacaoHeader: any[] = [];
	@Input() permissoes: any;
	@Input() temComplementacao: boolean = false;
	@Input() idAreaAtuacao: number;

	//- Eventos que o componente emite
	@Output() isFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() deleteEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() documentos = new EventEmitter();

	documentacaoCompleta: boolean = false;
	campoComplementacoes: boolean = false;
	botaoFinalizar: boolean = false;
	idAnaliseTecnica: number;

	/* PAGINAÇÃO */
	complementacaoRows: Array<any> = [];
	page: Page<any> = new Page([], true, false, 0, 0, 0);
	pageEvent: PageEvent;
	totalPaginas: number[] = [];
	totalItens: number = 0;
	itensPorPagina: number = 5;
	paginaAtual: number;
	paginaSelecionada: number = 1;
	primeiraPagina: boolean = false;
	ultimaPagina: boolean = false;
	funcionalidadeDocumentos: Funcionalidade = Funcionalidade.DOCUMENTOS_GERAIS_RESULTADO_ANALISE;
	//- Documentos
	tiposDocumentos: SelectItem[];
	deferido: SelectItem[] = [{ label: 'Deferido', value: StatusCredenciamento.DEFERIDO }, { label: 'Indeferido', value: StatusCredenciamento.INDEFERIDO }, { label: 'Arquivado', value: StatusCredenciamento.ENCERRADO }];
	tiposDocumentosRaw: TipoDocumento[] = [];
	tipoDocumentoEscolhido: any;
	deferimentoEscolhido: any;
	nomeDocumento: string;
	idTipoDocumento: number;
	keysDocumento: Array<string> = [];
	nomeCampoInformacoesAdicionais: string = "informações_adicionais";
	documento: any = {};
	documentosUpload: DocumentoUploadDTO[] = [];
	documentosEnviados: Documento[] = [];
	documentoEnviado: Documento
	idDocumentoSalvo: number;
	identificadorDocumentoSalvo: string;
	nomeDocumentoSalvo: string;
	urlApi: string = this.URL_API;
	urlApiDelete: string = this.URL_API_DELETE;
	documentoAdicionadoHeader: any[] = [
		{ title: "Tipo do Documento", field: "tipoDocumento",   render: (index: number, row: DocumentoUploadDTO, field: string) => { return row.tipoDocumento.nome } },
		{ title: "Descrição do anexo", field: "descricaoAnexo", render: (index: number, row: DocumentoUploadDTO, field: string) => { return row.descricao } },
		{ title: "Ações", field: "acoes", actions: ["download", "delete"] },
	];
	nomeArquivos = {
		parecerTecnico: "",
		despachoCGAFI: "",
		despachoDAFN: "",
		despachoDiretor: "",
		gerarOficio: "",
	};
	encerradoClicado: boolean = false;
	emcaminhadoClicado: boolean = false;
	solicitarComplementacaoClicado: boolean = false;
	areaAtuacao: AreaAtuacaoDTO;
	visualizacaoTituloAnalise: boolean = false;
	assinaturaPdfHelper: AssinaturaPdfHelper = null;
	conteudoEncerrarModal: any;
	arquivadoSelecionado: boolean = false;
	parecerPreenchido: boolean = false;
	
	tipoMensagemAlerta = TipoMensagem.ATENCAO
	titulo = 'Alerta!';
	corpoMensagem = 'Após uma solicitação/processo ser arquivado, não é possível desfazer este arquivamento.';

	necessarioParecer: boolean = false;
	parecer = null;

	constructor(
		readonly formBuilder: UntypedFormBuilder,
		private dialog: MatDialog,
		private notificationService: NotificationService,
		private tipoDocumentoService: TipoDocumentoService,
		private readonly documentoUploadService: DocumentoUploadService,
		private eventBusService: EventBusService,
		private router: Router,
		readonly areaAtuacaoService: AreaAtuacaoService,
		private pdfService: PdfService,
		public pdfDialog: MatDialog,
		private readonly documentoSolicitacaoService: DocumentoSolicitacaoService,
		private solicitacaoService: SolicitacaoService,
		private readonly analiseTecnicaResultadoService: AnaliseTecnicaResultadoService,

	) {
		this.assinaturaPdfHelper = new AssinaturaPdfHelper(pdfDialog, pdfService);
	}

	ngOnInit(): void {

		this.form = this.formBuilder.group({
			nomeDocumento: [null, null],
			parecer: [null, Validators.required],
			tipoDocumento: [null],
			descricaoAnexo: [null],
			deferido: [null],
			campos: [null, null],
			encerrarSolicitacao: [null, null]
		});
		this.buscarAreaAtuacao(this.idAreaAtuacao);

		this.buscarTiposDocumentos();

		//- Cria paginas para a complementação
		this.atualizarPaginas();

		this.conteudoEncerrarModal = {
			title: "Selecione a conclusão da solicitação",
			cancelButton: { show: true, label: "Cancelar" },
			okButton: { show: true, label: "Confirmar" },
			dialogComponent: {
				form: this.form,
				controlName: "encerrarSolicitacao",
				type: "dropdown",
				itemsDropdown: this.deferido,
			},
			config: { disableClose: true, minWidth: "600px" },
		};
		// this.form.controls.parecer.setValue("Digite um parecer...")
		
		
	}

	ngOnChanges(): void {
		this.campoComplementacoes = true;
		this.eventBusService.on('complementacao', (complementacao: any) => {
			this.campoComplementacoes = true;
			this.complementacaoDataRows.push(complementacao);
			this.complementacaoDataRows = JSON.parse(JSON.stringify(this.complementacaoDataRows));

			if (this.complementacaoRows){
				this.atualizarPaginas();
			} 
			if (this.complementacaoDataRows.length > 0) {
				this.campoComplementacoes = true;
			}
		
		})
		 
	}
 
	visualizarComplementacao(payload: any) {
		const { tipoDocumentoObj, documento, descricao } = payload.entity;

		this.dialog.open(SimpleDialogComponent, {
			data: {
				title: tipoDocumentoObj ? tipoDocumentoObj.nome : documento,
				content: descricao,
				okButton: {
					show: true,
					label: "Ok",
				},
			},
		});
	}

	buscarAreaAtuacao(idAreaAtuacao: number) {
		this.areaAtuacaoService.buscarAreaAtuacaoEditadoPorId(idAreaAtuacao).subscribe(areaAtuacaoBuscada => {
			this.areaAtuacao = areaAtuacaoBuscada;
			this.visualizacaoTituloAnalise = (areaAtuacaoBuscada.nome.substring(0, 7).toLowerCase() == 'análise' || areaAtuacaoBuscada.nome.substring(0, 7).toLowerCase() == 'analise');
		});
	}

	deletarComplementacao(payload: any): void {
		if (payload.confirm === true) {
			this.deleteEvent.emit(payload);
			this.atualizarPaginas();
		}
	}

	editarComplementacao(payload: any): void {
		const index = payload.index;
		const { tipoDocumentoObj, documento, descricao } = payload.entity;
		this.dialog
			.open(TextareaDialogComponent, {
				data: {
					title: tipoDocumentoObj ? tipoDocumentoObj.nome : documento,
					content: descricao,
					okButton: { show: true, label: "Ok" },
					width: "250px",
				},
			})
			.afterClosed()
			.subscribe((novaDescricao: string) => {
				this.complementacaoDataRows[index].descricao = novaDescricao;
				// this.complementacaoDataRows = this.complementacaoRows
				this.notificationService.sucesso(this.mensagem.SUCESSO.COMPLEMENTACAO_EDITADA);
				this.atualizarPaginas();
			});
	}

	public atualizarPaginas(): void {
		// this.complementacaoRows = this.complementacaoDataRows;
		this.complementacaoRows = this.criaPaginas();
		this.complementacaoRows = JSON.parse(JSON.stringify(this.complementacaoRows));
		this.setTotalItens(this.complementacaoDataRows.length);
		this.calculaTotalDePaginas();
		this.setPaginaAtual();
		this.setPrimeiraOuUltimaPagina();
	}

	private criaPaginas(): Array<void> {
		//- Levando em consideração que paginaSelecionada incia em 1
		const trimInicialParaPagina = (this.paginaSelecionada - 1) * this.itensPorPagina;
		const trimFinalParaPagina = trimInicialParaPagina + this.itensPorPagina;

		return this.complementacaoRows.slice(trimInicialParaPagina, trimFinalParaPagina);
	}

	private calculaTotalDePaginas(): void {
		this.totalPaginas = [];
		const restoDoTotalDePaginas: number = Math.floor((this.totalItens / this.itensPorPagina) % 2);
		let totalDePaginas: number = Math.floor((this.totalItens / this.itensPorPagina) + restoDoTotalDePaginas);
		totalDePaginas = totalDePaginas >= 1 && this.totalItens > this.itensPorPagina ? totalDePaginas : 1;

		for (let i = 1; i <= totalDePaginas; i++) this.totalPaginas.push(i);
		this.totalPaginas = JSON.parse(JSON.stringify(this.totalPaginas));
	}

	private setPaginaAtual(): void {
		this.paginaAtual = this.paginaSelecionada;
	}

	private setPrimeiraOuUltimaPagina(): void {
		this.primeiraPagina = this.paginaAtual === 1;
		this.ultimaPagina = this.paginaAtual === this.totalPaginas.length;
	}

	private setTotalItens(totalItens: number): void {
		this.totalItens = totalItens;
	}

	gerarOficio() {
		this.nomeArquivos.gerarOficio = "teste-gerar-oficio.pdf";
		this.assinaturaPdfHelper.gerarPdfDoTexto(this.form.get("conteudo").value).subscribe(nomeArquivo => {
			this.documentoSolicitacaoService.salvarDocumentoBanco(nomeArquivo, 82).subscribe(() => {
				this.assinaturaPdfHelper.assinarDocumento(nomeArquivo).subscribe(() => { });
			});
		});
	}

	buscarTiposDocumentos() {
		this.tiposDocumentos = []
		this.tiposDocumentosRaw = []
		this.tipoDocumentoService.buscarPorFuncionalidade(this.funcionalidadeDocumentos).subscribe((documentos: Array<TipoDocumento>) => {
			if (documentos.length > 0) {
				this.tiposDocumentosRaw = documentos;
				documentos.forEach((documento: TipoDocumento) => {
					const nomeDocumento = documento.nome.toLowerCase();
					const key = nomeDocumento.indexOf("adicionais") !== -1 || nomeDocumento.indexOf('adicional') !== -1 ? this.nomeCampoInformacoesAdicionais : nomeDocumento.replace(/ /g, "_");

					this.documento[key] = {
						id: documento.id,
						documento,
						documentosSalvos: [],
						grupo: documento.grupo,
						template: documento.template
					};
					this.keysDocumento.push(key);

					this.tiposDocumentos.push(
						{ label: this.documento[key].documento.nome, value: this.documento[key].documento.id }
					);
				});
			}
		});
	}

	tipoDocumentoSelecionado(payload: any) {
		this.tipoDocumentoEscolhido = payload
	}
	deferimentoSelecionado(payload: any) {
		this.deferimentoEscolhido = payload
	}

	adicionaDocumentos() {
		let nomeDocumento: string;
		let idTipoDocumento: number;
		this.tiposDocumentos.forEach(tipoDocumento => {
			if (tipoDocumento.value === this.tipoDocumentoEscolhido) {
				nomeDocumento = tipoDocumento.label;
				idTipoDocumento = tipoDocumento.value;
			}
		});

		if (this.form.controls.descricaoAnexo.value !== null && this.nomeDocumentoSalvo !== null && typeof this.nomeDocumentoSalvo !== "undefined" && typeof this.form.controls.descricaoAnexo.value !== "undefined") {
			this.documentosUpload.push({
				id: this.idDocumentoSalvo,
				identificador: this.identificadorDocumentoSalvo,
				nome: this.nomeDocumentoSalvo,
				descricao: this.form.controls.descricaoAnexo.value,
				tipoDocumentoId: idTipoDocumento,
				tipoDocumento: this.tiposDocumentosRaw.filter(td => td.id == idTipoDocumento)[0]
			} as DocumentoUploadDTO);

			this.documentos.emit(this.documentosUpload);
			this.documentosUpload = JSON.parse(JSON.stringify(this.documentosUpload));
			this.limparCamposDocumentos();
			this.campoDeArquivo.forEach(campoArquivo => {
				campoArquivo.limpaCamposDeAquivos();
			});
		} else {
			this.notificationService.erro(this.mensagem.ERRO.CAMPO_OBRIGATORIO("Nome e/ou Descrição"));
		}
	}

	public documentoSalvo(documento: any, idTipoDocumento: number): void {
		this.idDocumentoSalvo = documento.id;
		this.identificadorDocumentoSalvo = documento.identificador;
		this.nomeDocumentoSalvo = documento.nome;
		this.keysDocumento.forEach((key: string) => {
			if (idTipoDocumento === this.documento[key].id) this.documento[key].documentosSalvos.push(documento);
		});
	}

	limparCamposDocumentos() {
		this.form.controls.tipoDocumento.setValue(null);
		this.form.controls.descricaoAnexo.setValue(null);
	}

	public deletarDocumento(payload: PayloadTabela): void {
		if (payload.confirm) {
			const { index, entity } = payload;

			this.documentosUpload.splice(index, 1);
			this.documentosUpload = JSON.parse(JSON.stringify(this.documentosUpload));
		}
	}

	downloadArquivo(event: any): void {
		this.documentoUploadService.download(event.entity).subscribe(download => {
			this.documentoUploadService.handleDownload(download, `${event.entity.name || event.entity.nome}`);
		});
	}

	public checkEncerrado(event): void {
		if(this.form.controls.parecer.value) {
			let onCancel = (result) => {
				this.form.controls.campos.reset();
			};
			if (event == 1) {
				this.encerradoClicado = true;
				this.emcaminhadoClicado = false;
				this.documentacaoCompleta = true;
				this.dialog.open(EncerrarSolicitacaoModalComponent, {
					data: {
						cancelButton: { show: true, label: "Cancelar" },
						okButton: { show: true, label: "Enviar" },
						protocolo: this.protocolo,
						parecer: this.form.controls.parecer.value,
						documentos: this.documentosUpload,
						areaAtuacaoAnterior: this.areaAtuacao,
					},
					maxWidth: 800,
					maxHeight: 800
				}).afterClosed().subscribe(onCancel);
	
			} else if (event == 2) {
				this.encerradoClicado = false;
				this.emcaminhadoClicado = false;
				// this.documentacaoCompleta = false;
				this.solicitarComplementacaoClicado = true;
				this.dialog.open(SolicitarComplementosModalComponent, {
					data: {
						cancelButton: { show: true, label: "Cancelar" },
						okButton: { show: true, label: "Enviar" },
						protocolo: this.protocolo,
						parecer: this.form.controls.parecer.value,
						complementacaoRows: this.complementacaoDataRows,
						areaAtuacaoAnterior: this.areaAtuacao,
						documentos: this.documentosUpload,
					}, 
					maxWidth: 500,
					maxHeight: 500
				}).afterClosed().subscribe(onCancel);
	
			} else if (event == 3) {
				this.encerradoClicado = false;
				this.emcaminhadoClicado = true;
				this.documentacaoCompleta = true;
				this.dialog.open(AnaliseAreaAtuacaoModalComponent, {
					data: {
						cancelButton: { show: true, label: "Cancelar" },
						okButton: { show: true, label: "Enviar" },
						protocolo: this.protocolo,
						parecer: this.form.controls.parecer.value,
						complementacaoRows: this.complementacaoDataRows,
						areaAtuacaoAnterior: this.areaAtuacao,
						documentos: this.documentosUpload,
					},
					maxWidth: 500,
					maxHeight: 500
				}).afterClosed().subscribe(onCancel);			
			}
		} else {
			this.notificationService.erro(this.mensagem.ERRO.CAMPO_PARECER);
			this.form.controls.campos.reset();
		}
	}

	tratadados(event): SolicitacaoSalvarEncaminharDTO {
		const dados = this.form.controls;

		const encaminhamento: SolicitacaoGeralEncaminhamentoDTO = {
			areaAtuacaoAnterior: this.areaAtuacao,
			areaAtuacaoEncaminhada: event,
			parecer: dados.parecer.value,
		}

		const solicitacao: SolicitacaoCredenciamentoDTO = {
			protocolo: this.protocolo,
		}

		return {
			solicitacao: solicitacao,
			encaminhamento: encaminhamento,
			complementacaoDocumentos: this.complementacaoRows,
			listaDocumentos: this.documentosUpload,
			deferido: Object.keys(StatusCredenciamento).find(sc => StatusCredenciamento[sc] == this.deferimentoEscolhido) as StatusCredenciamento,
			encerrado: this.encerradoClicado
		} as SolicitacaoSalvarEncaminharDTO;
	}

	finalizarAnalise() {
		
		if (this.documentacaoCompleta == true) {
			this.dialog.open(AnaliseAreaAtuacaoModalComponent, {
				data: {
					cancelButton: { show: true, label: "Cancelar" },
					okButton: { show: true, label: "Enviar" },
				}
			})
				.afterClosed()
				.subscribe((r) => {
					if (r !== null) {
						this.solicitacaoService.encaminharSolicitacao(this.tratadados(r)).subscribe(r => {
							this.voltarTelaConsulta();
						});
					}
				});
		} else if (this.documentacaoCompleta == false) {
			const protocolo = Utils.formatarProtocolo(this.protocolo);
			const analiseEnvio: AnaliseTecnica = {
				protocolo: protocolo,
				completo: "PENDENTE",
				descricao: ''
			};

			if (this.complementacaoDataRows.length > 0 || this.form.controls.parecer.valid) {

				this.complementacaoDataRows.push({
					protocolo: this.protocolo,
				});

				this.analiseTecnicaResultadoService.salvarAnaliseTecnica(analiseEnvio).subscribe(
					({ id }) => {
						this.idAnaliseTecnica = id;

						this.analiseTecnicaResultadoService.salvarComplementacaoDocumento(this.complementacaoDataRows).subscribe(result => {
							this.notificationService.sucesso(this.mensagem.SUCESSO.COMPLEMENTACAO_SOLICITADA);
							this.voltarTelaConsulta();
						});
					},
				);
			}

		}

	}

	async voltarTelaConsulta(mensagem?: string) {
		if (mensagem) this.notificationService.sucesso(mensagem);
		this.router.navigate(["/credenciamento/consultar-solicitacao"]);
	}

	encerrarSolicitacao(event) {
		if (event == StatusCredenciamento.ENCERRADO) {
			this.arquivadoSelecionado = true;
		} else {
			this.arquivadoSelecionado = false;
		}
	}

}