import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { AnaliseTecnica } from "../model/analise-tecnica";
import { Injectable } from "@angular/core";
import { ComplementacaoDocumentos } from "../model/complementacao-documentos";
import { ParecerTecnico } from "../model/parecer-tecnico";
import { DespachoCgafi } from "../model/despacho-cgafi";
import { DespachoDafn } from "../model/despacho-dafn";
import { DespachoDiretorPresidente } from "../model/despacho-diretor-presidente";
import { NotificacaoDeferimentoSolicitacao } from "../model/notificacao-deferimento";
import { PublicacaoDou } from "../model/publicacao-dou";
import { Utils } from "../util/utils";
import { DetalharCredenciamentoDTO } from "../model/detalhar-credenciamento";
import { NomeContratualDTO } from "../model/nome-contratual-dto";
import { PlanoAuditoriaDTO } from '../model/plano-auditoria-dto';
import { DadosBasicosAC2DTO } from '../model/credenciamento-ac-2/dados-basicos/dados-basicos-ac-2-dto';
import { SolicitarACTDTO } from "../model/credenciamento-act/solicitar-act-dto";
import { AutoridadeCertificadoraDTO } from "../model/autoridade-certificadora-dto";
import { AlteracaoRepresentantesDTO } from "../model/manutencao-ar/alteracao-representantes/alteracao-representantes-dto";
import { EnderecoComContatosDTO } from "../model/endereco-com-contatos-dto";

@Injectable({ providedIn: "root" })
export class AnaliseTecnicaResultadoService {
	constructor(private readonly http: HttpClient) { }

	salvarAnaliseTecnica(analise: AnaliseTecnica): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/resultado-cadastrar`, analise);
	}

	salvarComplementacaoDocumento(complementacao: ComplementacaoDocumentos[]): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/complemento-documentacao`, complementacao);
	}

	buscarComplementacaoDocumento(protocolo: string): Observable<any> {
		protocolo = Utils.formatarProtocolo(protocolo);
		return this.http.get(`${environment.cadastroApiUrl}/analise-solicitacao/busca-complemento-documentacao/${protocolo}`);
	}
	salvarComplementacaoDocumentoSolicitante(complementacao: ComplementacaoDocumentos[]): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/complemento-documentacao-solicitante`, complementacao);
	}
	//---------------------------------------------------------- AR -----------------------------------------------------------------------------

	salvarComplementacaoFormularioSolicitante(formulario: DetalharCredenciamentoDTO): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/complemento-formulario-solicitante`, formulario);
	}

	//---------------------------------------------------------- ACT -----------------------------------------------------------------------------
	salvarComplementacaoFormularioACT(formulario: DetalharCredenciamentoDTO): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/complemento-formulario-act`, formulario);
	}
	salvarComplementacaoFormularioPSC(formulario: DetalharCredenciamentoDTO): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/complemento-formulario-psc`, formulario);
	}
	//---------------------------------------------------------- PSBIO -----------------------------------------------------------------------------

	salvarComplementacaoFormularioACTSolicitante(formulario: SolicitarACTDTO): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/complemento-formulario-act-solicitante`, formulario);
	}
	salvarComplementacaoFormularioPSBioSolicitante(formulario: DetalharCredenciamentoDTO): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/complemento-formulario-psbio-solicitante`, formulario);
	}

//---------------------------------------------------------- AC1 -----------------------------------------------------------------------------

	salvarComplementacaoFormularioSolicitanteAc1(formulario: DetalharCredenciamentoDTO): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/complemento-formulario-solicitante-ac1`, formulario);
	}

	salvarComplementacaoFormularioAlteracaoNomeAC1Solicitante(formulario: NomeContratualDTO): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/complemento-formulario-alteracao-nome-ac1-solicitante`, formulario);
	}

	salvarComplementacaoFormularioNovoEnderecoAC1Solicitante(formulario: AutoridadeCertificadoraDTO): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/complemento-formulario-novo-endereco-ac1-solicitante`, formulario);
	}

	salvarComplementacaoFormularioAlteracaoRepresentanteAC1Solicitante(formulario: AlteracaoRepresentantesDTO): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/complemento-formulario-alteracao-representante-ac1-solicitante`, formulario);
	}

	salvarComplementacaoFormularioPlanoAuditoriaAC1Solicitante(formulario: PlanoAuditoriaDTO): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/complemento-formulario-plano-auditoria-ac1-solicitante`, formulario);
	}

	//---------------------------------------------------------- AC2 -----------------------------------------------------------------------------

	salvarComplementacaoFormularioCredenciamentoAC2Solicitante(formulario: DadosBasicosAC2DTO): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/complemento-formulario-credenciamento-ac2-solicitante`, formulario);
	}

	salvarComplementacaoFormularioNovoEnderecoAC2Solicitante(formulario: AutoridadeCertificadoraDTO): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/complemento-formulario-novo-endereco-ac2-solicitante`, formulario);
	}


	salvarParecertecnico(parecer: ParecerTecnico): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/parecer-tecnico`, parecer);
	}

	salvarDespachoCgafi(despacho: DespachoCgafi): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/despacho-cgafi`, despacho);
	}

	salvarHistoricoParaDevolucaoDeCigafParaAnalista(protocolo: string): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/devolver-para-anlista`, protocolo);
	}

	salvarHistoricoParaDevolucaoDeDafnParaCgafi(protocolo: string): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/devolver-para-cgafi`, protocolo);
	}

	salvarHistoricoParaDevolucaoDeDiretorParaDafn(protocolo: string): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/devolver-para-dafn`, protocolo);
	}

	salvarDespachoDafn(despachoDafn: DespachoDafn): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/despacho-dafn`, despachoDafn);
	}

	salvarDespachoDafnAgente(despachoDafn: DespachoDafn): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/agente-despacho-dafn`, despachoDafn);
	}

	salvarDespachoDafnRepresentante(despachoDafn: DespachoDafn): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/representante-despacho-dafn`, despachoDafn);
	}

	salvarDespachoDafnSolicitacao(despachoDafn: DespachoDafn): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/solicitacao-despacho-dafn`, despachoDafn);
	}

	salvarNotificacaoDeferimento(notificacao: NotificacaoDeferimentoSolicitacao): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/salvar-notificacao-deferimento`, notificacao);
	}

	salvarDespachoDiretorPresidente(despachoDiretorPresidente: DespachoDiretorPresidente): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/despacho-diretor-presidente`, despachoDiretorPresidente);
	}

	salvarPublicacaoDou(publicacaoDou: PublicacaoDou): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/publicacao-dou`, publicacaoDou);
	}
	salvarPublicacaoDouDescredenciamento(publicacaoDou: PublicacaoDou): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/publicacao-dou-descredenciamento`, publicacaoDou);
	}

	devolverDespachoAnalistaTecnico(despacho: ParecerTecnico): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/devolver-despacho-para-analista-tecnico`, despacho);
	}

	devolverDespachoAnalistaCGAFI(despacho: DespachoCgafi): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/devolver-despacho-para-cgafi`, despacho);
	}

	devolverDespachoAnalistaDAFN(despacho: DespachoDafn): Observable<any> {
		return this.http.post(`${environment.cadastroApiUrl}/analise-solicitacao/devolver-despacho-para-dafn`, despacho);
	}

	// -----------------------------------------------Manutenções de Endereço-------------------------------------------------
	salvarComplementacaoManutencaoEnderecos(enderecos: EnderecoComContatosDTO[]): Observable<EnderecoComContatosDTO> {
		return this.http.post<EnderecoComContatosDTO>(`${environment.cadastroApiUrl}/analise-solicitacao/complemento-manutencao-enderecos`, enderecos);
	}

	salvarComplementacaoManutencaoNomeContratual(nomeContratual: NomeContratualDTO): Observable<NomeContratualDTO> {
		return this.http.post<NomeContratualDTO>(`${environment.cadastroApiUrl}/analise-solicitacao/complemento-manutencao-nome-contratual`, nomeContratual);
	}
}
