import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { SelectItem } from "app/core/components/dropdown/select-item";
import { TipoDocumentoDTO } from "app/shared/model/tipo-documento-dto";
import { FuncionalidadeDTO } from "app/shared/model/funcionalidade-dto";
import { GrupoDTO } from "app/shared/model/grupo-dto";
import { ModeloDocumentoDTO } from "app/shared/model/modelo-documento-dto";

const API_DOCUMENTACAO = `${environment.cadastroApiUrl}/criar-tipo-documento`;

@Injectable({
	providedIn: "root",
})
export class CriarTipoDocumentoService {
	constructor(private readonly http: HttpClient) {}

	cadastrarNovoDocumento(novoDocumento: TipoDocumentoDTO): Observable<TipoDocumentoDTO> {
		return this.http.post<TipoDocumentoDTO>(`${API_DOCUMENTACAO}/salvar`, novoDocumento);
	}

	salvarEdicaoDocumento(novoDocumento: TipoDocumentoDTO, id: number): Observable<TipoDocumentoDTO> {
		return this.http.post<TipoDocumentoDTO>(`${API_DOCUMENTACAO}/salvar-edicao/${id}`, novoDocumento);
	}

	buscarFuncionalidades(): Observable<FuncionalidadeDTO[]>{
		return this.http.get<FuncionalidadeDTO[]>(`${API_DOCUMENTACAO}/buscar`);
	}

	buscarGrupos(): Observable<GrupoDTO[]>{
		return this.http.get<GrupoDTO[]>(`${API_DOCUMENTACAO}/buscar-grupos`);
	}

	buscarTipoDocumentoEditadoPorId(id: number): Observable<TipoDocumentoDTO>{
		return this.http.get<TipoDocumentoDTO>(`${API_DOCUMENTACAO}/buscar-editado/${id}`);
	}

	buscarModelosDocumento(): Observable<ModeloDocumentoDTO[]>{
		return this.http.get<ModeloDocumentoDTO[]>(`${API_DOCUMENTACAO}/buscar-modelos`);
	}
}