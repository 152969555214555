import { Component, OnInit } from "@angular/core";

/**
 * Componente de template, concetra todos os elementos que compoe o layout da aplicação.
 */
@Component({
	selector: "app-template",
	templateUrl: "./template.component.html",
	styleUrls: ["./template.component.scss"],
})
export class TemplateComponent implements OnInit {
	opened: boolean = false;
	comContraste: boolean = false;
	fonteDislexia: boolean = false;

	constructor() {}

	ngOnInit() {}

	receberEventoComContraste($event: boolean) {
		this.comContraste = $event
	}
	receberEventoFonteDislexia($event: boolean) {
		this.fonteDislexia = $event
	}

}

	