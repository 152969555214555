export enum GrupoDocumentoEnum {
  DADOS_ALTERACAO_NOME_CONTRATUAL = 2,
  INFORMACAO_ADICIONAIS = 3,
  DADOS_NOVA_VINCULACAO = 4,
  DADOS_ENDERECO = 5,
  DADOS_REPRESENTANTE = 6,
  DADOS_SOCIOS = 7,
  PLANO_ANUAL_AUDITORIA = 8,
  EMPRESA_AUDITORIA = 9,
  DESCREDENCIAMENTO_REQUERIMENTO = 10,
  DESCREDENCIAMENTO_IDENTIFICACAO_DCP = 11,
  POLITICAS_CERTIFICADO = 12,
  AUTORIDADE_REGISTRO = 13,
  PRESTADOR_SERVICO_SUPORTE = 14,
  SOLICITAÇÃO = 15,
  ENVIO_SOLICITACAO = 16,
  CREDENCIAMENTO_DOCUMENTACAO = 17,
  ANEXO = 18,
  CADASTRO_AGR = 19,
  DESCREDENCIAMENTO_PSS = 20,
  CREDENCIAMENTO_PSS = 21,
  DOCUMENTACAO_ASSINADA_NOVA_SOLICITACAO = 22
}