
<form [formGroup]="formDocumentosUpload">
    <div>
        <div class="row align-self-center">
            <div class="col-6 align-self-center">
                <button class="br-button primary" type="submit" (click)="gerarPdf()">Gerar PDF da solicitação para
                    assinatura</button>
            </div>
            <div class="col-6" *ngIf="_verificarDocumentosExistentesGrupo(documentos, grupoDocumento)">
                <div *ngFor="let doc of documentos">
                    <div *ngIf="doc.grupo != null && doc.grupo.id === grupoDocumento">
                        <app-ds-input-upload-form
                                    [formControlName]="'upload_'+doc.id"
                                    [solicitacaoProtocolo]="protocolo"
                                    [tipoDocumentoId]="doc.id"
                                    [multiplosAquivos]="!doc.unico"
                                    [label]="doc.nome"
                                    [obrigatorio]="doc.obrigatorio"
                                    [orientacao]="doc.orientacao"
                        ></app-ds-input-upload-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!-- <div class="d-flex justify-content-end mt-4">
        <button class="br-button secondary mr-2" type="button" (click)="voltar()">Voltar</button>
        <button class="br-button primary" type="submit" (click)="enviarSolicitacaoAssinada()">Enviar solicitação assinada</button>
    </div> -->
</form>