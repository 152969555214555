import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Perfis } from 'app/shared/enums/Perfis';
import { TipoSolicitacao } from 'app/shared/enums/tipoSolicitacao';
import { EmpresaCredenciadaDTO } from 'app/shared/model/empresa-credenciada-dto';
import { SolicitacaoCredenciamentoDTO } from 'app/shared/model/solicitacao-credenciamento-dto';
import { EmpresaCredenciadaService } from 'app/shared/service/empresa-credenciada.service';
import { UsuarioService } from 'app/shared/service/usuario.service';
import { Utils } from 'app/shared/util/utils';
import { KeycloakService } from 'keycloak-angular';
import * as moment from 'moment';

@Component({
	selector: 'app-cabecalho-solicitacao',
	templateUrl: './cabecalho-solicitacao.component.html',
	styleUrls: ['./cabecalho-solicitacao.component.scss']
})
export class CabecalhoSolicitacaoComponent {

	@Input() solicitacaoCabecalho: SolicitacaoCredenciamentoDTO
	@Input() empresaCredenciada: EmpresaCredenciadaDTO
	@Input() statusCredenciamentoDescricao: string
	@Input() titulo: string
	@Input() protocolo: string

	tipoSolicitacao: TipoSolicitacao
	perfilIti: string;

	form: UntypedFormGroup;

	cabecalho: any = {
		protocolo: "",
		cnpj: "",
		nome: "",
		dataSolicitacao: "",
		status: "",
		atividadeAtuacao: ""
	};

	constructor(private readonly keycloakService: KeycloakService, private readonly empresaCredenciadaService: EmpresaCredenciadaService, private readonly usuarioService: UsuarioService) { }

	ngOnChanges() {
		const dataAtual = Date.now();
		const tipoSolicitacaoHabilitacaoAct = Object.keys(TipoSolicitacao).find(ts => TipoSolicitacao[ts] == TipoSolicitacao.HABILITACAO_AUTORIDADE_CARIMBO_TEMPO) as TipoSolicitacao
		const tipoSolicitacaoAr = Object.keys(TipoSolicitacao).find(ts => TipoSolicitacao[ts] == TipoSolicitacao.AUTORIDADE_REGISTRO) as TipoSolicitacao
		
		if (this.solicitacaoCabecalho != null) {
			this.cabecalho = {
				protocolo: this.protocolo,
				cnpj: "",
				nome: "",
				dataSolicitacao: moment(dataAtual).format('DD/MM/YYYY'),
				status: this.statusCredenciamentoDescricao,
				atividadeAtuacao: ""
			}

			this.usuarioService.getPerfil().subscribe({
				next: (perfil: string) => {
					if(perfil === Perfis.ITI  || perfil === Perfis.ITI_DEFAULT) {
						this.cabecalho.nome = this.solicitacaoCabecalho.pessoaJuridicaSolicitante.nome;
						this.cabecalho.cnpj = Utils.formatarCNPJ(this.solicitacaoCabecalho.pessoaJuridicaSolicitante.cnpj);
						this.cabecalho.atividadeAtuacao = this.solicitacaoCabecalho.atividadeAtuacao;
					} else {
						const idEmpresaAtuacao = parseInt(this.keycloakService.getKeycloakInstance().tokenParsed["cuboNotes"].empresaAtuacao);
						this.empresaCredenciadaService.buscaAtividadePeloId(idEmpresaAtuacao).subscribe(atividadeAtuacao => {
							if(this.solicitacaoCabecalho.tipoSolicitacao == tipoSolicitacaoAr || this.solicitacaoCabecalho.tipoSolicitacao == tipoSolicitacaoHabilitacaoAct) {
								this.cabecalho.nome = this.solicitacaoCabecalho.pessoaJuridicaSolicitado.nome;
								this.cabecalho.cnpj = Utils.formatarCNPJ(this.solicitacaoCabecalho.pessoaJuridicaSolicitado.cnpj);
								this.cabecalho.atividadeAtuacao = atividadeAtuacao.nomeIcpBrasil
							}else {
								this.cabecalho.nome = atividadeAtuacao.solicitacaoCredenciamento.pessoaJuridicaSolicitante.nome;
								this.cabecalho.atividadeAtuacao = atividadeAtuacao.nomeIcpBrasil
								this.cabecalho.cnpj = Utils.formatarCNPJ(atividadeAtuacao.solicitacaoCredenciamento.pessoaJuridicaSolicitante.cnpj);
							}

						});
						
					}
				}
			});
		
			// if (this.solicitacaoCabecalho.pessoaJuridicaSolicitante != null) {
			// 	if (this.empresaCredenciada != null) {
			// 	}
			// }

		} else {
			this.cabecalho = {
				protocolo: "",
				cnpj: "",
				nome: "",
				dataSolicitacao: "",
				status: "",
				atividadeAtuacao: ""

			}
		}

	}



}
