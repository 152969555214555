import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';

import {TableRedirect, TableSource} from './table-item';
import {TableService} from "./table.service";

/**
 * Componente Table
 */
@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, AfterViewInit {
    dataExists = true;
    dataNotExists = false;
    acao = true;
    obr = false;

    @Input()
    title = '';

    @Input()
    source: TableSource;

    @Input()
    idTabela: string;

    @Input()
    redirect?: TableRedirect

    @Output()
    edited = new EventEmitter();

    @Output()
    deleted = new EventEmitter();

    displayedColumns: string[];
    dataSource: MatTableDataSource<any>;
    selection = new SelectionModel<any>(true, []);

    deleteRow = {
        title: 'Deseja apagar o registro?',
        cancelButton: {
            show: true,
            label: 'Não'
        },
        okButton: {
            show: true,
            label: 'Sim'
        }
    };

    objetos = []; //Array importante para métodos ainda em utlilizacao

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatTable) table: MatTable<any>;

    constructor(private tableService: TableService) {
    }

    ngOnInit() {
        this.tableService.setTableElement(this.source);
        let colunasASeremExibidas = this.definirColunasASeremExibidas();
        this.displayedColumns = colunasASeremExibidas;

        this.dataSource = new MatTableDataSource(this.tableService.getSource());
        
        this.tableService.emitirTabelaAlterada.subscribe(data => {
            this.dataSource.data = [...this.tableService.getSource()];
            this.table.renderRows();
        });
    }

    private definirColunasASeremExibidas() {
        return this.tableService.getColumns().filter(coluna => {
            return !coluna.includes('ignored');
        });
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    // Métodos ainda em uso até adaptação
    getObjetos(): any[] {
        return this.objetos;
    }

    setObjeto(objeto: any, idTabela: string, botoesDeAcao: HTMLAnchorElement [], permiteBotaoEditar: Boolean, permiteBotaoRemover: Boolean): any {
        this.objetos.push(objeto);
        return this.criaLinhaTabela(objeto, idTabela, botoesDeAcao, permiteBotaoEditar, permiteBotaoRemover);
    }

    criaLinhaTabela(data: any, idTabela: string, botoesDeAcao: HTMLAnchorElement [], permiteBotaoEditar: Boolean, permiteBotaoRemover: Boolean) :any {
        let keys = Object.keys(data);
        const tabela = document.querySelector(`#${idTabela} table tbody`);
        const tr = document.createElement('tr');
        tr.setAttribute('_ngcontent-dki-c239', '');
        tr.setAttribute('role', 'row');
        tr.setAttribute('mat-row', '');
        tr.setAttribute('id', 'tab'+this.objetos.length);
        tr.setAttribute('class', 'mat-row cdk-row ng-star-inserted');
        for(let i: number = 0; i < keys.length; i++){
            const td = document.createElement('td');
            td.setAttribute('_ngcontent-dki-c239', '');
            td.setAttribute('role', 'cell');
            td.setAttribute('mat-cell', '');
            td.setAttribute('class', 'mat-cell cdk-cell cdk-column-nome mat-column-nome ng-star-inserted');
            td.innerHTML = data[keys[i]];
            tr.appendChild(td);
        }
        const tdAcoes = document.createElement('td');
        tdAcoes.setAttribute('_ngcontent-dki-c239', '');
        tdAcoes.setAttribute('role', 'cell');
        tdAcoes.setAttribute('mat-cell', '');
        tdAcoes.setAttribute('class', 'mat-cell cdk-cell cdk-column-nome mat-column-nome ng-star-inserted');
        tr.appendChild(tdAcoes);
        tabela.appendChild(tr);

        botoesDeAcao.forEach(botao => {
            tdAcoes.appendChild(botao);
        })
        if(permiteBotaoEditar){
            tdAcoes.appendChild(this.botaoEditar());
        }
        if(permiteBotaoRemover){
            tdAcoes.appendChild(this.botaoRemover());
        }

        return tdAcoes;
    }

    botaoEditar(): Element {
        const button = document.createElement('a');

        button.style.cursor = 'pointer';
        button.style.color = '#0c2c57';
        button.style.margin =  '0 0.5rem';

        button.addEventListener('click', (event) => {
            //Implementar funcionalidade
        });
        button.innerHTML = `<i class="material-icons">edit</i>`;
        return button;
    }

    botaoRemover(): Element {
        const button = document.createElement('a');

        button.style.cursor = 'pointer';
        button.style.color = '#0c2c57';
        button.style.margin =  '0 0.5rem';

        button.addEventListener('click', (event) => {
            const tr = (event.composedPath()[3] as Element);
            (event.target as Element).parentNode.parentNode.parentNode.parentNode.removeChild(tr);
        });
        button.innerHTML = `<i class="material-icons">delete</i>`;
        return button;
    }

    // Novos métodos sem manipulação da DOM
    edit(data: any) {
        const element = this.tableService.editElement(data);
        this.edited.emit(element);
    }

    delete(event, data: any) {
        if(event) {
            this.tableService.deleteElement(data);
            this.deleted.emit(this.tableService.getSource());
        }else {
            return;
        }
    }
}
