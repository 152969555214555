import { Component, Input, EventEmitter, Output, ViewChild, ElementRef, forwardRef, Injector, AfterViewInit } from "@angular/core";
import { HttpEventType } from "@angular/common/http";
import { NotificationService } from "app/shared/service/notification.service";
import { AbstractControl, ControlValueAccessor, NgControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms";
import { BaseInput } from "../base/base-input";
import { DsInputUploadFormService } from "./ds-input-upload-form.service";
import { DocumentoUploadDTO } from "app/shared/model/documento-upload-dto";

@Component({
	selector: "app-ds-input-upload-form",
	templateUrl: "./ds-input-upload-form.component.html",
	styleUrls: ["./ds-input-upload-form.component.scss"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DsInputUploadFormComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => DsInputUploadFormComponent),
			multi: true,
		}
	]
})
export class DsInputUploadFormComponent extends BaseInput implements AfterViewInit, ControlValueAccessor, Validator {

	@ViewChild("inputFile") inputFile: ElementRef;

	@Input() label: string | null;

	@Input() orientacao: string | null;

	@Input() orientacaoComplementacao: string | null;

	@Input() multiplosAquivos: boolean = false;

	@Input() listaErros: string[] | null;

	@Input() urlApi: string;

	@Input() urlApiDelete: string;

	@Input() tipoArquivo: string | null;

	@Input() solicitacaoProtocolo: string | null;

	@Input() tipoDocumentoId: number | null;

	@Input() obrigatorio: boolean = false;

	@Input() tamanhoMaximo: number = 50;

	@Input() possuiAlerta: boolean = false;

	@Input() destaqueLabel: string = null;

	@Input() disabled: boolean = false;

	/*
	@Output() temArquivo = new EventEmitter<boolean>();
	*/
	@Output() documentoSalvo: EventEmitter<DocumentoUploadDTO> = new EventEmitter<DocumentoUploadDTO>();

	@Output() documentoRemovido: EventEmitter<DocumentoUploadDTO> = new EventEmitter<DocumentoUploadDTO>();

	erroTamanho: string;

	possuiErro: boolean = false;

	arquivoParaDownload: boolean = false;

	arquivoUnicoID: number;

	arquivoCarregando: boolean = false;

	arquivoSobrescrito: boolean = false;

	listaArquivos: DocumentoUploadDTO[] = [];

	_touched = false;
	_disabled = false;
	

	constructor(
		private readonly dsInputUploadFormService: DsInputUploadFormService,
		private notificationService: NotificationService,
		private inj: Injector
	) {
		super();
	}

	ngAfterViewInit() {
		const outerControl = this.inj.get(NgControl).control;
		outerControl.markAsTouched = (...args: any) => { 
			this._touched = true;
		};
	}

	onChange = (val: any) => { };
	
	onTouched = () => { };
	
	get value(): DocumentoUploadDTO[] | null {
		return this.listaArquivosValidos();
	}

	writeValue(val: DocumentoUploadDTO | DocumentoUploadDTO[] | null): void {
		this.reset();

		if (val != null) {
			if (Array.isArray(val)) {
				this.listaArquivos = val;
			} else {
				this.listaArquivos = [val];
			}
		}
		this.onChange(this.value);
	}

	registerOnChange(fn: (val: any) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	markAsTouched() {
		if (!this._touched) {
			this.onTouched();
			this._touched = true;
		}
	}

	setDisabledState(disabled: boolean) {
		this._disabled = disabled;
		this.disabled = disabled;
	}

	validate(control: AbstractControl): ValidationErrors | null {
		if (this.obrigatorio == true && (control.value == null || (Array.isArray(control.value) && control.value.length == 0))) {
			return {
				required: true
			};
		}
		return null;
	}

	/**
	 * 
	 * Método chamado no onChange do input[file] no HTML
	 * 
	 * @param $event 
	 */
	inputSelecionouArquivo($event: Event) {
		if ((this.disabled || this._disabled) == false) {
			this.markAsTouched();

			let arquivosUpload = [...$event.target["files"]];

			if (arquivosUpload && arquivosUpload.length > 0) {
				arquivosUpload.forEach(arquivo => {
					if (this.verificaExtensaoArquivo(arquivo)) {
						if (this.verificaTamanhoAquivo(arquivo)) {
							this.upload(arquivo);
							this.possuiErro = false;
							if (this.listaErros) {
								this.listaErros = this.listaErros.filter(element => {
									element != this.erroTamanho;
								});
							}
						} else {
							arquivosUpload = arquivosUpload.filter(element => element.name !== arquivo.name);
							this.listaArquivos = arquivosUpload;
						}
					}
				});
			}
		}
	}

	onClickDelete(arquivo: DocumentoUploadDTO): void {
		this.markAsTouched();

		if (this.listaArquivos) {
			this.listaArquivos.forEach(element => {
				if (element.nome === arquivo.nome) {
					this.delete(arquivo);
					this.documentoRemovido.emit(element);
				}
			});
		}
	}

	upload(arquivo: File) {
		if ((this.disabled || this._disabled) == false) {
			const file = arquivo;
			const reader = new FileReader();
			reader.readAsDataURL(file);

			let documentoId = null;
			this.arquivoSobrescrito = false;

			if (this.arquivoUnicoID && !this.multiplosAquivos) {
				documentoId = this.arquivoUnicoID;
				this.arquivoSobrescrito = true;
			}

			reader.onload = () => {
				let documento: DocumentoUploadDTO = {
					id: documentoId,
					file: reader.result as string,
					tipoDocumentoId: this.tipoDocumentoId,
					nome: file.name,
					protocolo: this.solicitacaoProtocolo,
				};

				this.dsInputUploadFormService.upload(documento, this.urlApi).subscribe({
					next: (response: any) => {
						if (this.multiplosAquivos != true) {
							// Se permitir múltiplos arquivos, vamos sempre limpar para deixar apenas 1 na lista
							this.listaArquivos = [];
						}

						this.arquivoUnicoID = response.id;

						documento.id = response.id;
						documento.nome = response.nome;
						documento.identificador = response.identificador;
						documento.file = null;

						this.listaArquivos.push(documento);
						this.documentoSalvo.emit(documento);
						if (response.type === HttpEventType.UploadProgress) {
							this.arquivoCarregando = true;
						}

						this.onChange(this.value);
					},
					error: (error: any) => {
						this.notificationService.erro(error.message);
					},
					complete: () => {
						this.arquivoCarregando = false;
						this.inputFile.nativeElement.value = "";
					},
				});
			};
		}
	}

	onClickDownload(arquivo: any, index: number) {
		this.dsInputUploadFormService.download(this.listaArquivos[index]).subscribe((response: Blob) => {
			this.dsInputUploadFormService.handleDownload(response, `${arquivo.name || arquivo.nome} .pdf`);
		});
	}

	verificaSeTemArquivo(): boolean {
		return (this.listaArquivos != null && this.listaArquivos.length > 0 ? true : false);
	}

	delete(arquivo: DocumentoUploadDTO) {
		if ((this.disabled || this._disabled) == false) {
			this.markAsTouched();

			this.dsInputUploadFormService.delete(arquivo.id, this.urlApiDelete).subscribe({
				error: (error: any) => {
					this.notificationService.erro("Ocorreu um erro ao deletar o arquivo");
				},
				complete: () => {
					this.arquivoSobrescrito = false;
					this.notificationService.limpar();
					this.notificationService.sucesso(`${arquivo.nome} removido com sucesso`);
					this.listaArquivos = this.listaArquivos.filter(element => {
						return element !== arquivo;
					});
					this.inputFile.nativeElement.value = "";
					this.onChange(this.value);
				},
			});
		}
	}

	verificaTamanhoAquivo(file: File): boolean {
		let tamanhoBytes = file.size;
		const tamanhoArquivo = Math.floor(tamanhoBytes / 1048576);

		if (tamanhoArquivo > this.tamanhoMaximo) {
			this.erroTamanho = `${file.name} tamanho inválido. O arquivo não pode ter mais de ${this.tamanhoMaximo}MB`;
			this.possuiErro = true;
			this.listaErros = [];
			this.notificationService.erro(this.erroTamanho);
			this.listaErros.push(this.erroTamanho);
			return false;
		} else {
			return true;
		}
	}

	verificaExtensaoArquivo(file: File): boolean {
		if (file.type === "application/pdf") {
			return true
		} else {
			this.notificationService.erro("Tipo de arquivo inválido, apenas arquivos do tipo PDF podem ser inseridos");
			return false;
		}
	}

	reset() {
		this.listaArquivos = [];
		this.arquivoUnicoID = null;
		this.arquivoSobrescrito = false;
		this.possuiErro = false;
		this.listaErros = [];
	}

	listaArquivosValidos(): DocumentoUploadDTO[] {
		if (this.listaArquivos == null || this.listaArquivos.length == 0) {
			return [];
		} else {
			return this.listaArquivos
		}
	}
}
