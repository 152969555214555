import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';

import {TabelaRedirect} from './tabela-item';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

/**
 * Componente Table
 */
@Component({
    selector: 'app-tabela',
    templateUrl: './tabela.component.html',
    styleUrls: ['./tabela.component.scss']
})
export class TabelaComponent implements OnInit, AfterViewInit, OnChanges {

    dataExists = true;
    dataNotExists = false;
    acao = true;
    obr = false;
    botaoEditar: boolean = false;
    botaoVisualizar: boolean = false;
    botaoDeletar: boolean = false;
    botaoEditarSemModal: boolean = false;

    @Input()
    title = '';

    @Input()
    header: Array<string> = [];

    @Input()
    rows: Array<any> = [];

    @Input()
    contentMaxChars: number = 30;

    /**
     * @TODO verificar
     */
    @Input()
    redirect?: TabelaRedirect

    @Output()
    editar = new EventEmitter();

    @Output()
    editarSemModal = new EventEmitter();

    @Output()
    deletar = new EventEmitter();

    @Output()
    visualizar = new EventEmitter();

    @Output()
    atualizar = new EventEmitter();

    displayedColumns: string[];
    dataSource: MatTableDataSource<any>;
    selection = new SelectionModel<any>(true, []);

    deleteRow = {
        title: 'Deseja apagar o registro?',
        cancelButton: {
            show: true,
            label: 'Não'
        },
        okButton: {
            show: true,
            label: 'Sim'
        }
    };

    editRow = {
        title: 'Deseja editar o registro?',
        cancelButton: {
            show: true,
            label: 'Cancelar'
        },
        okButton: {
            show: true,
            label: 'Editar'
        },
        dialogComponent: {
            form: new UntypedFormGroup({
                inputEdit: new UntypedFormControl('')
            }),
            controlName: 'inputEdit',
            label: 'Insira novo valor',
            type: 'textarea'
        },
        showComponent: true
    };

    visibilityRow = {
        title: 'Visualizar',
        cancelButton: {
            show: false,
            label: 'Cancelar'
        },
        okButton: {
            show: true,
            label: 'Fechar'
        },
        content: {
            paragraphs: []
        }
    };

    objetos = []; //Array importante para métodos ainda em utlilizacao

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatTable) table: MatTable<any>;


    constructor() {
    }

    ngOnInit() {
        let colunasASeremExibidas = this.definirColunasASeremExibidas();
        this.displayedColumns = colunasASeremExibidas;
        this.dataSource = new MatTableDataSource(this.rows);
    }

    ngOnChanges(changes: SimpleChanges){
            this.rows.map(colunas => {
                if(colunas['ações']){
                    colunas['ações'].map(nomeAcao => {
                        if(nomeAcao == 'editar'){
                            this.botaoEditar = true;
                        }
                        if(nomeAcao == 'editarSemModal'){
                            this.botaoEditarSemModal = true;
                        }
                        if(nomeAcao == 'visualizar'){
                            this.botaoVisualizar = true;
                        }
                        if(nomeAcao == 'deletar'){
                            this.botaoDeletar = true;
                        }
                    })
                } else {
                    this.botaoEditar = true;
                    this.botaoVisualizar = true;
                    this.botaoDeletar = true;
                }
            });
        if(typeof this.rows != 'undefined'){
            this.dataSource = new MatTableDataSource(this.rows);
        }
    }

    contentWithMaxCharsLimit(content: any): any{
        if(content != null && typeof content === 'string' && content.length > this.contentMaxChars){
            return content.substr(0, this.contentMaxChars) + '...'
        } else {
            return content
        }
    }

    private definirColunasASeremExibidas() {
        return this.header.filter(coluna => {
            return !coluna.includes('ignored');
        });
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    edit(event, data: any) {
        const editarValor = event;
        /**
         * @TODO alterar
         */
        data['Descrição da complementação'] = editarValor;
        this.editar.emit(data);
    }

    editSemModal(event, data: any) {
        this.editarSemModal.emit(data);
    }

    delete(event, data: any) {
        this.deletar.emit(data);
    }

    showPreview(data: any) {
        /**
         * @TODO arrumar isso
         */
        this.visibilityRow.content.paragraphs.push(data['Descrição da complementação']);
        this.visualizar.emit(data);
    }

    onMudouObrigatoriedade(event , data: any) {
        this.atualizar.emit({
            id:data.obrigatoriedade,
            obrigatoriedade: event.target.checked
        });
    }

    
}

