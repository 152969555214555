<div class="br-upload">
	<!-- Label para o arquivo -->
	<label *ngIf="label" class="upload-label">
		{{ label }}
		<span *ngIf="obrigatorio" class="required-text">(Obrigatório)</span>
		<i class="material-icons" *ngIf="orientacao" matTooltip="{{ orientacao }}" matTooltipClass="toolTip">info</i>
		<i class="material-icons alerta" *ngIf="orientacaoComplementacao" matTooltip="{{ orientacaoComplementacao }}" matTooltipClass="toolTip">report_problem</i>
	</label>

	<!-- Input Upload -->
	<input
		#inputFile
		[disabled]="disabled || _disabled"
		class="upload-input"
		type="file"
		(change)="inputSelecionouArquivo($event)"
		[multiple]="multiplosAquivos"
		[accept]="tipoArquivo ? tipoArquivo : 'application/pdf'"
	/>
	<button [disabled]="disabled || _disabled" class="br-button upload-button" [ngClass]="{ erro: possuiErro, warning: possuiAlerta }" type="button" (click)="inputFile.click()">
		<i class="material-icons" aria-hidden="true">file_upload</i>
		<span>Selecione o arquivo</span>
	</button>
	<!-- Lista de arquivos para upload -->
	<div class="upload-list" *ngFor="let arquivo of listaArquivos; let i = index">
		<!-- Aruivos individuais -->
		<div *ngIf="verificaSeTemArquivo()" class="br-item">
			<mat-spinner *ngIf="arquivoCarregando" diameter="20"></mat-spinner>
			<ng-container *ngIf="!arquivoCarregando">
				<div class="content text-primary-default mr-auto">
					<span (click)="arquivoParaDownload && arquivo.excluido != true ? onClickDownload(arquivo, i) : null" [ngClass]="{ 'arquivo-download': arquivoParaDownload, 'excluido': (arquivo.excluido === true) }">{{ arquivo.name || arquivo.nome }}</span>
				</div>
				<div *ngIf="!(disabled || _disabled) && arquivo.excluido != true" class="support">
					<span class="mr-1" *ngIf="arquivo.size">{{ arquivo.size | filesize }}</span>
					<button class="br-button" type="button" (click)="onClickDelete(arquivo)">
						<i class="material-icons">delete</i>
					</button>
					<button class="br-button" type="button" (click)="onClickDownload(arquivo, i)">
						<i class="material-icons">download</i>
					</button>
				</div>
			</ng-container>
		</div>
	</div>
	<!-- Mensagem de Erro -->
	<ng-container *ngIf="arquivoSobrescrito">
		<span class="feedback warning mt-1" role="alert">
			<i class="material-icons" aria-hidden="true">warning</i>
			O arquivo enviado anteriormente foi substituído
		</span>
	</ng-container>

	<ng-container *ngIf="listaErros">
		<ng-container *ngFor="let erro of listaErros">
			<div>
				<span class="feedback danger mt-1" role="alert">
					<i class="material-icons">cancel</i>
					{{ erro }}
				</span>
			</div>
		</ng-container>
	</ng-container>

	<mat-error *ngIf="(disabled || _disabled) == false && _touched == true && obrigatorio == true && listaArquivosValidos().length == 0">
		<span class="feedback danger" role="alert">
			<i class="fas fa-times-circle" aria-hidden="true"></i>
			É obrigatório enviar o arquivo
		</span>
	</mat-error>
</div>
