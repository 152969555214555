export enum Funcionalidade {
	CREDENCIAMENTO_AR_SOLICITACAO = 1,
	CREDENCIAMENTO_AR_DOCUMENTACAO = 2,
	CREDENCIAMENTO_AR_ENVIOSOLICITACAO = 3,
	CREDENCIAMENTO_AR_CADASTRO_AGR = 4,
	CADASTRO_AGENTE_CERTIFICADO = 5,
	AUTORIDADE_CERTIFICADORA_CADASTRO_REPRESENTANTE_LEGAL = 6,
	MANUTENCAO_CREDENCIAMENTO_AR_DADOS_ENDERECO = 7,
	MANUTENCAO_CREDENCIAMENTO_AR_NOVA_VINCULACAO = 8,
	EMPRESA_AUDITORIA = 9,
	MANUTENCAO_CREDENCIAMENTO_ALTERACAO_SOCIOS = 10,
	MANUNTENCAO_CREDENCIAMENTO_NOME_CONTRATUAL = 11,
	ATIVOS_CADASTRO_ATIVO = 12,
	SOLICITACAO_DESCREDENCIAMENTO_AR = 13,
	MANUTENCAO_INFORMAR_DADOS_AUDITORIA = 14,
	PLANO_ANUAL_AUDITORIA = 15,
	MANUTENCAO_CREDENCIAMENTO_AR_ALTERACAO_REPRESENTANTE = 16,
	CREDENCIAMENTO_AC2_SOLICITACAO = 17,
	CREDENCIAMENTO_AC2_DOCUMENTACAO = 18,
	CREDENCIAMENTO_AC2_ENVIOSOLICITACAO = 19,
	SOLICITAR_CREDENCIAMENTO_AC1 = 20,
	CREDENCIAMENTO_AC1_SOLICITACAO = 21,
	CREDENCIAMENTO_AC1_ENVIOSOLICITACAO = 22,
	MANUTENCAO_AC1_NOME_CONTRATUAL = 23,
	MANUTENCAO_CREDENCIAMENTO_AC1_NOVA_VINCULACAO = 24,
	MANUTENCAO_CREDENCIAMENTO_AC1_DADOS_ENDERECO = 25,
	MANUTENCAO_AC1_INFORMAR_DADOS_SOCIO = 26,
	MANUTENCAO_AC1_ALTERAR_REPRESENTANTE_LEGAL = 27,
	PLANO_ANUAL_AUDITORIA_AC1 = 28,
	MANUTENCAO_CREDENCIAMENTO_AC2_NOVA_VINCULACAO = 29,
	MANUTENCAO_AC2_NOME_CONTRATUAL = 30,
	MANUTENCAO_CREDENCIAMENTO_AC2_DADOS_ENDERECO = 31,
	MANUTENCAO_AC2_INFORMAR_DADOS_SOCIO = 32,
	MANUTENCAO_AC2_ALTERAR_REPRESENTANTE_LEGAL = 33,
	SOLICITACAO_DESCREDENCIAMENTO_AC1 = 34,
	PLANO_ANUAL_AUDITORIA_AC2 = 35,
	SOLICITACAO_DESCREDENCIAMENTO_AC2 = 36,
	CREDENCIAMENTO_PSS_DOCUMENTACAO = 37,
	CREDENCIAMENTO_PSS_ENVIO= 38,
	MANUTENCAO_PSS_INFORMAR_DADOS_SOCIO = 39,
	MANUTENCAO_PSS_ALTERAR_REPRESENTANTE_LEGAL = 40,
	MANUTENCAO_PSS_NOME_CONTRATUAL = 41,
	MANUTENCAO_PSS_NOVA_VINCULACAO = 42,
	MANUTENCAO_PSS_ALTERAR_ENDERECO = 43,
	SOLICITACAO_DESCREDENCIAMENTO_PSS = 44,	
	CREDENCIAMENTO_PSS_SOLICITACAO = 45,
	MANUTENCAO_DE_AC1_DESCREDENCIAMENTO_SIMPLIFICADO_DE_PSS = 46,
	MANUTENCAO_DE_AC1_CREDENCIAMENTO_SIMPLIFICADO_DE_PSS = 47,
	DOCUMENTOS_GERAIS_RESULTADO_ANALISE = 48,
	CREDENCIAMENTO_PSBIO_DOCUMENTACAO = 49,
	CREDENCIAMENTO_PSBIO_ENVIO = 50,
	MANUTENCAO_PSBIO_NOME_CONTRATUAL = 51,
	MANUTENCAO_PSBIO_ALTERAR_ENDERECO = 52,
	CREDENCIAMENTO_PSC_DOCUMENTACAO = 53,
	CREDENCIAMENTO_PSC_ENVIO= 54,
	CREDENCIAMENTO_ACT_DOCUMENTACAO = 55,
	CREDENCIAMENTO_ACT_ENVIO= 56,
	MANUTENCAO_PSC_ALTERAR_ENDERECO = 57,
	MANUTENCAO_ACT_ALTERAR_ENDERECO = 58,
	DOCUMENTO_ASSINADO = 59,
	MANUTENCAO_PSC_NOME_CONTRATUAL = 60,
	MANUTENCAO_ACT_ALTERAR_NOME_CONTRATUAL = 61
}
