<h2 mat-dialog-title>
    <span *ngIf="data.etapa == GeradorAssinadorPdfDialogComponent.ETAPA_AGUARDANDO_SENHA_CERTIFICADO_DIGITAL || data.etapa == GeradorAssinadorPdfDialogComponent.ETAPA_AGUARDANDO_REDIRECIONAMENTO; else elseBlock">Login com Certificado Digital</span>
    <ng-template #elseBlock>Geração e assinatura de PDF</ng-template>
</h2>

<div mat-dialog-content>

    <div *ngIf="data.etapa == GeradorAssinadorPdfDialogComponent.ETAPA_GERACAO_ANDAMENTO">
        <p>Aguarde, estou gerando o PDF...</p>
        <mat-spinner [diameter]="50"></mat-spinner>
    </div>

    <div *ngIf="data.etapa == GeradorAssinadorPdfDialogComponent.ETAPA_ASSINATURA_AGUARDANDO_USUARIO">
        <p>Aguardando assinatura no SDK Desktop em seu computador...</p>
        <mat-spinner [diameter]="50"></mat-spinner>
    </div>

    <div *ngIf="data.etapa == GeradorAssinadorPdfDialogComponent.ETAPA_ASSINATURA_SUCESSO">
        <p>PDF assinado com sucesso!</p>
    </div>

    <div *ngIf="data.etapa == GeradorAssinadorPdfDialogComponent.ETAPA_ASSINATURA_SDK_NAO_INICIADO">
        <p>Não consegui encontrar o SDK Desktop rodando em seu computador, não é possível prosseguir com a assinatura do PDF.</p>
        <p>Por favor, certifique-se que o SDK Desktop está instalado e em execução.</p>
    </div>

    <div *ngIf="data.etapa == GeradorAssinadorPdfDialogComponent.ETAPA_AGUARDANDO_SENHA_CERTIFICADO_DIGITAL">
        <p>Por favor, escolha seu certificado digital e/ou informe sua senha no E-sec SDK app.</p>
    </div>

    <div *ngIf="data.etapa == GeradorAssinadorPdfDialogComponent.ETAPA_AGUARDANDO_REDIRECIONAMENTO">
        <p>Seu login foi validado com sucesso!</p>
        <p>Por favor, aguarde enquanto faço seu redirecionamento...</p>
    </div>
    
</div>
