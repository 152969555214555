
  <div *ngIf="tipoMensagem == tipoMensagemAlerta" class="br-message danger" role="alert">
    <div class="icon"><i class="fas fa-times-circle fa-lg" aria-hidden="true"></i>
    </div>
    <div class="content"><span class="message-title">{{  titulo + ' ' }}</span><span class="message-body">{{ corpoMensagem }}</span></div>
  </div>
  <div *ngIf="tipoMensagem == tipoMensagemSucesso" class="br-message success" role="alert">
    <div class="icon"><i class="fas fa-check-circle fa-lg" aria-hidden="true"></i>
    </div>
    <div class="content"><span class="message-title">{{  titulo + ' ' }}</span><span class="message-body">{{ corpoMensagem }}</span></div>
  </div>
  <div *ngIf="tipoMensagem == tipoMensagemInformacao" class="br-message info" role="alert">
    <div class="icon"><i class="fas fa-info-circle fa-lg" aria-hidden="true"></i>
    </div>
    <div class="content"><span class="message-title">{{  titulo + ' ' }}</span><span class="message-body"> {{ corpoMensagem }}</span></div>
  </div>
  <div *ngIf="tipoMensagem == tipoMensagemAtencao" class="br-message warning" role="alert">
    <div class="icon"><i class="fas fa-exclamation-triangle fa-lg" aria-hidden="true"></i>
    </div>
    <div class="content"><span class="message-title">{{  titulo + ' ' }}</span><span class="message-body">{{ corpoMensagem }}</span></div>
  </div>
  