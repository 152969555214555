import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CoreModule } from "app/core/core.module";
import { SharedModule } from "app/shared/shared.module";
import { DetalheCredenciamentoDescredenciamentoPssBaseComponent } from "./detalhamento/detalhe-credenciamento-descredenciamento-pss-base.component";
import { CredenciamentoDescredenciamentoPssBaseComponent } from "./solicitacao/credenciamento-descredenciamento-pss-base.component";

@NgModule({
	declarations: [
		DetalheCredenciamentoDescredenciamentoPssBaseComponent,
		CredenciamentoDescredenciamentoPssBaseComponent
	],
	imports: [
		CommonModule,
		CoreModule,
		SharedModule
	],
	exports: [
		DetalheCredenciamentoDescredenciamentoPssBaseComponent,
		CredenciamentoDescredenciamentoPssBaseComponent
	],
	schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
	
export class CredenciamentoDescredenciamentoPssModule {}
