import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { parametrizacaoRoutes, ParametrizacaoRoutingModule } from './parametrizacao-routing.module';
import { ParametrizacaoMensageriaComponent } from './parametrizacao-mensageria/parametrizacao-mensageria.component';
import { ParametrizacaoComponent } from './parametrizacao.component';
import { CoreModule } from 'app/core/core.module';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';



@NgModule({
  imports: [
    CommonModule,
    ParametrizacaoRoutingModule,
    CoreModule,
    RouterModule.forChild(parametrizacaoRoutes),
    SharedModule
  ],
  declarations: [ParametrizacaoComponent,ParametrizacaoMensageriaComponent]
})
export class ParametrizacaoModule { }
