import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CoreModule } from "app/core/core.module";
import { SharedModule } from "app/shared/shared.module";
import { ManutencaoRepresentanteBaseComponent } from "./solicitacao/manutencao-representante-base.component";

@NgModule({
	declarations: [
		ManutencaoRepresentanteBaseComponent
	],
	imports: [
		CommonModule,
		CoreModule,
		SharedModule
	],
	exports: [
		ManutencaoRepresentanteBaseComponent
	],
	schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
	
export class ManutencaoRepresentanteModule {}