import {Component, Input, OnInit, forwardRef} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {BaseInput} from '../base/base-input';
import {NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
    selector: 'app-input-text',
    templateUrl: './input-text.component.html',
    styleUrls: ['./input-text.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InputTextComponent),
        multi: true
    }]
})
export class InputTextComponent extends BaseInput implements OnInit {
    content = true;
    other_content = false;

    @Input()
    controlName: any;

    @Input()
    value: string = '';

    @Input()
    label: string | null;

    @Input()
    showLabel: boolean = true;

    @Input()
    placeholder: string;

    @Input()
    maxlength: number;

    @Input()
    form: UntypedFormGroup;

    @Input()
    disabled = false;

    @Input()
    readonly = false;


    constructor() {
        super();
    }

    ngOnInit(): void {

    }
}
