<h2 mat-dialog-title>{{data.title}}</h2>

<div mat-dialog-content *ngIf="data.content">
    {{data.content}}

</div>

<div *ngIf="data.url">
    <iframe
    [src]="url"
    frameBorder="0"
    scrolling="auto"
    height="450px"
    width="1000px"
    ></iframe>
</div>

<div mat-dialog-actions class="d-flex justify-content-end">
    <button *ngIf="data.cancelButton && data.cancelButton.show" [mat-dialog-close]="false" class="br-button secondary">
        <i *ngIf="data.cancelButton.icon" class="material-icons">{{data.cancelButton.icon}}</i>
        {{data.cancelButton.label}}
    </button>
    
    <button *ngIf="data.okButton && data.okButton.show" [mat-dialog-close]="true" class="br-button primary">
        <i *ngIf="data.okButton.icon" class="material-icons">{{data.okButton.icon}}</i>
        {{data.okButton.label}}
    </button>
</div>
