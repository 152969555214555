import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AssinaturaPdfHelper } from 'app/shared/helper/assinatura-pdf-helper';
import { DocumentoSolicitacao } from 'app/shared/model/documento-solicitacao';
import { PdfService } from 'app/shared/service/pdf.service';
import { SelectItem } from '../dropdown/select-item';

@Component({
  selector: 'app-gerar-oficio-modal',
  templateUrl: './gerar-oficio-modal.component.html',
  styleUrls: ['./gerar-oficio-modal.component.scss']
})
export class GerarOficioModalComponent implements OnInit {

	form: UntypedFormGroup;
	gerarOficio:SelectItem[];

	constructor(
	readonly formBuilder: UntypedFormBuilder,
	) {
	
	}
	ngOnInit(): void {
		this.getGerarOficio();

		this.form = this.formBuilder.group({
			gerarOficio:[null,null],
			conteudo:[null, null]
		})

	}

	getGerarOficio(): void {
		this.gerarOficio = [
		  { label: "Modelo 1", value: "1" },
		  { label: "Modelo 2", value: "2" },
		  { label: "Modelo 3", value: "3" },
		];
	}


}
