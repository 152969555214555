type Erro = (label?: string, error?: any) => string;

/**
 * Interface de validação.
 */
interface MensavemValidacao {
    key: string;
    mensagem: Erro;
}

/**
 * Mapa que abstrai as mensagens e tipo de validação a ser executada.
 */
export class MensavemValidacaoMap {

    static lista: MensavemValidacao[] = [
        {key: 'required', mensagem: () => `Preenchimento obrigatório`},
        {key: 'pattern', mensagem: () => `Formato inválido`},
        {key: 'minlength', mensagem: (label, error) => `${label} deve ter no mínimo ${error.requiredLength} caracteres`},
        {key: 'maxlength', mensagem: (label, error) => `${label} deve ter no máximo ${error.requiredLength} caracteres`},
        {key: 'min', mensagem: (label, error) => `${label} não pode ser menor que ${error.min}`},
        {key: 'max', mensagem: (label, error) => `${label} não pode ser maior que ${error.max}`},
    ]

    get(key: string, label: string, error: any): string {
        const mensagem = MensavemValidacaoMap.lista.filter(value => value.key === key);
        if (mensagem) {
            return mensagem[0].mensagem(label, error);
        }
    }

}
