<form [formGroup]="form">
    <h5 class="section-title">Para qual área gostaria de encaminhar essa solicitação?</h5>
    <div>
        <app-dropdown [form]="form" [items]="areaAtuacao" controlName="areaAtuacao" placeholder="Selecione a área"
            (selectionChange)="areaAtuacaoSelecionado($event)" class="col-8"></app-dropdown>

        <div mat-dialog-actions class="d-flex justify-content-end">
            <button type="button" *ngIf="data.cancelButton && data.cancelButton.show" [mat-dialog-close]="false"
                class="br-button secondary" (click)="onNoClick()">
                <i *ngIf="data.cancelButton.icon" class="material-icons">{{ data.cancelButton.icon }}</i>
                {{ data.cancelButton.label }}
            </button>

            <button type="button" *ngIf="data.okButton && data.okButton.show" [mat-dialog-close]="true"
                class="br-button primary" (click)="onOkClick()">
                <i *ngIf="data.okButton.icon" class="material-icons">{{ data.okButton.icon }}</i>
                {{ data.okButton.label }}
            </button>
        </div>
    </div>
</form>