import { Component, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AreaAtuacaoService } from 'app/modulos/area-atuacao/area-atuacao.service';
import { Mensagem } from 'app/shared/enums/mensagem';
import { StatusCredenciamento } from 'app/shared/enums/statusCredenciamento';
import { TipoMensagem } from 'app/shared/enums/tipoMensagemEnum';
import { AnaliseTecnica } from 'app/shared/model/analise-tecnica';
import { AreaAtuacaoDTO } from 'app/shared/model/area-atuacao-dto';
import { SolicitacaoCredenciamentoDTO } from 'app/shared/model/solicitacao-credenciamento-dto';
import { SolicitacaoGeralEncaminhamentoDTO } from 'app/shared/model/solicitacao-geral-encaminhamento-dto';
import { SolicitacaoSalvarEncaminharDTO } from 'app/shared/model/solicitacao-salvar-encaminhar-dto';
import { AnaliseTecnicaResultadoService } from 'app/shared/service/analise-tecnica-resultado.service';
import { NotificationService } from 'app/shared/service/notification.service';
import { SolicitacaoService } from 'app/shared/service/solicitacao-service';
import { DialogData } from '../dialog/dialog-data';
import { SelectItem } from '../dropdown/select-item';

@Component({
	selector: 'app-solicitar-complementos-modal',
	templateUrl: './solicitar-complementos-modal.component.html',
	styleUrls: ['./solicitar-complementos-modal.component.scss']
})
export class SolicitarComplementosModalComponent implements OnInit {
	private readonly mensagem = Mensagem;

	form: UntypedFormGroup;
	areaAtuacao: SelectItem[] = [];
	deferido: SelectItem[] = [{ label: 'Sim', value: "S" }, { label: 'Não', value: "N" }];
	prazoSelecionado: boolean = false;
	itemSelecionado: StatusCredenciamento;
	idAnaliseTecnica: number;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
		readonly formBuilder: UntypedFormBuilder,
		readonly areaAtuacaoService: AreaAtuacaoService,
		public modalRef: MatDialogRef<SolicitarComplementosModalComponent>,
		private solicitacaoService: SolicitacaoService,
		private notificationService: NotificationService,
		private router: Router,
		private readonly analiseTecnicaResultadoService: AnaliseTecnicaResultadoService,
	) {

	}

	ngOnInit(): void {
		this.form = this.formBuilder.group({
			areaAtuacao: [null, Validators.required],
			deferido: [null, null],
			prazo: [null, null]
		});
	}

	onOkClick() {
		const solicitacao: SolicitacaoCredenciamentoDTO = {
			protocolo: this.data.protocolo,
		}

		const encaminhamento: SolicitacaoGeralEncaminhamentoDTO = {
			areaAtuacaoAnterior: this.data.areaAtuacaoAnterior,
			parecer: this.data.parecer,
		}

		const analiseEnvio: AnaliseTecnica = {
			protocolo: this.data.protocolo,
			completo: "PENDENTE",
			descricao: ''
		};

		//- Complementação com documentos
		if (this.data.complementacaoRows.length > 0) {
			this.data.complementacaoRows.forEach(complementacao => {
				complementacao.prazo = this.form.controls.prazo.value;
				complementacao.parecer = this.data.parecer
			})
			const solicitacaoEncerrada: SolicitacaoSalvarEncaminharDTO = {
				solicitacao: solicitacao,
				encaminhamento: encaminhamento,
				listaDocumentos: this.data.documentos,
				prazo: this.form.controls.prazo.value,
				complementacaoDocumentos: this.data.complementacaoRows
			}
			this.analiseTecnicaResultadoService.salvarAnaliseTecnica(analiseEnvio).subscribe(
				({ id }) => {
				  this.idAnaliseTecnica = id;
  
				  this.solicitacaoService.solicitarComplementacao(solicitacaoEncerrada).subscribe(result => {
					this.notificationService.sucesso(this.mensagem.SUCESSO.COMPLEMENTACAO_SOLICITADA);
					this.voltarTelaConsulta();
				  });
				},
			  );
		} else {
			//- Complementação sem documentos
			const solicitacaoEncerrada: SolicitacaoSalvarEncaminharDTO = {
				solicitacao: solicitacao,
				encaminhamento: encaminhamento,
				listaDocumentos: this.data.documentos,
				prazo: this.form.controls.prazo.value
			}

			this.analiseTecnicaResultadoService.salvarAnaliseTecnica(analiseEnvio).subscribe(
			  ({ id }) => {
			    this.idAnaliseTecnica = id;

			    this.solicitacaoService.solicitarComplementacao(solicitacaoEncerrada).subscribe(result => {
			      this.notificationService.sucesso(this.mensagem.SUCESSO.COMPLEMENTACAO_SOLICITADA);
			      this.voltarTelaConsulta();
			    });
			  },
			);
		}
	}

	onNoClick(): void {
		this.modalRef.close(null);
	}


	escolhidoPrazo(payload: any) {
		if (payload == "S") {
			this.prazoSelecionado = true;
		} else {
			this.prazoSelecionado = false;
		}
	}

	async voltarTelaConsulta(mensagem?: string) {
		if (mensagem) this.notificationService.sucesso(mensagem);
		this.router.navigate(["/credenciamento/consultar-solicitacao"]);
	}
}