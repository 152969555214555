import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnexoDTO } from '../model/anexo-dto';
import { RepresentanteAC2DTO } from '../model/credenciamento-ac-2/dados-basicos/representante-ac-2-dto';

import { DetalharCredenciamentoDTO } from '../model/detalhar-credenciamento';
import { HistoricoSolicitacaoDTO } from '../model/historico-solicitacao-dto';
import { Page, QueryBuilder } from '../util/pagination';
import { Utils } from '../util/utils';

@Injectable({
	providedIn: "root",
})
export class DetalharCredenciamentoService {
	constructor(private readonly http: HttpClient) {}

	buscarDetalhesDeCredenciamento(protocolo: string): Observable<DetalharCredenciamentoDTO> {
		return this.http.get<DetalharCredenciamentoDTO>(environment.cadastroApiUrl + `/detalhar-credenciamento/buscar-detalhes-credenciamento/${protocolo}`);
	}

	historicoSolicitacao(protocolo: string, queryBuilder: QueryBuilder): Observable<Page<HistoricoSolicitacaoDTO>> {
		protocolo = Utils.formatarProtocolo(protocolo);
		return this.http
			.get<HistoricoSolicitacaoDTO[]>(`${environment.cadastroApiUrl}/detalhar-credenciamento/historico?protocolo=${protocolo}&${queryBuilder.buildQueryString()}`, { observe: "response" })
			.pipe(map(response => <Page<HistoricoSolicitacaoDTO>>Page.fromResposne(response)));
	}

	//-----------------------------------------------------------------------AC2-------------------------------------------------------
	
	anexoSolicitacao(protocolo: string, queryBuilder: QueryBuilder): Observable<Page<AnexoDTO>> {
		protocolo = Utils.formatarProtocolo(protocolo);
		return this.http
			.get<HistoricoSolicitacaoDTO[]>(`${environment.cadastroApiUrl}/detalhar-credenciamento/anexo?protocolo=${protocolo}&${queryBuilder.buildQueryString()}`, { observe: "response" })
			.pipe(map(response => <Page<AnexoDTO>>Page.fromResposne(response)));
	}
	representanteSolicitacao(protocolo: string, queryBuilder: QueryBuilder): Observable<Page<RepresentanteAC2DTO>> {
		protocolo = Utils.formatarProtocolo(protocolo);
		return this.http
			.get<RepresentanteAC2DTO[]>(`${environment.cadastroApiUrl}/detalhar-credenciamento-ac2/representantes?protocolo=${protocolo}&${queryBuilder.buildQueryString()}`, { observe: "response" })
			.pipe(map(response => <Page<RepresentanteAC2DTO>>Page.fromResposne(response)));
	}
	
	buscarDetalhesDeCredenciamentoAC2(protocolo: string): Observable<DetalharCredenciamentoDTO> {
		return this.http.get<DetalharCredenciamentoDTO>(environment.cadastroApiUrl + `/detalhar-credenciamento-ac2/buscar-detalhes-credenciamento-ac2/${protocolo}`);
	}


	//-----------------------------------------------------------------------AC1-------------------------------------------------------

	buscarDetalhesDeCredenciamentoAC1(protocolo: string): Observable<DetalharCredenciamentoDTO> {
		return this.http.get<DetalharCredenciamentoDTO>(environment.cadastroApiUrl + `/detalhar-credenciamento-ac1/buscar-detalhes-credenciamento/${protocolo}`);
	}

	anexoSolicitacaoAC1(protocolo: string, queryBuilder: QueryBuilder): Observable<Page<AnexoDTO>> {
		protocolo = Utils.formatarProtocolo(protocolo);
		return this.http
			.get<HistoricoSolicitacaoDTO[]>(`${environment.cadastroApiUrl}/detalhar-credenciamento-ac1/anexo?protocolo=${protocolo}&${queryBuilder.buildQueryString()}`, { observe: "response" })
			.pipe(map(response => <Page<AnexoDTO>>Page.fromResposne(response)));
	}

		//-----------------------------------------------------------------------PSS-------------------------------------------------------

		buscarDetalhesDeCredenciamentoPSS(protocolo: string): Observable<DetalharCredenciamentoDTO> {
			return this.http.get<DetalharCredenciamentoDTO>(environment.cadastroApiUrl + `/detalhar-credenciamento-pss/buscar-detalhes-credenciamento-pss/${protocolo}`);
		}

		//-----------------------------------------------------------------------PSBio-------------------------------------------------------

		buscarDetalhesDeCredenciamentoPSBIO(protocolo: string): Observable<DetalharCredenciamentoDTO> {
			return this.http.get<DetalharCredenciamentoDTO>(environment.cadastroApiUrl + `/detalhar-credenciamento-psbio/buscar-detalhes-credenciamento-psbio/${protocolo}`);
		}
	
		//-----------------------------------------------------------------------PSC-------------------------------------------------------

		buscarDetalhesDeCredenciamentoPSC(protocolo: string): Observable<DetalharCredenciamentoDTO> {
			return this.http.get<DetalharCredenciamentoDTO>(environment.cadastroApiUrl + `/detalhar-credenciamento-psc/buscar-detalhes-credenciamento-psc/${protocolo}`);
		}
}
