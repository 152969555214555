import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ParametrizacaoMensageriaComponent } from "./parametrizacao-mensageria/parametrizacao-mensageria.component";
import { ParametrizacaoComponent } from "./parametrizacao.component";
import { TemplateComponent } from "app/core/template/template.component";

export const parametrizacaoRoutes: Routes = [
  {
    path: "",
    component: TemplateComponent,
    children: [
      {
        path: "parametrizar-obrigatoriedade",
        component: ParametrizacaoComponent,
        data: {
          breadcrumb: "Parametrizar Obrigatoriedade",
        },
      },
      {
        path: "parametrizar-mensagem-de-alerta",
        component: ParametrizacaoMensageriaComponent,
        data: {
          breadcrumb: "Parametrizar Mensagem de Alerta",
        },
      },
      {
        path: "tipo-documento",
        loadChildren: () => import("../tipo-documento/tipo-documento.module").then(value => value.TipoDocumentoModule),
        data: {
          breadcrumb: "Tipos de Documentos",
        },
      },
      {
        path: "modelo-documento",
        loadChildren: () => import("../modelo-documento/modelo-documento.module").then(value => value.ModeloDocumentoModule),
        data: {
          breadcrumb: "Modelos de Documentos",
        },
      },
      {
        path: "area-atuacao",
        loadChildren: () => import("../area-atuacao/area-atuacao.module").then(value => value.AreaAtuacaoModule),
        data: {
          breadcrumb: "Areas de Atuação",
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(parametrizacaoRoutes)],
  exports: [RouterModule],
})
export class ParametrizacaoRoutingModule {}
