<h5 class="section-title">Histórico da Solicitação</h5>
<super-tabela 
	[header]="historicoSolicitacaoHeader"
	 [rows]="historicoDataRows" 
	 (visualizar)="visualizarRegistroHistorico($event)"  
	 class="col-12"
	[paginaAtual]="paginaAtual"
	[totalPaginas]="totalPaginas"
	[totalItens]="totalItens"
	[primeiraPagina]="primeiraPagina"
	[ultimaPagina]="ultimaPagina"
	(paginaSelecionada)="paginaSelecionada = $event; detalharHistorico()"
></super-tabela>
