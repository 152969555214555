import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { DadosSolicitacaoACDTO } from "../model/credenciamento-ac-2/dados-solicitacao/dados-solicitacao-ac-2-dto";
import { DocumentoSolicitacaoEntidadeDTO } from "../model/documento-solicitacao-entidade-dto";
import { DocumentoUploadDTO } from "../model/documento-upload-dto";
import { PessoaJuridicaDTO } from "../model/pessoa-juridica-dto";
import { Solicitacao } from "../model/solicitacao";
import { SolicitacaoCredenciamentoDTO } from '../model/solicitacao-credenciamento-dto';
import { SolicitacaoGeralEncaminhamentoDTO } from "../model/solicitacao-geral-encaminhamento-dto";
import { SolicitacaoSalvarEncaminharDTO } from "../model/solicitacao-salvar-encaminhar-dto";
import { Utils } from '../util/utils';
/**
 * Requisição ao back end para consultar estados.
 */
@Injectable({
	providedIn: "root",
})
export class SolicitacaoService {
	protocolo: string;

	constructor(private readonly http: HttpClient) {}

	// --------------- -----------------------------------SOLICITAÇAO AR --------------------------------------------------

	buscarSolicitacao(id: number): Observable<Solicitacao[]> {
		return this.http.get<Solicitacao[]>(environment.cadastroApiUrl + `/dadosSolicitacao/buscarSolicitacao/${id}`);
	}

	buscarSolicitacaoPeloProtocolo(protocolo: string): Observable<SolicitacaoCredenciamentoDTO> {
		protocolo = Utils.formatarProtocolo(protocolo);
		return this.http.get<SolicitacaoCredenciamentoDTO>(`${environment.cadastroApiUrl}/solicitacao-credenciamento/buscar/${protocolo}`);
	}

	salvarSolicitacao(solicitacao: Solicitacao): Observable<Object> {
		return this.http.post(environment.cadastroApiUrl + "/dadosSolicitacao/cadastro", solicitacao);
	}

	salvarDocumentosUpload(solicitacao: SolicitacaoCredenciamentoDTO, documentos: DocumentoUploadDTO[]): Observable<Object> {
		if (documentos != null && documentos.length > 0) {
			documentos.forEach((elem, index, arr) => {
				documentos[index].solicitacaoCredenciamento = solicitacao;
			})
		}
		return this.http.post(environment.cadastroApiUrl + "/solicitacao-credenciamento/salvar-documentos-upload", documentos);
	}

	salvarDescricaoDocumentosUpload(solicitacao: SolicitacaoCredenciamentoDTO, documentos: DocumentoUploadDTO[]): Observable<Object> {
		if (documentos != null && documentos.length > 0) {
			documentos.forEach((elem, index, arr) => {
				documentos[index].solicitacaoCredenciamento = solicitacao;
			})
		}
		return this.http.post(environment.cadastroApiUrl + "/solicitacao-credenciamento/salvar-descricao-documentos-upload", documentos);
	}

	salvarDocumentosUploadSolicitacaoEntidade(documentos: DocumentoSolicitacaoEntidadeDTO[]): Observable<Object> {
		return this.http.post(environment.cadastroApiUrl + "/solicitacao-credenciamento/salvar-documentos-upload-entidade", documentos);
	}

	salvarAssinaturaAR(protocolo: String, nomeArquivo: String): Observable<Object> {
		return this.http.post(environment.cadastroApiUrl + "/dadosSolicitacao/salvar-assinatura-ar", {
			protocolo: protocolo,
			nomeArquivo: nomeArquivo,
		});
	}

	salvarAssinaturaAC(protocolo: String, nomeArquivo: String): Observable<Object> {
		return this.http.post(environment.cadastroApiUrl + "/dadosSolicitacao/salvar-assinatura-ac", {
			protocolo: protocolo,
			nomeArquivo: nomeArquivo,
		});
	}
	
	// --------------- -----------------------------------SOLICITAÇAO AC1 --------------------------------------------------

	
	buscarSolicitacaoAC1(id: number): Observable<Solicitacao[]> {
		return this.http.get<Solicitacao[]>(environment.cadastroApiUrl + `/dadosSolicitacao/buscarSolicitacao-ac1/${id}`);
	}


	salvarSolicitacaoAC1(dadosSolicitacao: DadosSolicitacaoACDTO): Observable<Object> {
		return this.http.post(environment.cadastroApiUrl + "/dadosSolicitacao/cadastro-ac1", dadosSolicitacao);
	}

	salvarRascunhoSolicitacaoAC1(dadosSolicitacao: DadosSolicitacaoACDTO): Observable<Object> {
		return this.http.post(environment.cadastroApiUrl + "/dadosSolicitacao/rascunho-ac1", dadosSolicitacao);
	}

	// --------------- -----------------------------------SOLICITAÇAO AC2 --------------------------------------------------

	buscarSolicitacaoAC2(id: number): Observable<Solicitacao[]> {
		return this.http.get<Solicitacao[]>(environment.cadastroApiUrl + `/dadosSolicitacao/buscarSolicitacao-ac2/${id}`);
	}


	salvarSolicitacaoAC2(dadosSolicitacao: DadosSolicitacaoACDTO): Observable<Object> {
		return this.http.post(environment.cadastroApiUrl + "/dadosSolicitacao/cadastro-ac2", dadosSolicitacao);
	}

	salvarRascunhoSolicitacaoAC2(dadosSolicitacao: DadosSolicitacaoACDTO): Observable<Object> {
		return this.http.post(environment.cadastroApiUrl + "/dadosSolicitacao/rascunho-ac2", dadosSolicitacao);
	}


	// --------------- -----------------------------------SOLICITAÇAO PSS --------------------------------------------------

	buscarSolicitacaoPSS(id: number): Observable<Solicitacao[]> {
		return this.http.get<Solicitacao[]>(environment.cadastroApiUrl + `/dadosSolicitacao/buscarSolicitacao-pss/${id}`);
	}

	salvarSolicitacaoPSS(dadosSolicitacao: DadosSolicitacaoACDTO): Observable<Object> {
		return this.http.post(environment.cadastroApiUrl + "/dadosSolicitacao/cadastro-pss", dadosSolicitacao);
	}

	//-------------------------------------------------------------------------------------------------------------------------------------------
	encaminharSolicitacao( encaminhamento: SolicitacaoSalvarEncaminharDTO) {
        return this.http.post(`${environment.cadastroApiUrl}/solicitacao-credenciamento/encaminhar-solicitacao`, encaminhamento);
    }

	salvarEncaminharSolicitacao(solicitacao): Observable<Object>{
		return this.http.post(environment.cadastroApiUrl + "analise-solicitacao/salvar-encaminhar-solicitacao",solicitacao)
	}

	buscarEncaminhamentoPelaSolicitacao(protocolo: string): Observable<SolicitacaoGeralEncaminhamentoDTO[]> {
		return this.http.get<SolicitacaoGeralEncaminhamentoDTO[]>(`${environment.cadastroApiUrl}/solicitacao-credenciamento/buscar-encaminhamento?protocolo=${protocolo}`);
	}

	salvarDocumentoAssinado(protocolo: string) {
        return this.http.post(`${environment.cadastroApiUrl}/solicitacao-credenciamento/salvar-documento-assinado`, protocolo);
    }

	encerrarSolicitacao(solicitacao): Observable<Object>{
		return this.http.post(environment.cadastroApiUrl + "/solicitacao-credenciamento/encerrar-solicitacao",solicitacao)
	}

	solicitarComplementacao(solicitacao): Observable<Object>{
		return this.http.post(environment.cadastroApiUrl + "/solicitacao-credenciamento/solicitar-complementacao",solicitacao)
	}
	
}
