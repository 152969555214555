import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { DadosEmpresaSolicitanteDTO } from "../model/dados-empresa-solicitante-dto";
import { SolicitacaoCredDescredPssDTO } from "../model/solicitacao-cred-descred-pss-dto";
import { Utils } from "../util/utils";
import { DocumentoSolicitacaoEntidadeDTO } from "../model/documento-solicitacao-entidade-dto";
import { ComplementacaoDocumentos } from "../model/complementacao-documentos";

@Injectable({
	providedIn: "root", 
})
export class CredenciamentoDescredenciamentoPSSService {
    constructor(private readonly http: HttpClient) {}

    salvarDadosAutoridadeCertificadora(dadosAutoridadeCertificadora: DadosEmpresaSolicitanteDTO): Observable<Object> {
		return this.http.post(`${environment.cadastroApiUrl}/credenciamento-descredenciamento-pss/dados-empresa-solicitante`, dadosAutoridadeCertificadora);
	}

	salvarSolicitacaoPss(solicitacao: SolicitacaoCredDescredPssDTO): Observable<SolicitacaoCredDescredPssDTO> {
		return this.http.post<SolicitacaoCredDescredPssDTO>(`${environment.cadastroApiUrl}/credenciamento-descredenciamento-pss/salvar`, solicitacao);
	}
	salvarDocumentoPss(documentos: DocumentoSolicitacaoEntidadeDTO): Observable<DocumentoSolicitacaoEntidadeDTO> {
		return this.http.post<DocumentoSolicitacaoEntidadeDTO>(`${environment.cadastroApiUrl}/credenciamento-descredenciamento-pss/salvar-documento`, documentos);
	}
	detalharCredenciamentoDescredenciamentoPSS(protocolo: string): Observable<SolicitacaoCredDescredPssDTO> {
		protocolo = Utils.formatarProtocolo(protocolo);
		return this.http.get<SolicitacaoCredDescredPssDTO>(`${environment.cadastroApiUrl}/credenciamento-descredenciamento-pss/detalhe/${protocolo}`);
	}

}