export enum Perfis {
	ITI = "ITI",
	GOV_BR = "GOV_BR",
	ITI_DEFAULT = "iti_default",
	GOV_BR_DEFAULT = "govBr_default",
	ANALISTA_TECNICO = "analista-tecnico",
	CGAF = "cgaf",
	DAFN = "dafn",
	DIRETOR = "diretor",
	REPRESENTANTE_AC = "representante-ac",
	REPRESENTANTE_AR = "representante-ar",
	DESENVOLVEDOR = "dev",
}
