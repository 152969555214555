import { EventBusService } from 'app/shared/service/event-bus.service';
import { Component, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { EventBusData } from 'app/shared/class/event-bus-data.class';
import * as moment from 'moment';

import { TipoOperacao } from '../../../shared/enums/tipoOperacao';
import { HistoricoSolicitacaoDTO } from '../../../shared/model/historico-solicitacao-dto';
import { DetalharCredenciamentoService } from '../../../shared/service/detalhar-credenciamento.service';
import { Page, PageRequest } from '../../../shared/util/pagination';
import { SimpleDialogComponent } from '../simple-dialog/simple-dialog.component';

@Component({
	selector: "app-historico-solicitacao",
	templateUrl: "./historico-solicitacao.component.html",
	styleUrls: ["./historico-solicitacao.component.scss"],
})
export class HistoricoSolicitacaoComponent implements OnChanges {
	/* EVENTOS */
	@Input() protocolo: string;

	/* HISTÓRICO */
	historicoSolicitacaoHeader: any[] = [
		{ title: "Operação", field: "operacao" },
		{ title: "Data", field: "data" },
		{ title: "Ações", field: "acoes", actions: ["preview"] },
	];
	historicoDataRows: any[] = [];

	/* PAGINAÇÃO */
	page: Page<HistoricoSolicitacaoDTO> = new Page([], true, false, 0, 0, 0);
	pageEvent: PageEvent;
	totalPaginas: number[] = [];
	totalItens: number = 0;
	paginaAtual: number;
	paginaSelecionada: number = 0;
	primeiraPagina: boolean = false;
	ultimaPagina: boolean = false;

	constructor(private readonly detalharCredenciamentoService: DetalharCredenciamentoService, public dialog: MatDialog) {}

	ngOnChanges(): void {
		if (this.protocolo) this.detalharHistorico();
	}

	public visualizarRegistroHistorico(payload: any) {
		const historico = payload.entity;

		this.dialog.open(SimpleDialogComponent, {
			data: {
				title: "Visualização Histórico",
				width: "250px",
				content: `${historico.data} - ${historico.parecer}`,
				okButton: { show: true, label: "Ok" },
				cancelButton: { show: false, label: "Cancelar" },
			},
		});
	}

	public detalharHistorico() {
		//- Consulta do histórico paginado
		let queryAdicional;
		const pageRequest: PageRequest = new PageRequest(
			{ pageNumber: this.paginaSelecionada > 0 ? this.paginaSelecionada - 1 : this.paginaSelecionada, pageSize: this.pageEvent ? this.pageEvent.pageSize : 5 },
			queryAdicional
		);

		this.detalharCredenciamentoService
			.historicoSolicitacao(this.protocolo, pageRequest)
			.subscribe(({ content: historico, pageNumber, totalPages, totalElements, first, last }: Page<HistoricoSolicitacaoDTO>) => {
				this.totalPaginas = [];
				this.historicoDataRows = [];

				for (let i = 1; i <= totalPages; i++) {
					this.totalPaginas.push(i);
				}

				this.totalItens = totalElements;
				this.paginaAtual = pageNumber + 1;
				this.primeiraPagina = first;
				this.ultimaPagina = last;
				
				if (historico.length > 0) {
					//- Data da última atualização na solicitação
					 const ultimaAtualizacao = historico[historico.length - 1].data;
					//this.form.controls.ultimaAtualizacao.setValue(ultimaAtualizacao);

					historico.forEach((historicoItem: HistoricoSolicitacaoDTO) => {
						const operacao = TipoOperacao[historicoItem.operacao];


						this.historicoDataRows.push({ 
							operacao: operacao, 
							data: moment(historicoItem.data).format("DD/MM/YYYY"),
							parecer: historicoItem.parecer
						});
						this.historicoDataRows = JSON.parse(JSON.stringify(this.historicoDataRows));
					});
				}

			});
	}


}
