import {Injectable} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {MensavemValidacaoMap} from './mensagem-validacao';
import {FormHelper} from '../../../shared/helper/form-helper';

/**
 * Classe abstrata que trata os erros de validação e serve de base os inputs
 */
@Injectable()
export abstract class BaseInput {

    /**
     * Mensagens de validação de acordo com o FormControl
     *
     * @type {MensavemValidacaoMap}
     */
    readonly erros: MensavemValidacaoMap = new MensavemValidacaoMap();

    /**
     * FormControlName do input
     *
     * @type {string}
     */
    controlName: string;

    /**
     * Referencia do form para verificação de erros no input
     *
     * @type {UntypedFormGroup}
     */
    form: UntypedFormGroup;

    /**
     * Label do campo
     *
     * @type {string}
     */
    label: string;

    /**
     * Retorna a mensagem de validação do componente
     *
     * @returns {string}
     */
    validacao() {
        let mensagem = '';
        const control = this.form.get(this.controlName);
        const errors = control.errors;
        if (errors && control.invalid && (control.touched || control.dirty)) {
            const errorsKeys = Object.keys(errors);
            if (errorsKeys.length > 0) {
                const key = errorsKeys[0];
                mensagem = this.erros.get(key, this.label.replace(/[*:]/g, ''), errors[key]);
            }
        }
        return mensagem;
    }

    /**
     * Verifica se o componente é obrigatório ou não, baseando-se no valor obtido atraves do form reativo
     *
     * @returns {boolean}
     */
    required(): boolean {
        return FormHelper.hasRequired(this.form.get(this.controlName))
            && !this.form.get(this.controlName).disabled;
    }
}
