import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

import {MATERIAL_IMPORTS} from './material-imports';
import {CuboDirectiveModule} from './directive/cubo-directive.module';
import {CuboPipeModule} from './pipes/cubo-pipe.module';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        MATERIAL_IMPORTS,
        ReactiveFormsModule,
    ],
    exports: [
        MATERIAL_IMPORTS,
        CuboDirectiveModule,
        CuboPipeModule,
        ReactiveFormsModule
    ]
})
export class SharedModule {
}
