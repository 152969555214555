import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { Usuario } from "../model/Usuario";
import { StatusCredenciamento } from "../enums/statusCredenciamento";
import { EmpresasAtuacaoDTO } from "../model/empresas-atuacao-dto";
import { Utils } from '../util/utils';
import { UsuarioSsoDTO } from "../model/usuario-sso-dto";
import { Perfil } from "../model/Perfil";

/**
 * Exemplo de requisição ao back-end.
 */
@Injectable({
	providedIn: "root",
})
export class UsuarioService {
	private readonly API: string = `${environment.cadastroApiUrl}`;

	constructor(private readonly http: HttpClient) {}

	hello() {
		return this.http.get<any>(this.API + "/hello");
	}

	getAcessosSidebar(): Observable<any> {
		return this.http.get<any>(`${this.API}/permissoes-usuario/acessos-sidebar`);
	}

	getAcessos(): Observable<any> {
		return this.http.get<any>(`${this.API}/permissoes-usuario/acessos`);
	}

	getAcessosDetalharCredenciamento(status: StatusCredenciamento, protocolo?: string): Observable<any> {
		protocolo = Utils.formatarProtocolo(protocolo);
		return this.http.get<any>(`${this.API}/permissoes-usuario/acessos-detalhar-credenciamento/${status}/${protocolo}`);
	}

	getPermissoes(permissoes: any | any[]): Observable<any> {
		return this.http.get<any>(`${this.API}/permissoes-usuario/permissoes?permissoes=${permissoes}`);
	}

	getPerfil(): Observable<any> {
		return this.http.get<any>(`${this.API}/permissoes-usuario/perfil`); //Perfil iti ou perfil gov.br
	}

	buscarTodosOsUsuariosSso(): Observable<UsuarioSsoDTO[]> {
		return this.http.get<UsuarioSsoDTO[]>(`${this.API}/usuario/lista`);
	}

	buscarUsuarioPeloIdKeycloak(id: string): Observable<any> {
		return this.http.get<any>(`${this.API}/usuario/busca/${id}`);
	}

	editarUsuario(usuario: Usuario): Observable<any> {
		return this.http.patch(`${this.API}/usuario/edita`, usuario);
	}

	/**
	 * Registrar o login com certificado digital para poder integrar com o KeyCloak
	 */
	registrarLoginCd(token: String): Observable<Object> {
		return this.http.post(this.API + "/usuario/registrar-login-cd", {
			token: token,
		});
	}

	/*--------------- SELEÇÃO DA EMPRESA DE ATUAÇÃO DO USUÁRIO **EXTERNO** ---------------*/
	/**
	 * @access public
	 * @description
	 * Busca empresas de Atuação do usuário logado pelo CPF do usuário
	 *
	 * @returns ```void```
	 */
	public buscaEmpresasDeAtuacaoDoUsuario(cpf: string): Observable<Array<EmpresasAtuacaoDTO>> {
		return this.http.get<Array<EmpresasAtuacaoDTO>>(`${this.API}/usuario/empresas-atuacao/${cpf}`);
	}

	/**
	 * 
	 * Registrar no KeyCloak qual a Empresa de Atuação escolhida, para podermos determinar as permissões disponíveis
	 * 
	 * @param empresa
	 * @returns 
	 */
	public registrarEmpresaAtuacaoEscolhida(empresa: EmpresasAtuacaoDTO): Observable<Object> {
		return this.http.post(this.API + "/usuario/registrar-empresa-atuacao-escolhida", empresa);
	}

	public buscaUsuariosInternos(): Observable<Array<UsuarioSsoDTO>> {
		return this.http.get<Array<UsuarioSsoDTO>>(`${this.API}/usuario/usuarios-internos`);
	}

	public buscarUsuarioInterno(id: number): Observable<UsuarioSsoDTO> {
		return this.http.get<UsuarioSsoDTO>(`${this.API}/usuario/busca-usuario-interno/${id}`);
	}

	public editarUsuarioInterno(usuario: UsuarioSsoDTO): Observable<UsuarioSsoDTO> {
		return this.http.post<UsuarioSsoDTO>(`${this.API}/usuario/atualiza-usuario`, usuario);
	}

	public buscarUsuarioLogado(): Observable<UsuarioSsoDTO> {
		return this.http.get<UsuarioSsoDTO>(`${this.API}/usuario/usuario-logado`);
	}
}
