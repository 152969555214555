import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Funcionalidade } from 'app/shared/enums/funcionalidade';
import { TipoDocumentoEnum } from 'app/shared/enums/tipoDocumento.enum';
import { NotificationService } from 'app/shared/service/notification.service';
import { TipoDocumentoService } from 'app/shared/service/tipo-documento.service';

@Component({
    selector: 'app-parametrizacao',
    templateUrl: './parametrizacao.component.html',
    styleUrls: ['./parametrizacao.component.scss']
})
export class ParametrizacaoComponent implements OnInit {

    docs: Array<any> = [];

    form: UntypedFormGroup;
    @ViewChild("tabela") tabela; 
    documentoData = {
        columns: [
            'campos',
            'obrigatoriedade',
            'ignoredObrigatoriedade',
            'tipo documento'
        ],
        source: []
    };

    parametrizacaoRows: Array<any> = [];
    constructor(readonly formBuilder: UntypedFormBuilder, private tipoDocumentoService: TipoDocumentoService, private notificationService: NotificationService){

    }
    
    ngOnInit(): void {
        this.form = this.formBuilder.group({
          });
        this.tipoDocumentoService
            .buscarTodosTiposDeDocumento()
            .subscribe(documentos => {
                this.docs = documentos
                documentos.forEach(doc => {
                    let funcionalidade = '';
                    if(doc['funcionalidade']['id'] == Funcionalidade.CREDENCIAMENTO_AR_SOLICITACAO){
                        funcionalidade = TipoDocumentoEnum.SOLICITACAO;
                    } else if (doc['funcionalidade']['id'] == Funcionalidade.CREDENCIAMENTO_AR_DOCUMENTACAO){
                        funcionalidade = TipoDocumentoEnum.DOCUMENTACAO;
                    } else if(doc['funcionalidade']['id'] == Funcionalidade.CREDENCIAMENTO_AR_ENVIOSOLICITACAO) {
                        funcionalidade = TipoDocumentoEnum.ENVIO_SOLICITACAO;
                    } else {
                        funcionalidade = TipoDocumentoEnum.CADASTRO_AGR;
                    }

                    this.parametrizacaoRows.push({
                        'campos': doc.nome,
                        obrigatoriedade: doc.id,
                        ignoredObrigatoriedade: doc.obrigatorio,
                        'tipo documento': funcionalidade
                    });

                    this.documentoData.source.push({
                        obrigatoriedade: doc.id,})

                    this.parametrizacaoRows = JSON.parse(JSON.stringify(this.parametrizacaoRows));
                })
            })
    }

    submit(){
        this.tipoDocumentoService.salvarParametrizacao(this.docs).subscribe(response => {
            this.notificationService.sucesso(`Os Parâmetros foram atualizados com sucesso.`);
        });

    }

    atualizarObrigatoriedade(event: any){
        this.docs.forEach(doc =>{
            if(doc.id === event.id){
                doc.obrigatorio = !doc.obrigatorio;
            }
        })
        
        
     
    }
}