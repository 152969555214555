import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { SelectItem } from "app/core/components/dropdown/select-item";
import { NotificationService } from "app/shared/service/notification.service";
import { ParametrizacaoMensageriaDTO } from "./parametrizacao-mensageria-dto";
import { ParametrizacaoMensageriaService } from "./parametrizacao-mensageria.service";

@Component({
	selector: "app-parametrizacao-mensageria",
	templateUrl: "./parametrizacao-mensageria.component.html",
	styleUrls: ["./parametrizacao-mensageria.component.scss"],
})
export class ParametrizacaoMensageriaComponent implements OnInit {
	form: UntypedFormGroup;

	editando: boolean = false;

	listaDados: ParametrizacaoMensageriaDTO[] = [];

	indice: number;

	listaMensagens: SelectItem[] = [];
	parametrizacaoHeader: any[] = [
		{ title: "Tipo de Mensagem", field: "tipoDeMensagem" },
		{
			title: "Tempo",
			field: "tempo",
			render: (i, row, field) => {
				return Math.floor(row.tempo / 60) + "h " + (row.tempo % 60) + "min";
			},
		},
		{ title: "Ações", field: "acoes", actions: ["edit"] },
	];
	parametrizacaoRows: any[];
	idEditada: number;

	constructor(private readonly formBuilder: UntypedFormBuilder, private readonly notificationService: NotificationService, private readonly parametrizacaoMensageriaService: ParametrizacaoMensageriaService) {}

	ngOnInit(): void {
		this.criaFormulario();
		this.buscaListaPadronizada();
		this.buscarMensagens();
	}

	ngAfterViewInit() {}

	buscaListaPadronizada() {
		this.parametrizacaoMensageriaService.buscaListaPadronizada().subscribe({
			next: lista => {
				lista.forEach(mensagem => {
					this.listaMensagens.push({
						label: mensagem.label,
						value: mensagem.value,
					});
				});
			},
		});
	}

	criaFormulario(): void {
		this.form = this.formBuilder.group({
			items: [null, Validators.required],
			tiposDeMensagem: [null, Validators.required],
			time: [null, Validators.required],
		});
	}

	buscarMensagens() {
		this.parametrizacaoMensageriaService.buscaMensagens().subscribe({
			next: lista => {
				this.parametrizacaoRows = [];

				lista.forEach(item => {
					this.parametrizacaoRows.push({
						value: item.tipoMensagem.value,
						tipoDeMensagem: item.tipoMensagem.label,
						tempo: item.tempo,
						id: item.id,
					});
				});
			},
		});
	}

	salvaParametrizacao(mensagem: ParametrizacaoMensageriaDTO) {
		this.parametrizacaoMensageriaService.salvaMensagem(mensagem).subscribe({
			next: retorno => {
				this.parametrizacaoRows.push({
					tipoDeMensagem: mensagem.mensagem,
					tempo: mensagem.tempo,
					id: null,
				});
				this.notificationService.sucesso(`Parametrização salva com sucesso!`);

				this.parametrizacaoRows = JSON.parse(JSON.stringify(this.parametrizacaoRows));
			},
		});
	}

	editaMensagem(mensagem: ParametrizacaoMensageriaDTO) {
		this.parametrizacaoMensageriaService.editaMensagem(mensagem).subscribe({
			next: () => {
				this.notificationService.sucesso(`Parametrização editada com sucesso!`);
				this.buscarMensagens();
			},
		});
	}

	salvarDados() {
		const mensagem = this.listaMensagens.filter(l => l.value === this.form.controls.tiposDeMensagem.value);

		if (this.editando) {
			const mensagemEditada: ParametrizacaoMensageriaDTO = {
				mensagem: mensagem[0].label,
				tipoMensagem: this.form.controls.tiposDeMensagem.value,
				tempo: this.form.controls.time.value,
				id: this.idEditada,
			};

			this.editaMensagem(mensagemEditada);
			this.editando = false;
		} else {
			const mensagemSalva: ParametrizacaoMensageriaDTO = {
				mensagem: mensagem[0].label,
				tipoMensagem: this.form.controls.tiposDeMensagem.value,
				tempo: this.form.controls.time.value,
			};
			this.listaDados.push(mensagemSalva);
			this.salvaParametrizacao(mensagemSalva);
		}

		this.form.reset();
		this.indice = null;
	}

	editarDados(event: any) {
		const dados = this.parametrizacaoRows[event.index];
		this.form.controls.time.setValue(dados.tempo);
		this.form.controls.tiposDeMensagem.setValue(dados.value);
		this.indice = event.index;
		this.idEditada = dados.id;

		this.editando = true;
	}
}
