<h2 mat-dialog-title>{{ data.title }}</h2>

<div mat-dialog-content>
	<form [formGroup]="form">
		<mat-form-field appearance="outline">
			<textarea matInput formControlName="conteudoTA"></textarea>
		</mat-form-field>
	</form>
</div>

<div mat-dialog-actions class="d-flex justify-content-end">
	<button type="button" *ngIf="data.cancelButton && data.cancelButton.show" [mat-dialog-close]="false" class="br-button secondary" (click)="onNoClick()">
		<i *ngIf="data.cancelButton.icon" class="material-icons">{{ data.cancelButton.icon }}</i>
		{{ data.cancelButton.label }}
	</button>

	<button type="button" *ngIf="data.okButton && data.okButton.show" [mat-dialog-close]="true" class="br-button primary" (click)="onOkClick()">
		<i *ngIf="data.okButton.icon" class="material-icons">{{ data.okButton.icon }}</i>
		{{ data.okButton.label }}
	</button>
</div>
