import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ConsultarSolicitacoes } from "../model/consultar-solicitacoes";
import { Utils } from "../util/utils";
import { ConsultarSolicitacoesRequest } from "../model/consultar-solicitacoes-request-dto";
import { AutoridadeRegistroDTO } from "../model/autoridade-registro-dto";
import { ConsultarSolicitacoesPaginada } from "../model/consultar-solicitacoes-paginada";

@Injectable({
	providedIn: "root",
})
export class ConsultarSolicitacoesService {
	urls = {
		pesquisar: ({ protocolo, situacao, solicitacao, dataInicial, dataFinal, cnpj, areaAtuacao, nomeEmpresa, page, size }: ConsultarSolicitacoesRequest) =>
			`${environment.cadastroApiUrl}/consulta/buscar-solicitacoes-paginada?protocolo=${protocolo ? protocolo : ""}&tipo_solicitacao=${solicitacao ? solicitacao : ""}&area_atuacao=${areaAtuacao ? areaAtuacao : 0}&data_inicial=${dataInicial ? dataInicial : ""}&data_final=${dataFinal ? dataFinal : ""}&situacao=${situacao ? situacao : ""}&cnpj=${cnpj ? cnpj : ""}&nome_empresa=${nomeEmpresa ? nomeEmpresa : ""}&page=${page}&size=${size}`
	};
	urlsAr = {
		pesquisar: ({ cnpj, nomeEmpresa }: ConsultarSolicitacoesRequest) => `${environment.cadastroApiUrl}/consulta/buscar-ar?cnpj=${cnpj ? cnpj : ""}&nome_empresa=${nomeEmpresa ? nomeEmpresa : ""}`,
	};

	constructor(private readonly http: HttpClient) {}

	pesquisar(filtro: ConsultarSolicitacoesRequest): Observable<ConsultarSolicitacoesPaginada> {
		const valorFiltrado: ConsultarSolicitacoesRequest = {
			protocolo: filtro.protocolo ? Utils.formatarProtocolo(filtro.protocolo) : "",
			situacao: filtro.situacao ? filtro.situacao : "",
			solicitacao: filtro.solicitacao ? filtro.solicitacao : "",
			dataInicial: filtro.dataInicial ? filtro.dataInicial : "",
			dataFinal: filtro.dataFinal ? filtro.dataFinal : "",
			cnpj: filtro.cnpj ? Utils.formataCNPJSemMascara(filtro.cnpj) : "",
			areaAtuacao: filtro.areaAtuacao ? filtro.areaAtuacao : 0,
			nomeEmpresa: filtro.nomeEmpresa ? filtro.nomeEmpresa : "",
			page: filtro.page,
			size: 10,
		};

		const endpoint = this.urls["pesquisar"];
		return this.http.get<ConsultarSolicitacoesPaginada>(endpoint(valorFiltrado));
	}

	pesquisarAR(filtro: ConsultarSolicitacoesRequest): Observable<AutoridadeRegistroDTO[]> {
		const valorFiltrado: ConsultarSolicitacoesRequest = {
			cnpj: filtro.cnpj ? Utils.formataCNPJSemMascara(filtro.cnpj) : "",
			nomeEmpresa: filtro.nomeEmpresa ? filtro.nomeEmpresa : ""
		};

		const endpoint = this.urlsAr["pesquisar"];
		return this.http.get<AutoridadeRegistroDTO[]>(endpoint(valorFiltrado));
	}


	iniciarAnalise(protocolo: string) {
		const iniciarAnalise = { protocolo: Utils.formatarProtocolo(protocolo) };
		return this.http.post<any>(`${environment.cadastroApiUrl}/consulta/iniciar-analise`, iniciarAnalise);
	}

	deletarConsulta(protocolo: string) {
		// const protocoloFormatado = { protocolo: Utils.formatarProtocolo(protocolo) };
		return this.http.post<any>(`${environment.cadastroApiUrl}/consulta/deletar-consulta`, protocolo);
	}

	assinarNotificacao() {
		return this.http.get<any>(`${environment.cadastroApiUrl}/analise-solicitacao/assinar`);
	}
}
