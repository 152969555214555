import { getLocaleNumberFormat } from "@angular/common";

export enum StatusCredenciamentoDescricao {
    EM_PREENCHIMENTO = 'Em Preenchimento',
    AGUARDANDO_ASSINATURA_AR = 'Aguardando assinatura AR',
    AGUARDANDO_ASSINATURA_AC = 'Aguardando assinatura AC',
    AGUARDANDO_ANALISE = 'Aguardando Análise',
    EM_ANALISE = 'Em Análise',
    AGUARDANDO_COMPLEMENTACAO = 'Aguardando Complementação',
    AGUARDANDO_DISTRIBUICAO = 'Aguardando Distribuição',
    COMPLEMENTACAO_SOLICITADA = 'Complementação Solicitada',
    EM_ANALISE_TECNICA = 'Em Análise Técnica',
    EM_ANALISE_CGAFI = 'Em Análise CGAFI',
    EM_ANALISE_DAFN = 'Em Análise DAFN',
    EM_ANALISE_DIRETOR_PRESIDENTE ='Em Análise Diretor Presidente',
    AGUARDANDO_ANALISE_TECNICA = 'Aguardando Análise Técnica',
    AGUARDANDO_ANALISE_CGAFI = 'Aguardando Análise CGAFI',
    AGUARDANDO_ANALISE_DAFN = 'Aguardando Análise DAFN',
    AGUARDANDO_DISPACHO_DIRETOR_PRESIDENTE =  'Aguardando Despacho Diretor Presidente',
    AGUARDANDO_PUBLICACAO_DOU = 'Aguardando Publicação DOU',
    DEFERIDO = "Deferido / Finalizado",
    INDEFERIDO = "Indeferido / Aguardando recurso",
    AGUARDANDO_ENVIO_DOCUMENTO_ASSINADO_SOLICITACAO = "Aguardando envio do documento assinado da solicitação",
    ENCERRADO = "Solicitação Arquivada"

} 

