<!-- Modal -->
<div class="modal fade" id="gerarOficioModal" tabindex="-1" data-backdrop="false" role="dialog" aria-labelledby="gerarOficioModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="gerarOficioModalLabel">Gerar Ofício</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<form [formGroup]="form">
					<div class="form-row">
						<div class="d-flex align-items-center justify-content-around">
							<h4 style="font-size: 14px" class="col-6">Deseja Utilizar um Modelo?</h4>
							<app-dropdown [form]="form" [items]="gerarOficio" controlName="gerarOficio" label="Selecione" class="col-6 col-lg-6"></app-dropdown>
						</div>
					</div>
					<mat-form-field appearance="outline" class="col-12">
						<mat-label>Criar ofício</mat-label>
						<textarea matInput formControlName="conteudo"></textarea>
					</mat-form-field>
				</form>
			</div>
			<div class="modal-footer">
				<button type="button" class="br-button secondary" data-dismiss="modal">Cancelar</button>
				<button type="button" class="br-button primary">Gerar Documento</button>
			</div>
		</div>
	</div>
</div>