import { AreaAtuacaoDTO } from './../../shared/model/area-atuacao-dto';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { TipoDocumentoDTO } from "app/shared/model/tipo-documento-dto";
import { ModeloDocumentoDTO } from "app/shared/model/modelo-documento-dto";
import { Page, QueryBuilder } from "app/shared/util/pagination";
import { map } from "rxjs/operators";
import { Utils } from 'app/shared/util/utils';

const API_AREAS_ATUACAO = `${environment.cadastroApiUrl}/area-atuacao`;

@Injectable({
	providedIn: "root",
})
export class AreaAtuacaoService {
	constructor(private readonly http: HttpClient) {}

	cadastrarNovaAreaAtuacao(novaAreaAtuacao: AreaAtuacaoDTO): Observable<AreaAtuacaoDTO> {
		return this.http.post<AreaAtuacaoDTO>(`${API_AREAS_ATUACAO}/salvar`, novaAreaAtuacao);
	}

	salvarEdicaoAreaAtuacao(novaAreaAtuacao: AreaAtuacaoDTO, id: number): Observable<AreaAtuacaoDTO> {
		return this.http.post<AreaAtuacaoDTO>(`${API_AREAS_ATUACAO}/salvar-edicao/${id}`, novaAreaAtuacao);
	}

	buscarAreaAtuacaoEditadoPorId(id: number): Observable<AreaAtuacaoDTO>{
		return this.http.get<AreaAtuacaoDTO>(`${API_AREAS_ATUACAO}/buscar-editado/${id}`);
	}

	buscarAreaAtuacao(queryBuilder: QueryBuilder): Observable<Page<AreaAtuacaoDTO>> {
        return this.http
        .get<AreaAtuacaoDTO[]>(`${environment.cadastroApiUrl}/area-atuacao?${queryBuilder.buildQueryString()}`, { observe:"response" })
        .pipe(map(response => <Page<AreaAtuacaoDTO>>Page.fromResposne(response)));
    }

	buscarAreasAtuacao(): Observable<AreaAtuacaoDTO[]>{
		return this.http.get<ModeloDocumentoDTO[]>(`${API_AREAS_ATUACAO}/buscar`);
	}

	deletarAreaAtuacao(id: number): Promise<void>{
		return this.http.delete<void>(`${API_AREAS_ATUACAO}/deletar-area-atuacao/${id}`).toPromise();
	}

	buscarAtuacaoUsuario(id: number): Observable<AreaAtuacaoDTO[]>{
		return this.http.get<AreaAtuacaoDTO[]>(`${API_AREAS_ATUACAO}/buscar-atuacao-usuario/${id}`);
	}

	buscarAresAtuacaoUsuarioLogado(): Observable<AreaAtuacaoDTO[]>{
		return this.http.get<AreaAtuacaoDTO[]>(`${API_AREAS_ATUACAO}/buscar-areas-atuacao-usuario-logado`);
	}

	
}