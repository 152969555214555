<h5 class="section-title">Dados da Solicitação</h5>
<div class="row">
    <div class="col-4 mb-4" *ngIf="cabecalho.protocolo != null && cabecalho.protocolo != 'undefined'">
        <p class="label">Protocolo</p>
        <p>{{ cabecalho.protocolo }}</p>
    </div>
    <div class="col-4 mb-4" *ngIf="cabecalho.cnpj != null && cabecalho.cnpj !== 'undefined'">
        <p class="label">CNPJ da Pessoa Jurídica Solicitante</p>
        <p>{{ cabecalho.cnpj }}</p>
    </div>
    <div class="col-4 mb-4" *ngIf="cabecalho.nome != null && cabecalho.nome !== 'undefined'">
        <p class="label">Razão Social da Pessoa Jurídica Solicitante</p>
        <p>{{ cabecalho.nome }}</p>
    </div>
    <div class="col-4 mb-4" *ngIf="cabecalho.status != null && cabecalho.status !== 'undefined'">
        <p class="label"  translate>fieldset.usuario.situacao-solicitacao</p>
        <p>{{ cabecalho.status  }}</p>
    </div>
    <div class="col-4 mb-4" *ngIf="cabecalho.atividadeAtuacao != null && cabecalho.atividadeAtuacao !== 'undefined'">
        <p class="label">Atividade de atuação</p>
        <p>{{ cabecalho.atividadeAtuacao  }}</p>
    </div>
    <div class="col-4 mb-6" *ngIf="cabecalho.dataSolicitacao != null && cabecalho.dataSolicitacao !== 'undefined' && cabecalho.dataSolicitacao != 'Data inválida'">
        <p class="label">Data da Solicitação</p>
        <p>{{ cabecalho.dataSolicitacao }}</p>
    </div> 
   </div>

 